import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { gerencValesPresenteRoute } from "../../../routes/modules/vendas";
import { TbGiftCardFilled } from "react-icons/tb";

export const ValePresente = () => {
  return (
    <PageContainer title="Vale Presente">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de Vales Presente"
          icon={TbGiftCardFilled}
          pathname={gerencValesPresenteRoute.path}
          nroTela={gerencValesPresenteRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
