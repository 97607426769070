import React, { useState } from "react";
import { BotaoImprimir, IntegerFormInput, ModalBase, TextInput } from "../../../../components";
import { Row } from "reactstrap";
import { showWarning } from "../../../../components/AlertaModal";
import OutsourcingService from "../../../../services/outsourcing/OutsourcingService";

export const AddaxIncluirModal = ({buscarImpressao}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [nroNota, setNroNota] = useState(null);
  const [volumes, setVolumes] = useState(null);
  const [nome, setNome] = useState("");
  const [destino, setDestino] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setNroNota(null);
    setVolumes(null);
    setNome("");
    setDestino("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(nroNota, volumes, nome, destino)) {
      showWarning("Por favor, preencha todos os campos");
      return;
    }
    setLoading(true);

    const payload = {
      numero_nf: parseInt(nroNota),
      nome_cliente: nome,
      destino: destino,
      qtd_volumes: parseInt(volumes),
    };

    const ret = await OutsourcingService.incluir(payload);

    if (ret) {
      buscarImpressao(payload)
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <BotaoImprimir md="auto" onClick={toggle} text="Incluir NF" />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Incluir NF"
        number="0134_1"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <TextInput
            colClassName="ml-auto"
            md={9}
            label="Numero da Nota"
            onChange={(_, v) => setNroNota(v)}
            value={nroNota}
          />
          <TextInput
            colClassName="ml-auto"
            md={3}
            label="Volumes"
            onChange={(_, v) => setVolumes(v)}
            inputStyle={{ textAlign: "center" }}
            value={volumes}
          />
        </Row>
        <Row>
          <TextInput
            colClassName="ml-auto"
            md={12}
            label="Nome do Cliente"
            onChange={(_, v) => setNome(v)}
            value={nome}
          />
        </Row>
        <Row>
          <TextInput
            colClassName="ml-auto"
            md={12}
            label="Destino"
            onChange={(_, v) => setDestino(v)}
            value={destino}
          />
        </Row>
      </ModalBase>
    </>
  );
};
