import React, { useState } from "react";
import { ModalBase } from "../../../../../../../components/ModalBase";
import { TableDelete } from "../../../../../../../components/TableDelete";

export const ExcluirProdutoModal = ({ nome, handleDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = () => {
    handleDelete();
    toggle();
  };

  return (
    <>
      <TableDelete onClick={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Exclusão de Item do Pedido"
        number="0034_42"
        autoFocus
        confirmButtonText="Excluir"
        onConfirm={onConfirm}
      >
        <p className="mb-0">
          Tem certeza de que deseja excluir o produto {nome}?
        </p>
      </ModalBase>
    </>
  );
};
