import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
} from "../../../../components";
import { Row } from "reactstrap";
import {
  setEnvioEmailAutomatico,
  setGerarBoletoAutomatico,
  setIdCentroCustoReceita,
  setIdContaBancFatBoleto,
} from "../store/entrega_pedido_ext_slice";

export const PersonEntregaPedidoExt = () => {
  const store = useSelector((state) => state.entrega_pedido_ext);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Centro de Custo de Receitas"
          mode="centro_custo"
          value={store.id_centro_custo_receita}
          onChange={(s) => dispatch(setIdCentroCustoReceita(s ?? 0))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Conta Bancária para Faturamento de Boletos"
          isSearchable
          isConcatField
          defaultOptions
          concatModelName="conta_banc"
          defaultValue={store.id_conta_banc_fat_boleto}
          onChange={(s) => dispatch(setIdContaBancFatBoleto(s?.value ?? 0))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gerar Boleto Automaticamente"
          checked={store.gerar_boleto_automatico}
          onChange={() =>
            dispatch(setGerarBoletoAutomatico(!store.gerar_boleto_automatico))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Enviar E-Mail Automaticamente"
          checked={store.envio_email_automatico}
          onChange={() =>
            dispatch(setEnvioEmailAutomatico(!store.envio_email_automatico))
          }
        />
      </Row>
    </>
  );
};
