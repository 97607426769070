import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Label, Row } from "reactstrap";
import { BotaoImprimir, IntegerFormInput } from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";
import CentralPedidosService from "../../../../../../services/pedidos/CentralPedidosService";
import { docPrintReport } from "../../../../../../pdf";

export const ImprimirPedidosModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viasImprimir, setViasImprimir] = useState(1);

  const imprimirPedidos = async (viasImprimir) => {
    setLoading(true);
    let pedidosImprimir = [];

    for (let i = 0; i < selected.length; i++) {
      const [ok, ret] = await CentralPedidosService.buscarDadosImpressao(
        selected[i]
      );
      if (ok) pedidosImprimir.push(ret);
    }
    pedidosImprimir = pedidosImprimir.filter((e) => e);

    if (pedidosImprimir.length > 0) {
      await docPrintReport(
        "/pedido/pedido_externo/",
        {
          layout: pedidosImprimir[0].layout_impressao,
          obs_fixa: pedidosImprimir[0].obs_fixa,
          pedidos: pedidosImprimir,
          vias_imprimir: viasImprimir,
        },
        "0034_2"
      );
    }

    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = () => {
    if (viasImprimir < 1) {
      return toastr.warning(
        "Atenção",
        "É necessário imprimir ao menos uma via do(s) pedido(s)."
      );
    }
    imprimirPedidos(viasImprimir);
    toggle();
  };

  return (
    <>
      <BotaoImprimir
        onClick={toggle}
        loading={loading}
        disabled={selected?.length === 0}
        disabledHint="Selecione ao menos um pedido da lista"
        text="Imprimir"
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        onOpened={() => setViasImprimir(1)}
        onClosed={() => setViasImprimir(1)}
        title="Imprimir Pedidos"
        number="0034_3"
        onConfirm={onConfirm}
        onKeyDown={(e) => e.key === "Enter" && onConfirm()}
      >
        <Row
          style={{ alignItems: "center", justifyContent: "center", margin: 0 }}
        >
          <Label>Imprimir</Label>
          <IntegerFormInput
            md={5}
            onChange={(v) => setViasImprimir(v)}
            defaultValue={viasImprimir}
          />
          <Label>via(s)</Label>
        </Row>
      </ModalBase>
    </>
  );
};
