import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { Card, Row } from "reactstrap";
import { ProdutosAbaixoEstMinGrid } from "./components/ProdutosAbaixoEstMinGrid";
import { docPrintReport } from "../../../../pdf";

const ordenarOptions = [
  { label: "Nome", value: "NOM" },
  { label: "Código", value: "COD" },
  { label: "Diferença", value: "DIF" },
];

export const ProdutosAbaixoEstMin = () => {
  const [idFabricante, setIdFabricante] = useState(null);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [somenteControlaEstoque, setSomenteControlaEstoque] = useState(true);
  const [ordenarPor, setOrdenarPor] = useState(ordenarOptions[0].value);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = () => {
    const params = {
      id_fabricante: idFabricante,
      mostrar_inativos: mostrarInativos,
      somente_controla_estoque: somenteControlaEstoque,
      ordenar_por: ordenarPor,
    };

    return apiGetV2(`/relatorios/estoque/produtos_abaixo_est_min/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        dados: ret,
      };

      await docPrintReport(
        "/relatorios/estoque/produtos_abaixo_est_min/",
        payload,
        "5065",
        true
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Produtos Abaixo do Estoque Mínimo" number="5065">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Fabricante"
            concatModelName="fabricante"
            defaultValue={idFabricante}
            onChange={(s) => setIdFabricante(s?.value)}
            hideShortcut
          />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
          />
          <FormCheckbox
            label="Somente produtos marcados para Controlar Estoque"
            checked={somenteControlaEstoque}
            onChange={() => setSomenteControlaEstoque(!somenteControlaEstoque)}
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Ordenar Por"
            options={ordenarOptions}
            defaultValue={ordenarPor}
            onChange={(s) => setOrdenarPor(s?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <ProdutosAbaixoEstMinGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
