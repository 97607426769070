import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../../components";
import { MODAL_ACTIONS, formatDateISO } from "../../../../../coreUtils";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";
import { CentralPedidosGrid } from "./components/CentralPedidosGrid";
import { ImprimirPedidosModal } from "./components/ImprimirPedidosModal";
import { CancelarPedidoModal } from "./components/CancelarPedidoModal";
import {
  entregarPedidoRoute,
  incluirAlterarPedidoRoute,
} from "../../../../../routes/modules/pedidos";
import { toastr } from "react-redux-toastr";
import { NaoEntregarRestantePedidoModal } from "./components/NaoEntregarRestantePedidoModal";
import { docPrintReport } from "../../../../../pdf";
import { showInfo } from "../../../../../components/AlertaModal";

const mostrarOptions = [
  { label: "Pendentes", value: "PEN" },
  { label: "Não Liberados", value: "PNL" },
  { label: "Liberados", value: "LIB" },
  { label: "Entregues Parcialmente", value: "ENP" },
  { label: "Entregues Totalmente", value: "ENT" },
  { label: "Cancelados", value: "CAN" },
  { label: "Todos", value: "T" },
];

const filtrarPorOptions = [
  { label: "Emissão", value: "EMI" },
  { label: "Previsão de Entrega", value: "PRE" },
];

export const CentralPedExterno = ({ location, history }) => {
  const [dataIni, setDataIni] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 3
    )
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [idCliente, setIdCliente] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [numeroPedido, setNumeroPedido] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [dados, setDados] = useState([]);
  const [contagem, setContagem] = useState(0);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImpListagem, setLoadingImpListagem] = useState(false);
  const [loadingIncluir, setLoadingIncluir] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [impListaSomenteSel, setImpListaSomenteSel] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const [naoEntregarRestanteOpen, setNaoEntregarRestanteOpen] = useState(false);

  const usuarioPodeManipularPedido = () =>
    localStorage.getItem("ped_ext_pode_manipular_pedidos") === "true";

  const limparDados = () => {
    setDados([]);
    setContagem(0);
    setTotal(0);
    setSelected([]);
    setLastSelected(null);
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
    limparDados();
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_vendedor: idVendedor,
      numero_pedido: numeroPedido,
      mostrar: mostrar !== "T" ? mostrar : null,
      filtrar_por: filtrarPor || "EMI",
      id_cidade: idCidade,
    };
    return await CentralPedidosService.listar(params);
  };

  const listarDados = async () => {
    setSelected([]);
    setLastSelected(null);
    const [ok, ret] = await buscarDados();

    if (ok) {
      setDados(ret.pedidos);
      setContagem(ret.qtd_pedidos);
      setTotal(ret.tot_pedidos);
    } else {
      setDados([]);
      setContagem(0);
      setTotal(0);
    }
  };

  const verificarParametros = async () => {
    const [ok] = await CentralPedidosService.verificarConfiguracoesEntrega();

    return ok;
  };

  const verificarPodeAlterar = async (idPedido) => {
    const [ok] = await CentralPedidosService.verifPodeAlterar(idPedido);

    return ok;
  };

  const entregarPedido = async (id) => {
    if (!usuarioPodeManipularPedido()) {
      showInfo("O seu usuário não tem permissão para entregar pedidos");
      return;
    }

    if (await verificarParametros()) {
      history.push(entregarPedidoRoute.path, { id: id });
    }
  };

  const incluirPedido = async () => {
    setLoadingIncluir(true);
    if (await verificarParametros()) {
      history.push(incluirAlterarPedidoRoute.path, {
        action: MODAL_ACTIONS.ADD,
      });
    }
    setLoadingIncluir(false);
  };

  const alterarPedido = async (id) => {
    if (!usuarioPodeManipularPedido()) {
      showInfo("O seu usuário não tem permissão para alterar pedidos");
      return;
    }

    if ((await verificarParametros()) && (await verificarPodeAlterar(id))) {
      history.push(incluirAlterarPedidoRoute.path, {
        id: id,
        action: MODAL_ACTIONS.EDIT,
      });
    }
  };

  const clonarPedido = async () => {
    if (await verificarParametros()) {
      history.push(incluirAlterarPedidoRoute.path, {
        action: MODAL_ACTIONS.ADD,
        id_pedido_clonar: lastSelected,
      });
    }
  };

  const imprimirListagem = async () => {
    setLoadingImpListagem(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/pedido/listagem/",
        {
          data: ret,
          imp_lista_somente_sel: impListaSomenteSel,
          selected: selected,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "0034_5"
      );
    }
    setLoadingImpListagem(false);
  };

  const enviarEmail = async () => {
    setLoadingEmail(true);
    for (let i = 0; i < selected.length; i++) {
      await CentralPedidosService.enviarEmail(selected[i]);
    }
    setLoadingEmail(false);
  };

  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const toggleNaoEntregarRestante = () =>
    setNaoEntregarRestanteOpen(!naoEntregarRestanteOpen);

  const cancelarPedido = (id, status) => {
    if (!["PEN", "AFA"].includes(status)) {
      toastr.warning(
        "Atenção",
        "Somente pedidos Pendentes de Liberação " +
          "ou Aguardando Faturamento podem ser cancelados"
      );
      return false;
    }
    if (!usuarioPodeManipularPedido()) {
      showInfo("O seu usuário não tem permissão para cancelar pedidos");
      return false;
    }
    setLastSelected(id);
    toggleCancelar();
  };

  const naoEntregarRestante = (id) => {
    if (!usuarioPodeManipularPedido()) {
      showInfo("O seu usuário não tem permissão para entregar pedidos");
      return;
    }
    setLastSelected(id);
    toggleNaoEntregarRestante();
  };

  const notifyEvent = () => {
    listarDados();
  };

  const handleSearch = async () => {
    limparDados();
    setLoadingPesquisar(true);
    await listarDados();
    setLoadingPesquisar(false);
  };

  const handleSelect = (id, isSelect) => {
    setLastSelected(id);
    setSelected(
      isSelect ? [...selected, id] : selected.filter((e) => e !== id)
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const onActivate = () => {
    if (location.state?.refresh) {
      handleSearch();
    }
  };
  useEffect(onActivate, []);

  return (
    <PageContainer title="Central de Pedidos Externos" number="0034" canGoBack>
      <Card body>
        <Row>
          <IntegerFormInput
            label="Nro. Pedido"
            md={2}
            onChange={(v) => setNumeroPedido(v)}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdVendedor(s?.value)}
            hideShortcut
            defaultOptions
          />
          <AsyncComboBox
            md={3}
            label="Cidade"
            isConcatField
            concatModelName="cidade"
            isSearchable
            isClearable
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <ComboBox
            md={3}
            label="Mostrar"
            options={mostrarOptions}
            defaultValue={mostrar}
            onChange={(s) => setMostrar(s?.value)}
          />
          <ComboBox
            md={2}
            label="Filtrar Por"
            options={filtrarPorOptions}
            isClearable
            defaultValue={filtrarPor}
            onChange={(s) => setFiltrarPor(s?.value)}
          />
          <FiltroPeriodoDatas defaultOption="3D" onChange={handleSelectDate} />
        </Row>
        <Row>
          <BotaoPesquisar onClick={handleSearch} loading={loadingPesquisar} />
          <FormButton
            md="auto"
            onClick={incluirPedido}
            color="info"
            loading={loadingIncluir}
          >
            Incluir
          </FormButton>
          <CancelarPedidoModal
            isOpen={cancelarOpen}
            toggle={toggleCancelar}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
          <NaoEntregarRestantePedidoModal
            isOpen={naoEntregarRestanteOpen}
            toggle={toggleNaoEntregarRestante}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
          <FormButton
            md="auto"
            color="secondary"
            disabled={
              selected?.length === 0 ||
              dados
                .filter((e) => selected.includes(e.id))
                .some((e) => ["PEN", "AFA"].includes(e.status))
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione um ou mais pedidos na lista"
                : "Selecione somente pedidos que não estejam Pendentes"
            }
            onClick={enviarEmail}
            loading={loadingEmail}
          >
            Enviar E-Mail
          </FormButton>
          <ImprimirPedidosModal selected={selected} />
          <FormButton
            color="warning"
            onClick={clonarPedido}
            disabled={[0, null, undefined].includes(lastSelected)}
          >
            Clonar
          </FormButton>
          <BotaoImprimir
            onClick={imprimirListagem}
            loading={loadingImpListagem}
            text="Imprimir Listagem"
            divClassName="ml-auto"
          />
          <FormCheckbox
            label="Imprimir Listagem Somente Selecionados"
            name="list-somente-selec"
            divClassName="px-0"
            checked={impListaSomenteSel}
            onChange={() => setImpListaSomenteSel(!impListaSomenteSel)}
          />
        </Row>
      </Card>
      <Card body>
        <CentralPedidosGrid
          data={dados}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          selected={selected}
          notifyEvent={notifyEvent}
          entregarPedido={entregarPedido}
          cancelarPedido={cancelarPedido}
          alterarPedido={alterarPedido}
          naoEntregarRestante={naoEntregarRestante}
        />
      </Card>
      <CardTotais md={4}>
        <CardTotaisItem
          label="Quantidade de Pedidos"
          value={contagem}
          checkFloat
        />
        <CardTotaisItem label="Valor Total" value={total} />
      </CardTotais>
      <Card body>
        <h6>A Central de Pedidos tem o intuito de:</h6>
        <ul>
          <li>
            Informar ao cliente que o seu pedido foi recebido pela empresa.
          </li>
          <li>Avaliar crédito do cliente para realização da venda.</li>
          <li>Conferir se o seu saldo de estoque atende o pedido.</li>
          <li>Impressão de pedidos para realização de separação</li>
        </ul>
      </Card>
    </PageContainer>
  );
};
