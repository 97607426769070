import React, { useRef } from "react";
import { useState } from "react";
import {
  modalTitleLigacaoProdFornec,
  routesBaseLigacaoProdFornec,
} from "../LigacaoProdFornec";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import {
  AsyncComboBox,
  FormCheckbox,
  NumberInput,
  TextInput,
} from "../../../../../components";

export const CadastroLigacaoProdFornecModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idProduto, setIdProduto] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [codProdFornec, setCodProdFornec] = useState("");
  const [eanProdFornec, setEanProdFornec] = useState("");
  const [considerarEan, setConsiderarEan] = useState(false);
  const [fatorConv, setFatorConv] = useState(1);
  const [arredondaResult, setArredondaResult] = useState(false);
  const [conversaoEmM3, setConversaoEmM3] = useState(false);

  const pesqProdRef = useRef();
  const idFornecedorRef = useRef();

  const limparDados = () => {
    setIdProduto(null);
    setIdFornecedor(null);
    setCodProdFornec("");
    setEanProdFornec("");
    setConsiderarEan(false);
    setFatorConv(1);
    setArredondaResult(false);
    setConversaoEmM3(false);
  };

  const handleSelectProduto = ({ idProduto, nomeProduto, referencia }) => {
    setIdProduto(idProduto);
    if (pesqProdRef.current) {
      pesqProdRef.current.setDescricao(nomeProduto);
      pesqProdRef.current.setId(String(idProduto));
      pesqProdRef.current.setReferencia(String(referencia));
    }
  };

  const fetchData = (data) => {
    setIdProduto(data.id_produto);
    setIdFornecedor(data.id_fornecedor);
    setCodProdFornec(data.cod_prod_for);
    setEanProdFornec(data.cod_barras ?? "");
    setConsiderarEan(data.considera_ean);
    setFatorConv(parseFloat(data.fator_conv));
    setArredondaResult(data.arredonda_result);
    setConversaoEmM3(data.conversao_em_m3);

    setTimeout(() => {
      if (pesqProdRef.current) {
        pesqProdRef.current.setValueByID(data.id_produto);
      }
    }, 35);
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, informe o Produto");
      return false;
    }

    if ([0, null, undefined].includes(idFornecedor)) {
      showWarning("Por favor, informe o Fornecedor");
      return false;
    }

    if (["", null, undefined].includes(codProdFornec)) {
      showWarning("Por favor, informe o Código do Produto no Fornecedor");
      return false;
    }

    if (considerarEan && ["", null, undefined].includes(eanProdFornec)) {
      showWarning(
        "Por favor, informe o Código de Barras do Produto no Fornecedor"
      );
      return false;
    }

    const payload = {
      id_produto: idProduto,
      id_fornecedor: idFornecedor,
      cod_prod_fornec: codProdFornec,
      ean_prod_fornec: eanProdFornec,
      considerar_ean: considerarEan,
      fator_conv: fatorConv,
      arredonda_result: arredondaResult,
      conversao_em_m3: conversaoEmM3,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_ligacao: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleLigacaoProdFornec}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseLigacaoProdFornec}
      number="0137_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <PesqProduto
          md={12}
          mdIdent={3}
          mdDesc={7}
          mdIdentAux={2}
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          selectNextField={() =>
            idFornecedorRef.current && idFornecedorRef.current.setFocus()
          }
          podeAlterarNomeProdCuringa={false}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={7}
          label="Fornecedor"
          concatModelName="fornecedor"
          defaultValue={idFornecedor}
          onChange={(s) => setIdFornecedor(s?.value)}
          ref={idFornecedorRef}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          label="Cód. Produto no Fornecedor"
          value={codProdFornec}
          onChange={(e, v) => setCodProdFornec(v)}
          maxLength={60}
          inputStyle={{ textAlign: "center" }}
        />
        <TextInput
          md={3}
          label="Cód. Barras no Fornecedor"
          value={eanProdFornec}
          onChange={(e, v) => setEanProdFornec(v)}
          maxLength={20}
          inputStyle={{ textAlign: "center" }}
        />
        <FormCheckbox
          label="Identificar por código do produto no fornecedor + código de barras"
          checked={considerarEan}
          onChange={() => setConsiderarEan(!considerarEan)}
          hint={`Opção utilizada para diferenciar produtos
              que estejam no XML com o mesmo código.
              Marcando esta opção, o sistema irá considerar
              cód. do produto + código de barras
              para identificar o produto.`}
        />
      </Row>
      <Row>
        <NumberInput
          md={3}
          label="Fator de Conversão"
          value={fatorConv}
          onChange={(v) => setFatorConv(v)}
          decimalPlaces={7}
          style={{ textAlign: "center" }}
        />
        <FormCheckbox
          label="Conversão em M³"
          checked={conversaoEmM3}
          onChange={() => setConversaoEmM3(!conversaoEmM3)}
        />
        <FormCheckbox
          label="Arredondar Quantidade (resultado) para Inteiro"
          checked={arredondaResult}
          onChange={() => setArredondaResult(!arredondaResult)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
