import {
  relatoriosRoutes,
  authRoutes,
  financeiroRoutes,
  cadastroRoutes,
  comprasRoutes,
  conferirValoresJornadaDiariaRoutes,
  docsEletronRoutes,
  faturamentoRoutes,
  prontaEntregaRoutes,
  gerencRemessasRoute,
  inclusaoRotaRoute,
  OsRoutes,
  recargaProdutosRoutes,
} from "./modules";
import {
  cadastroCartoesRoutes,
  incluirAlterarClienteRoute,
  cadastroClientesRoutes,
  cadastroFinanceiroRoutes,
  cadastroGrupoRegraTribRoute,
  cadastroOutrosRoutes,
  incluirAlterarProdutoRoute,
  cadastroProdutosRoutes,
  cadastroServicosRoutes,
  cadastroTributacaoRoutes,
  camposPersonalizadosRoute,
  extratoClienteRoute,
  ligacaoVeicCliRoute,
  produtosRegraTribRoute,
  itensRegraPromocaoRoute,
  cadastroVendasRoutes,
  cadastroAcessosSistemaRoutes,
  incluirMetaBaseadoVendasRoute,
  incluirAlterarCfopRoute,
} from "./modules/cadastro";
import {
  incluirAlterarMDFeRoute,
  incluirAlterarNFRoute,
  incluirAlterarNFServicoRoute,
  docsEletronNotasFiscaisRoutes,
  docsEletronTransporteRoutes,
  cadastroMDFeRoutes,
  incluirAlterarNFConsRoute,
} from "./modules/docsEletron";
import {
  caixaLojaRoute,
  cartaoCreditoRoutes,
  cobrancaBancRoutes,
  compensacoesRealizadasRoute,
  contasPagarRoutes,
  contasReceberRoutes,
  gerencCaixaLojaRoute,
  receberCaixaLojaRoute,
  relInconsistenciasRoute,
} from "./modules/financeiro";
import {
  cadastroVistoriaTecnicaRoute,
  demonstrativosOsRoutes,
  incluirAlterarOSRoute,
  incluirAlterarOrcamentoOSRoute,
  manutencaoOSRoute,
  orcamentosOSRoute,
  atendimentoOSRoute,
  historicoVeiculoRoute,
  // programacaoProjetoRoute,
  // validarProgramacaoExecucaoRoute,
} from "./modules/ordem_servico";
import pedidosRoutes, {
  incluirAlterarPedidoRoute,
  entregarPedidoRoute,
  entregarPedidoInternoRoute,
  romaneioRoutes,
  pedidosExternosRoutes,
  pedidosInternosRoutes,
} from "./modules/pedidos";
import {
  relatoriosCadastraisRoutes,
  relatoriosComissionamentoRoutes,
  relatoriosControladoriaRoutes,
  relatoriosFinPagarRoutes,
  relatoriosFinReceberRoutes,
  relatoriosNfSaidaRoutes,
  relatoriosOutrosRoutes,
  relatoriosVendasRoutes,
  relatoriosEstoqueRoutes,
  relatoriosPedidosRoutes,
} from "./modules/relatorios";
import {
  estoqueRotaRoutes,
  prontaEntregaCadastroRoutes,
  prontaEntregaGerenciamentoRoutes,
  prontaEntregaRelatoriosRoutes,
} from "./modules/pronta_entrega";
import vendasRoutes, {
  devolverVendaRoute,
  frenteVendaRoute,
  orcamentosRoutes,
  valePresenteRoutes,
  vendasGerenciamentoRoutes,
  incluirLayoutMarketplacesRoute,
} from "./modules/vendas";
import {
  comprasGerenciamentoRoute,
  confirmarPrecosNfRoute,
  financeiroEntradaNfXmlRoute,
  gerarSugestaoCompraRoute,
  incluirOrdemCompraRoute,
  produtosEntradaNfXmlRoute,
} from "./modules/compras";
import {
  faturamentoVendaOsRoutes,
  infoNfFaturadaRoute,
  transferenciasRoutes,
} from "./modules/faturamento";
import { Home } from "../pages/home/Home";
import { industriaProcessosRoutes, industriaRoutes } from "./modules/industria";
import {
  estoqueRoutes,
  acoesEstoqueRoutes,
  incluirAlterarRemessaEntreEmpresasRoute,
  exportacaoBalancaRoute,
} from "./modules/estoque";
import {
  addaxTransportesRoutes,
  outsourcingRoutes,
} from "./modules/outsourcing";
import { temDevOutsourcing } from "../coreUtils";

export const checkRoutes = (routes, permissions) => {
  const allowedRoutes = [];

  routes.forEach((route) => {
    if (route.children) {
      // Verifica se a rota possui filhos com permissão
      route.children = checkRoutes(route.children, permissions);
      if (route.children.length > 0) allowedRoutes.push(route);
    } else {
      const permission = permissions.find((x) => x.nroTela === route.nroTela);
      if (permission || (!route.nroTela && route.component)) {
        allowedRoutes.push(route);
      }
    }
  });

  return allowedRoutes;
};

// Dashboard specific routes
export const dashboard = [
  {
    path: "/",
    name: "Início",
    component: Home,
    canGoBack: false,
  },
  relatoriosRoutes,
  financeiroRoutes,
  comprasRoutes,
  inclusaoRotaRoute,
  OsRoutes,
  prontaEntregaRoutes,
  conferirValoresJornadaDiariaRoutes,
  recargaProdutosRoutes,
  cadastroVistoriaTecnicaRoute,
  cadastroRoutes,
  pedidosRoutes,
  faturamentoRoutes,
  camposPersonalizadosRoute,
  extratoClienteRoute,
  relInconsistenciasRoute,
  docsEletronRoutes,
  gerencRemessasRoute,
  estoqueRotaRoutes,
  produtosRegraTribRoute,
  entregarPedidoRoute,
  entregarPedidoInternoRoute,
  cadastroGrupoRegraTribRoute,
  incluirAlterarPedidoRoute,
  compensacoesRealizadasRoute,
  incluirAlterarMDFeRoute,
  vendasRoutes,
  devolverVendaRoute,
  incluirAlterarNFRoute,
  incluirAlterarOSRoute,
  manutencaoOSRoute,
  ligacaoVeicCliRoute,
  receberCaixaLojaRoute,
  incluirAlterarNFServicoRoute,
  incluirAlterarClienteRoute,
  incluirAlterarCfopRoute,
  orcamentosOSRoute,
  incluirAlterarOrcamentoOSRoute,
  // programacaoProjetoRoute,
  // validarProgramacaoExecucaoRoute,
  gerencCaixaLojaRoute,
  caixaLojaRoute,
  incluirAlterarProdutoRoute,
  infoNfFaturadaRoute,
  historicoVeiculoRoute,
  itensRegraPromocaoRoute,
  frenteVendaRoute,
  incluirAlterarNFConsRoute,
  incluirMetaBaseadoVendasRoute,
  industriaRoutes,
  incluirAlterarRemessaEntreEmpresasRoute,
  estoqueRoutes,
  produtosEntradaNfXmlRoute,
  financeiroEntradaNfXmlRoute,
  gerarSugestaoCompraRoute,
  incluirOrdemCompraRoute,
  exportacaoBalancaRoute,
  incluirLayoutMarketplacesRoute,
  confirmarPrecosNfRoute,
  outsourcingRoutes,
  ...addaxTransportesRoutes,
  ...relatoriosNfSaidaRoutes,
  ...relatoriosCadastraisRoutes,
  ...relatoriosComissionamentoRoutes,
  ...relatoriosFinPagarRoutes,
  ...relatoriosVendasRoutes,
  ...relatoriosEstoqueRoutes,
  ...relatoriosPedidosRoutes,
  ...relatoriosFinReceberRoutes,
  ...relatoriosOutrosRoutes,
  ...relatoriosControladoriaRoutes,
  ...cadastroFinanceiroRoutes,
  ...cadastroOutrosRoutes,
  ...cadastroProdutosRoutes,
  ...cadastroClientesRoutes,
  ...cadastroCartoesRoutes,
  ...cadastroTributacaoRoutes,
  ...cadastroServicosRoutes,
  ...cadastroVendasRoutes,
  ...cadastroMDFeRoutes,
  ...cobrancaBancRoutes,
  ...demonstrativosOsRoutes,
  ...prontaEntregaCadastroRoutes,
  ...prontaEntregaRelatoriosRoutes,
  ...prontaEntregaGerenciamentoRoutes,
  ...contasReceberRoutes,
  ...cartaoCreditoRoutes,
  ...romaneioRoutes,
  ...pedidosExternosRoutes,
  ...pedidosInternosRoutes,
  ...atendimentoOSRoute,
  ...docsEletronNotasFiscaisRoutes,
  ...docsEletronTransporteRoutes,
  ...vendasGerenciamentoRoutes,
  ...comprasGerenciamentoRoute,
  ...faturamentoVendaOsRoutes,
  ...transferenciasRoutes,
  ...orcamentosRoutes,
  ...valePresenteRoutes,
  ...cadastroAcessosSistemaRoutes,
  ...contasPagarRoutes,
  ...industriaProcessosRoutes,
  ...acoesEstoqueRoutes,
];

// Auth specific routes
export const page = [authRoutes];

const routes = [
  cadastroRoutes,
  comprasRoutes,
  docsEletronRoutes,
  estoqueRoutes,
  faturamentoRoutes,
  financeiroRoutes,
  industriaRoutes,
  OsRoutes,
  pedidosRoutes,
  relatoriosRoutes,
  vendasRoutes,
  prontaEntregaRoutes,
  outsourcingRoutes,
];

// All routes
export default routes;
