import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  FormCheckbox,
  IntegerFormInput,
  MaskedInput,
  ModalBase,
  NumberInput,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { Row } from "reactstrap";
import UteisService from "../../../../../services/uteis/UteisService";
import { MODAL_ACTIONS, roundFloat } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { IncluirAlterarItemNFTributosCard } from "./components/IncluirAlterarItemNFTributosCard";
import { useSelector } from "react-redux";
import { showConfirmation } from "../../../../../components/ConfirmationModal";

export const IncluirAlterarItemNFModal = ({
  isOpen,
  toggle,
  action,
  idItemAlterar,
  idCliente,
  obrigInfoOrdemCompraNfe,
  finalidadeOperacao,
  identificaCor,
  idNfe,
  notifyEvent,
  itens,
  casasDecQuantidade,
  casasDecValor,
  formaSistemaTribut,
  focarDescricaoProd,
}) => {
  // Controle
  const stateCab = useSelector((state) => state.cabNF);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const refPesqProd = useRef();
  const refCfop = useRef();
  const refDescAdic = useRef();
  const refQuantidade = useRef();
  // Dados do Item
  const [idProduto, setIdProduto] = useState(0);
  const [nomeProduto, setNomeProduto] = useState("");
  const [cfop, setCfop] = useState("");
  const [descricaoAdicional, setDescricaoAdicional] = useState("");
  const [unidade, setUnidade] = useState("");
  const [ncm, setNcm] = useState("");
  const [vlrUnit, setVlrUnit] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [vlrTotProd, setVlrTotProd] = useState(0);
  const [vlrFrete, setVlrFrete] = useState(0);
  const [vlrOutros, setVlrOutros] = useState(0);
  const [verDescNfe, setVerDescNfe] = useState(false);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [nroOrdCompra, setNroOrdCompra] = useState("");
  const [itemOrdCompra, setItemOrdCompra] = useState(null);
  const [unidadeTributavel, setUnidadeTributavel] = useState("");
  const [qtdTributavel, setQtdTributavel] = useState(0);
  const [qtdUnitTributavel, setQtdUnitTributavel] = useState(0);
  const [qtdCompra, setQtdCompra] = useState(0);
  const [vlrAduaneiro, setVlrAduaneiro] = useState(0);
  const [cor, setCor] = useState("");
  const [impostos, setImpostos] = useState({});

  const onBeforeOpen = async () => {
    if (action === MODAL_ACTIONS.EDIT) {
      const [ok, ret] = await NotaFiscalService.inclusao.buscarItem(
        idItemAlterar
      );

      if (ok) {
        setIdProduto(ret.id_produto);
        setNomeProduto(ret.descricao);
        setCfop(ret.cfop);
        setQuantidade(parseFloat(ret.quantidade));
        setNcm(ret.ncm);
        setVlrUnit(parseFloat(ret.vlr_unit));
        setVlrItem(parseFloat(ret.vlr_item));
        setVlrDesc(parseFloat(ret.vlr_desc));
        setVlrTotal(parseFloat(ret.vlr_total));
        setVlrFrete(parseFloat(ret.vlr_frete));
        setVlrOutros(parseFloat(ret.vlr_outros));
        setVlrTotProd(parseFloat(ret.vlr_tot_prod));
        setVerDescNfe(ret.ver_desc_nfe);
        setNroOrdCompra(ret.nro_ord_compra ?? "");
        setItemOrdCompra(ret.item_ordem_compra ?? 0);
        setQtdTributavel(parseFloat(ret.qtd_tributavel));
        setUnidade(ret.unidade);
        setUnidadeTributavel(ret.unidade_tributavel);
        setCor(ret.cor);
        setImpostos(ret.impostos);

        setTimeout(() => {
          if (refPesqProd) {
            refPesqProd.current.setDescricao(ret.descricao);
            refPesqProd.current.setId(String(ret.id_produto));
            refPesqProd.current.setReferencia(ret.referencia);
          }
        }, 35);
      } else {
        toggle();
      }
    }
  };

  const calcularImpostos = async (
    _idProduto,
    _quantidade,
    _vlrItem,
    _cfop,
    _vlrOutros,
    _vlrFrete,
    _vlrAduaneiro
  ) => {
    if (_idProduto && _quantidade > 0 && vlrItem > 0) {
      if (formaSistemaTribut === "CFOP") {
        if (["", null, undefined].includes(_cfop)) {
          toastr.warning("Atenção", "Por Favor, informe a CFOP do Item.");
          return false;
        }
      } else {
        _cfop = "";
      }
      const _vlrTotal = roundFloat(_vlrItem * _quantidade, 2);

      const dentroEstab = ["JOR", "JRC", "MOB", "JOS", "CDV"].includes(
        stateCab.origem
      )
        ? false
        : true;

      const trib = await UteisService.calcularImpostosProduto(
        _idProduto,
        idCliente,
        finalidadeOperacao,
        55,
        dentroEstab,
        _cfop?.trim(),
        _quantidade,
        _vlrTotal,
        _vlrFrete,
        _vlrOutros,
        _vlrAduaneiro ?? 0,
        stateCab.nfImportacao
      );

      if (trib && Object.keys(trib).length > 0) {
        setImpostos(trib);
        if (formaSistemaTribut === "R_TRIB") {
          setCfop(trib.cfop);
        }
        setVlrTotal(
          roundFloat(parseFloat(trib.vlr_total_com_impostos) + _vlrOutros, 2)
        );
        return true;
      } else {
        setVlrTotal(_vlrTotal + _vlrOutros);
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSelectProduto = async ({
    idProduto,
    nomeProduto,
    referencia,
    precoVenda,
    unidade,
    cfop,
    ncm,
    unidadeTributavel,
    qtdUnitTributavel,
  }) => {
    cfop = (cfop ?? "").trim();
    setIdProduto(idProduto);
    setNomeProduto(nomeProduto);
    setQuantidade(0);
    setQtdTributavel(0);
    setVlrDesc(0);
    setVlrAduaneiro(0);
    setVlrItem(precoVenda);
    setVlrUnit(precoVenda);
    setUnidade(unidade);
    setNcm(ncm);
    setUnidadeTributavel(unidadeTributavel ?? "");
    setQtdUnitTributavel(qtdUnitTributavel ?? 0);
    handleSetCfop(cfop);
    refPesqProd.current.setDescricao(nomeProduto);
    refPesqProd.current.setId(String(idProduto));
    refPesqProd.current.setReferencia(String(referencia));

    if (["", null, undefined].includes(cfop)) {
      if (refCfop.current) {
        refCfop.current.clearValue();
      }
    }

    calcularImpostos(idProduto, 0, precoVenda, cfop, 0, 0, 0);
    setImpostos({});
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);
    setVlrItem(vlrUnit);
    setVlrDesc(0);
    setVlrTotProd(roundFloat(vlrItem * v, 2));
    if (qtdUnitTributavel) {
      setQtdTributavel(qtdUnitTributavel * v);
    }
    if (v > 0) {
      calcularImpostos(
        idProduto,
        v,
        vlrItem,
        cfop,
        vlrOutros,
        vlrFrete,
        vlrAduaneiro
      );
    }
  };

  const handleSetCfop = (v) => {
    setCfop(v);
    if (v !== cfop && v && quantidade > 0) {
      calcularImpostos(
        idProduto,
        quantidade,
        vlrItem,
        v,
        vlrOutros,
        vlrFrete,
        vlrAduaneiro
      );
    }
  };

  const handleSetVlrItem = (v) => {
    setVlrItem(v);

    const vDescUnit = v < vlrUnit ? vlrUnit - v : 0;
    const vTotDesc = vDescUnit * quantidade;
    const vTotProd = v * quantidade;
    setVlrDesc(roundFloat(vTotDesc, 2));
    setVlrTotProd(roundFloat(vTotProd, 2));

    if (vTotProd > 0)
      calcularImpostos(
        idProduto,
        quantidade,
        v,
        cfop,
        vlrOutros,
        vlrFrete,
        vlrAduaneiro
      );
  };

  const handleSetVlrDesc = (v) => {
    let vItem = 0;
    let vTotProd = vlrUnit * quantidade;
    if (v <= vTotProd) {
      setVlrDesc(v);
      vTotProd -= v;
      vItem = vTotProd / quantidade;
      setVlrItem(roundFloat(vItem, casasDecValor));
      vTotProd = vItem * quantidade;
      setVlrTotProd(roundFloat(vTotProd, 2));
      calcularImpostos(
        idProduto,
        quantidade,
        vItem,
        cfop,
        vlrOutros,
        vlrFrete,
        vlrAduaneiro
      );
    } else {
      toastr.warning(
        "Atenção",
        "O Valor de Desconto não pode ser superior ao Total Bruto de Produtos"
      );
      setVlrDesc(0);
      setVlrItem(0);
      setVlrTotProd(0);
    }
  };

  const handleSetVlrFrete = (v) => {
    setVlrFrete(v);
    if (quantidade > 0)
      calcularImpostos(
        idProduto,
        quantidade,
        vlrItem,
        cfop,
        vlrOutros,
        v,
        vlrAduaneiro
      );
  };

  const handleSetVlrOutros = (v) => {
    setVlrOutros(v);
    if (quantidade > 0)
      calcularImpostos(
        idProduto,
        quantidade,
        vlrItem,
        cfop,
        v,
        vlrFrete,
        vlrAduaneiro
      );
  };

  const handleSetVlrAduaneiro = (v) => {
    setVlrAduaneiro(v);
    if (quantidade > 0)
      calcularImpostos(
        idProduto,
        quantidade,
        vlrItem,
        cfop,
        vlrOutros,
        vlrFrete,
        v
      );
  };

  const handleSetVlrTotProd = (v) => {
    setVlrTotProd(v);

    if (quantidade > 0) {
      let vItem = 0;
      vItem = v / quantidade;
      setVlrItem(roundFloat(vItem, casasDecValor));

      if (vlrUnit > 0) {
        const vTotProdBruto = vlrUnit * quantidade;

        if (v < vTotProdBruto) {
          let vDesc = vTotProdBruto - v;
          setVlrDesc(roundFloat(vDesc, 2));
        }
      } else {
        setVlrDesc(0);
      }

      calcularImpostos(
        idProduto,
        quantidade,
        vItem,
        cfop,
        vlrOutros,
        vlrFrete,
        vlrAduaneiro
      );
    }
  };

  const onConfirm = () => {
    if ([0, null, undefined].includes(idProduto)) {
      toastr.warning("Atenção", "Por Favor, informe o Produto.");
      return;
    }

    if (formaSistemaTribut === "CFOP") {
      if (["", null, undefined].includes(cfop)) {
        toastr.warning("Atenção", "Por Favor, informe a CFOP do Item.");
        return;
      }
    }

    if ([0, null, undefined].includes(quantidade)) {
      toastr.warning("Atenção", "Por Favor, informe a Quantidade do Item.");
      return;
    }

    if ([0, null, undefined].includes(vlrItem)) {
      toastr.warning("Atenção", "Por Favor, informe o Valor do Item.");
      return;
    }

    if (obrigInfoOrdemCompraNfe) {
      if (["", null, undefined].includes((nroOrdCompra ?? "").trim())) {
        toastr.warning(
          "Atenção",
          "Por Favor, o número da Ordem de Compra do Item."
        );
        return;
      }

      if ([null, undefined].includes(itemOrdCompra)) {
        toastr.warning(
          "Atenção",
          "Por Favor, o Número do Item na Ordem de Compra."
        );
        return;
      }
    }

    if (finalidadeOperacao === "DEVOL") {
      if ([0, null, undefined].includes(qtdCompra)) {
        toastr.warning(
          "Atenção",
          "Por favor, informe a Quantidade de Compra do Produto"
        );
        return;
      }

      if (qtdCompra < quantidade) {
        toastr.warning(
          "Atenção",
          "A quantidade de compra não pode ser menor que a quantidade devolvida."
        );
        return;
      }
    }

    if (identificaCor && ["", null, undefined].includes((cor ?? "").trim())) {
      toastr.warning("Atenção", "Por favor, informe a Cor do Produto");
      return;
    }

    if (
      action === MODAL_ACTIONS.ADD &&
      itens.findIndex((e) => e.id_produto === idProduto) > -1
    ) {
      showConfirmation(
        <p style={{ textAlign: "center", width: "100%" }}>
          O Produto já foi incluido na Nota Fiscal.
          <br />
          Deseja incluí-lo novamente?
        </p>,
        () => handleSubmit()
      );
      return;
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    let idRegraTribut = formaSistemaTribut === "R_TRIB" ? impostos.id_regra : 0;

    let percDesc;
    let vlrAcre;
    if (vlrItem > vlrUnit) {
      percDesc = 0;
      vlrAcre = (vlrItem - vlrUnit) * quantidade;
    } else {
      if ((vlrUnit ?? 0) > 0) {
        percDesc = 100 - (vlrItem / vlrUnit) * 100;
      } else {
        percDesc = 0;
      }
      vlrAcre = 0;
    }

    setLoadingSubmit(true);

    const okTrib = await calcularImpostos(
      idProduto,
      quantidade,
      vlrItem,
      cfop,
      vlrOutros,
      vlrFrete,
      vlrAduaneiro
    );
    if (!okTrib) {
      setLoadingSubmit(false);
      return;
    }

    const commonPayload = {
      id_produto: idProduto,
      descricao: `${nomeProduto} ${descricaoAdicional}`.trim(),
      cfop: cfop,
      quantidade: quantidade,
      ncm: ncm,
      vlr_unit: vlrUnit,
      vlr_item: vlrItem,
      perc_desc: percDesc,
      vlr_desc: vlrDesc,
      vlr_acre: vlrAcre,
      vlr_total: vlrTotal,
      vlr_frete: vlrFrete,
      vlr_seguro: 0,
      vlr_outros: vlrOutros,
      vlr_tot_prod: vlrTotProd,
      mostrar_desc: verDescNfe,
      nro_ord_compra: nroOrdCompra,
      item_ordem_compra: itemOrdCompra ?? 0,
      id_regra_tribut: idRegraTribut,
      qtd_tributavel: qtdTributavel ?? 0,
      unidade: unidade,
      unidade_tributavel: unidadeTributavel ?? "",
      cor: cor,
      impostos: {
        base_calc_icms: parseFloat(impostos.base_calc_icms),
        sit_trib_icms: impostos.sit_trib_icms,
        origem: impostos.origem,
        modal_bc_icms: impostos.modal_bc_icms,
        perc_icms: parseFloat(impostos.perc_icms),
        perc_red_bc_icms: parseFloat(impostos.perc_red_bc_icms),
        vlr_icms: parseFloat(impostos.vlr_icms),
        base_calc_icms_st: parseFloat(impostos.base_calc_icms_st),
        perc_red_icms_st: parseFloat(impostos.perc_red_icms_st),
        modal_bc_icms_st: impostos.modal_bc_icms_st,
        perc_icms_st: parseFloat(impostos.perc_icms_st),
        mva_icms_st: parseFloat(impostos.mva_icms_st),
        vlr_icms_st: parseFloat(impostos.vlr_icms_st),
        vlr_cred_icms: parseFloat(impostos.vlr_cred_icms),
        perc_cred_icms: parseFloat(impostos.perc_cred_icms),
        vlr_icms_oper: parseFloat(impostos.vlr_icms_oper),
        vlr_difer_icms: parseFloat(impostos.vlr_difer_icms),
        perc_difer_icms: parseFloat(impostos.perc_difer_icms),
        base_calc_ipi: parseFloat(impostos.base_calc_ipi),
        sit_trib_ipi: impostos.sit_trib_ipi,
        enquad_ipi: impostos.enquad_ipi,
        perc_ipi: parseFloat(impostos.perc_ipi),
        vlr_ipi: parseFloat(impostos.vlr_ipi),
        base_calc_pis: parseFloat(impostos.base_calc_pis),
        sit_trib_pis: impostos.sit_trib_pis,
        perc_pis: parseFloat(impostos.perc_pis),
        vlr_pis: parseFloat(impostos.vlr_pis),
        base_calc_cofins: parseFloat(impostos.base_calc_cofins),
        sit_trib_cofins: impostos.sit_trib_cofins,
        perc_cofins: parseFloat(impostos.perc_cofins),
        vlr_cofins: parseFloat(impostos.vlr_cofins),
        vlr_fcp: parseFloat(impostos.vlr_fcp),
        base_calc_fcp: parseFloat(impostos.base_calc_fcp),
        perc_deson_icms: parseFloat(impostos.perc_deson_icms),
        vlr_deson_icms: parseFloat(impostos.vlr_deson_icms),
        base_calc_st_ret: parseFloat(impostos.base_calc_st_ret),
        vlr_icms_substituto: parseFloat(impostos.vlr_icms_substituto),
        vlr_icms_st_ret: parseFloat(impostos.vlr_icms_st_ret),
        perc_red_bc_icms_efet: parseFloat(impostos.perc_red_bc_icms_efet),
        perc_icms_efet: parseFloat(impostos.perc_icms_efet),
        base_calc_icms_efet: parseFloat(impostos.base_calc_icms_efet),
        vlr_icms_efet: parseFloat(impostos.vlr_icms_efet),
        c_benef: impostos.c_benef,
        base_calc_icms_uf_dest: parseFloat(impostos.base_calc_icms_uf_dest),
        base_calc_fcp_uf_dest: parseFloat(impostos.base_calc_fcp_uf_dest),
        perc_fcp_uf_dest: parseFloat(impostos.perc_fcp_uf_dest),
        aliq_icms_uf_dest: parseFloat(impostos.aliq_icms_uf_dest),
        aliq_icms_inter: parseFloat(impostos.aliq_icms_inter),
        aliq_icms_inter_partilha: parseFloat(impostos.aliq_icms_inter_partilha),
        vlr_fcp_uf_dest: parseFloat(impostos.vlr_fcp_uf_dest),
        vlr_icms_uf_dest: parseFloat(impostos.vlr_icms_uf_dest),
        vlr_icms_uf_remet: parseFloat(impostos.vlr_icms_uf_remet),
        qtd_bc_icms_mono_ret: parseFloat(impostos.qtd_bc_icms_mono_ret),
        aliq_ad_rem_icms_mono_ret: parseFloat(
          impostos.aliq_ad_rem_icms_mono_ret
        ),
        vlr_icms_mono_ret: parseFloat(impostos.vlr_icms_mono_ret),
        vlr_aduaneiro: vlrAduaneiro,
      },
    };

    let ok;
    if (action === MODAL_ACTIONS.ADD) {
      const payload = {
        id_cab: idNfe,
        importacao: false,
        ...commonPayload,
      };

      [ok] = await NotaFiscalService.inclusao.incluirItem(payload);
    } else {
      const payload = {
        id_item: idItemAlterar,
        ...commonPayload,
      };

      [ok] = await NotaFiscalService.inclusao.alterarItem(payload);
    }

    if (ok) {
      if (action === MODAL_ACTIONS.ADD) {
        limparDados();
        refPesqProd.current.focus();
      } else {
        toggle();
      }
      notifyEvent();
    }
    setLoadingSubmit(false);
  };

  const limparDados = () => {
    setIdProduto(0);
    setNomeProduto("");
    setCfop("");
    setDescricaoAdicional("");
    setUnidade("");
    setNcm("");
    setVlrUnit(0);
    setQuantidade(0);
    setVlrDesc(0);
    setVlrItem(0);
    setVlrTotProd(0);
    setVlrFrete(0);
    setVlrOutros(0);
    setVerDescNfe(false);
    setVlrTotal(0);
    setUnidadeTributavel("");
    setQtdTributavel(0);
    setNroOrdCompra("");
    setItemOrdCompra(0);
    setQtdCompra(0);
    setCor("");
    setImpostos({});
    setQtdUnitTributavel(0);
    setVlrAduaneiro(0);
    if (refCfop.current) refCfop.current.clearValue();
    refPesqProd.current.clear();
  };

  const focarProximoCampo = () => {
    setTimeout(() => {
      if (focarDescricaoProd) {
        if (refDescAdic.current) {
          refDescAdic.current.focus();
        }
      } else {
        if (refQuantidade.current) {
          refQuantidade.current.focus();
        }
      }
    }, 15);
  };

  const semProduto = [0, null, undefined].includes(idProduto);

  return (
    <ModalBase
      title={
        action === MODAL_ACTIONS.ADD
          ? "Inclusão de Item em NFe"
          : "Alteração de Item de NFe"
      }
      number="0055_11"
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      onBeforeOpen={onBeforeOpen}
      onClosed={limparDados}
      onConfirm={onConfirm}
      loadingConfirm={loadingSubmit}
      confirmButtonText="Confirmar Item"
    >
      <Row>
        <PesqProduto
          onConfirm={handleSelectProduto}
          ref={refPesqProd}
          selectNextField={focarProximoCampo}
          onChangeDescricao={setNomeProduto}
          autoFocus
        />
        {formaSistemaTribut === "R_TRIB" ? (
          <FixedField label="CFOP" value={cfop} />
        ) : (
          <UnlockToEdit
            enabled={!["", null, undefined].includes(cfop)}
            hint="Clique aqui para alterar a CFOP do produto na NF"
          >
            <AsyncComboBox
              isConcatField
              concatModelName="cfop"
              isSearchable
              md={6}
              label="CFOP"
              onChange={(s) => handleSetCfop(s?.value)}
              defaultValue={cfop}
              defaultOptions
              disabled={semProduto}
              ref={refCfop}
              clearOnDisable={false}
            />
          </UnlockToEdit>
        )}
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Descrição Adicional"
          value={descricaoAdicional}
          onChange={(e, v) => setDescricaoAdicional(v)}
          disabled={semProduto}
          ref={refDescAdic}
        />
        <TextInput
          md={1}
          label="Unidade"
          value={unidade}
          disabled
          inputStyle={{ textAlign: "center" }}
        />
        <UnlockToEdit enabled={!semProduto}>
          <MaskedInput
            md={2}
            label="NCM"
            value={ncm}
            onChange={(e, v) => setNcm(v)}
            mask="99999999"
            maskChar={null}
            disabled={semProduto}
            inputStyle={{ textAlign: "center" }}
          />
        </UnlockToEdit>
        <NumberInput
          divClassName="ml-auto"
          md={2}
          label="Valor Unitário"
          value={vlrUnit}
          disabled
          decimalPlaces={casasDecValor}
        />
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          decimalPlaces={casasDecQuantidade}
          disabled={semProduto}
          ref={refQuantidade}
        />
        <NumberInput
          md={2}
          label="Desconto"
          value={vlrDesc}
          onChange={handleSetVlrDesc}
          disabled={semProduto}
        />
        <FormCheckbox
          divClassName="px-0"
          label="Mostrar na NF"
          checked={verDescNfe}
          onChange={() => setVerDescNfe(!verDescNfe)}
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Vlr. Item"
          value={vlrItem}
          onChange={handleSetVlrItem}
          decimalPlaces={casasDecValor}
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Total Produtos"
          value={vlrTotProd}
          onChange={handleSetVlrTotProd}
          disabled={semProduto}
        />
        <NumberInput
          divClassName="ml-auto"
          md={2}
          label="Valor Total"
          value={vlrTotal}
          disabled
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={2}
          label="Frete"
          value={vlrFrete}
          onChange={handleSetVlrFrete}
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Outras Despesas"
          value={vlrOutros}
          onChange={handleSetVlrOutros}
          disabled={semProduto}
        />
        {obrigInfoOrdemCompraNfe && (
          <>
            <TextInput
              md={2}
              label="Nº Ordem Compra"
              value={nroOrdCompra}
              onChange={(e, v) => setNroOrdCompra(v)}
              maxLength={15}
              disabled={semProduto}
            />
            <IntegerFormInput
              md={2}
              label="Item O.C."
              defaultValue={itemOrdCompra}
              onChange={setItemOrdCompra}
              disabled={semProduto}
            />
          </>
        )}
        {finalidadeOperacao === "DEVOL" && (
          <NumberInput
            md={2}
            label="Qtd. Compra"
            hint="Utilizado para calcular o percentual de devolução de IPI"
            value={qtdCompra}
            onChange={setQtdCompra}
            disabled={semProduto}
          />
        )}
        {stateCab.nfImportacao && (
          <NumberInput
            md={2}
            label="Valor Aduaneiro"
            hint="Utilizado para calcular o valor de ICMS de Importação"
            value={vlrAduaneiro}
            onChange={handleSetVlrAduaneiro}
            disabled={semProduto}
          />
        )}
        {identificaCor && (
          <TextInput
            md={2}
            label="Cor"
            value={cor}
            onChange={(e, v) => setCor(v)}
            maxLength={30}
            disabled={semProduto}
          />
        )}
      </Row>
      <IncluirAlterarItemNFTributosCard
        impostos={impostos}
        unidadeTributavel={unidadeTributavel}
        setUnidadeTributavel={setUnidadeTributavel}
        qtdTributavel={qtdTributavel}
        setQtdTributavel={setQtdTributavel}
      />
    </ModalBase>
  );
};
