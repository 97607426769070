import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIdTipoDoc,
  setUltimoRps,
  setCaminLogoPrefeit,
  setCaminSalvarXml,
  setNaturOperPadrao,
  setRegimeEspec,
  setIncentivCultural,
  setIdServicoPadraoFatura,
  setUsuarioAcessoProvedorNfse,
  setSenhaAcessoProvedorNfse,
  setProvedor,
  setLocalPrestaServPadrao,
  setUltimoLoteRps,
} from "../store/nf_servico_slice";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { Row, Col } from "reactstrap";
import { useEffect } from "react";
import NotaFiscalServicoService from "../../../../services/docs_eletron/NotaFiscalServicoService";
import { RadioItem } from "../../../../components/RadioGroup";

const regimesEspecTribut = [
  { label: "Sem regime especial", value: 0 },
  { label: "Microempresa Municipal", value: 1 },
  { label: "Estimativa", value: 2 },
  { label: "Sociedade de Profissionais", value: 3 },
  { label: "Cooperativa", value: 4 },
  { label: "Microempresario Individual", value: 5 },
  { label: "Microempresario Empresa PP", value: 6 },
  { label: "Lucro Real", value: 7 },
  { label: "Lucro Presumido", value: 8 },
  { label: "Simples Nacional", value: 9 },
  { label: "Imune", value: 10 },
  { label: "Empresa Individual RELI", value: 11 },
  { label: "Empresa PP", value: 12 },
  { label: "MicroEmpresario", value: 13 },
  { label: "Outros", value: 14 },
];

const provedores = [
  { label: "Não Definido", value: "NAO" },
  { label: "IPM", value: "IPM" },
  { label: "GOVBR", value: "GOVBR" },
  { label: "Tecnos", value: "TECNOS" },
  { label: "Infisc", value: "GIF4" },
];

export const PersonNfServico = () => {
  const [natOps, setNatOps] = useState([]);
  const store = useSelector((state) => state.nf_servico);
  const dispatch = useDispatch();

  const carregarNaturezasOperacao = async () => {
    const [ok, ret] = await NotaFiscalServicoService.naturezaOperacao.listar();

    setNatOps(ok ? ret.map((e) => ({ label: e.descricao, value: e.id })) : []);
  };

  const onChangeProvedor = () => {
    if (store.provedor === "NAO") {
      dispatch(setUsuarioAcessoProvedorNfse(""));
      dispatch(setSenhaAcessoProvedorNfse(""));
    }
  };
  useEffect(onChangeProvedor, [store.provedor]);

  useEffect(() => {
    carregarNaturezasOperacao();
  }, []);

  return (
    <>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento Padrão"
          isSearchable
          isConcatField
          concatModelName="tipo_documento"
          defaultValue={store.id_tipo_doc}
          onChange={(s) => dispatch(setIdTipoDoc(s?.value ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Serviço Padrão para Faturamento"
          isSearchable
          isClearable
          isConcatField
          concatModelName="servico"
          defaultValue={store.id_servico_padrao_fatura}
          onChange={(s) => dispatch(setIdServicoPadraoFatura(s?.value ?? null))}
        />
      </Row>
      <Row>
        <IntegerFormInput
          label="Número da Última NFS-e Emitida"
          md={2}
          defaultValue={store.ultimo_rps}
          onChange={(v) => dispatch(setUltimoRps(v))}
        />
        <IntegerFormInput
          label="Número do Último Lote de RPS"
          md={2}
          defaultValue={store.ultimo_lote_rps}
          onChange={(v) => dispatch(setUltimoLoteRps(v))}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Salvar XML da NFS-e Em"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Caminho do Logo da Prefeitura"
          value={store.camin_logo_prefeit}
          onChange={(e, v) => dispatch(setCaminLogoPrefeit(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Natureza de Operação Padrão"
          options={natOps}
          defaultValue={store.natur_oper_padrao}
          onChange={(s) => dispatch(setNaturOperPadrao(s?.value))}
          isDisabled={natOps.length === 0}
          placeholder={
            natOps.length === 0 && "Nenhuma Natureza de Operação Cadastrada"
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={4}
          label="Regime Especial de Tributação"
          options={regimesEspecTribut}
          defaultValue={store.regime_espec}
          onChange={(s) => dispatch(setRegimeEspec(s?.value ?? 0))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Incentivador Cultural"
          checked={store.incentiv_cultural}
          onChange={() =>
            dispatch(setIncentivCultural(!store.incentiv_cultural))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Local de Prestação do Serviço"
          value={store.local_presta_serv_padrao}
          onChange={(v) => dispatch(setLocalPrestaServPadrao(v))}
        >
          <RadioItem label="Cidade do Emitente" value="EMI" />
          <RadioItem label="Cidade do Tomador" value="TOM" />
          <RadioItem label="Sempre Selecionar" value="SSL" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={4}
          label="Provedor"
          options={provedores}
          defaultValue={store.provedor}
          onChange={(s) => dispatch(setProvedor(s?.value))}
          menuPlacement="top"
        />
        <TextInput
          md={3}
          label="Usuário"
          value={store.usuario_acesso_provedor_nfse}
          onChange={(e, v) => dispatch(setUsuarioAcessoProvedorNfse(v))}
          maxLength={30}
          forceUppercase={false}
          disabled={["NAO", "TECNOS"].includes(store.provedor)}
        />
        <TextInput
          md={3}
          label="Senha"
          value={store.senha_acesso_provedor_nfse}
          onChange={(e, v) => dispatch(setSenhaAcessoProvedorNfse(v))}
          maxLength={30}
          forceUppercase={false}
          disabled={["NAO", "TECNOS"].includes(store.provedor)}
        />
        {store.provedor === "IPM" &&
          localStorage.getItem("cod_ibge_empresa") === "4314050" && (
            <Col md={12}>
              <p
                style={{
                  marginTop: "-3px",
                  marginBottom: "0",
                  paddingLeft: "15px",
                }}
                className="text-danger"
              >
                O provedor IPM da cidade de Parobé/RS não disponibiliza o XML da
                NFS-e
              </p>
            </Col>
          )}
      </Row>
    </>
  );
};
