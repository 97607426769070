import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import ValePresenteService from "../../../../services/vale_presente/ValePresenteService";
import { GerenciamentoValePresenteGrid } from "./components/GerenciamentoValePresenteGrid";
import { showConfirmation } from "../../../../components/ConfirmationModal";

const GerenciamentoValePresente = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idValePresente, setIdValePresente] = useState(null);
  const [mostrarUtilizados, setMostrarUtilizados] = useState(false);
  const [idVendaOsUtilizado, setIdVendaOsUtilizado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_utilizados: mostrarUtilizados,
      id_utilizado: idVendaOsUtilizado,
      id_vale_presente: idValePresente,
    };

    setLoading(true);
    const [ok, ret] = await ValePresenteService.gerenciamento.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const excluirValePresente = async (id) => {
    const excluirVale = async () => {
      const [ok] = await ValePresenteService.gerenciamento.excluir(id);
      if (ok) {
        carregarDados();
      }
    };
    showConfirmation(
      `Você tem certeza que deseja excluir o Vale Presente Nº ${id}`,
      excluirVale,
      () => {}
    );
  };

  return (
    <PageContainer title="Gerenciamento de Vale Presente" number="0136">
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} defaultOption={"7D"} />
          <IntegerFormInput
            md={2}
            label="Nº Vale Presente"
            value={idValePresente}
            onChange={setIdValePresente}
          />
          <IntegerFormInput
            md={2}
            label="Nº Venda/O.S. Utilizado"
            value={idVendaOsUtilizado}
            onChange={setIdVendaOsUtilizado}
          />
          <FormCheckbox
            label="Mostrar Já Utilizados"
            checked={mostrarUtilizados}
            onChange={() => setMostrarUtilizados(!mostrarUtilizados)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <GerenciamentoValePresenteGrid
          dados={dados}
          excluir={excluirValePresente}
        />
      </Card>
    </PageContainer>
  );
};

export default GerenciamentoValePresente;
