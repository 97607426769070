import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ult_nro_lote: 0,
  solicita_senha_abre_conta_altera_limite: false,
  bloq_uso_cli_com_saldo_devedor: false,
};

export const creditoRotativoSlice = createSlice({
  name: "credito_rotativo_slice",
  initialState: initialState,
  reducers: {
    setupCreditoRotativo: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setSolicitaSenhaAbreContaAlteraLimite: (state, action) => {
      state.solicita_senha_abre_conta_altera_limite = action.payload;
    },
    setBloqUsoCliComSaldoDevedor: (state, action) => {
      state.bloq_uso_cli_com_saldo_devedor = action.payload;
    },
  },
});

export const {
  setupCreditoRotativo,
  setSolicitaSenhaAbreContaAlteraLimite,
  setBloqUsoCliComSaldoDevedor,
} = creditoRotativoSlice.actions;

export default creditoRotativoSlice.reducer;
