import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  PageContainer,
} from "../../../components";
import { RadioGroup, RadioItem } from "../../../components/RadioGroup";
import {
  exportDataToSheet,
  formatDateISO,
  formatarValor,
} from "../../../coreUtils";
import { CurvaABCGrid } from "./components/CurvaABCGrid";
import { docPrintReport } from "../../../pdf";
import { apiGetV2 } from "../../../apiV2";

const formatosCompra = [
  { label: "Fornecedor + Produto", value: "fornecedor_produto" },
  { label: "Fornecedor", value: "fornecedor" },
  { label: "Produto", value: "produto" },
];

const formatosVenda = [
  { label: "Cliente", value: "cliente" },
  { label: "Cidade", value: "cidade" },
  { label: "Produto", value: "produto" },
  { label: "Serviço", value: "servico" },
  { label: "Cliente + Produto", value: "cliente_produto" },
];

const indicadoresCompra = [
  { label: "Quantidade", value: "QTD" },
  { label: "Valor", value: "VAL" },
];

const indicadoresVenda = [
  { label: "Quantidade", value: "QTD" },
  { label: "Valor", value: "VAL" },
  { label: "Rentabilidade", value: "RTA" },
];

export const CurvaABC = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [modo, setModo] = useState("V");
  const [formato, setFormato] = useState(formatosVenda[0].value);
  const [indicador, setIndicador] = useState(indicadoresVenda[1].value);
  const [maxItens, setMaxItens] = useState(100);
  const [idCliente, setIdCliente] = useState(null);
  const [idTipoCliente, setIdTipoCliente] = useState(null);
  const [idRamoAtividade, setIdRamoAtividade] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);
  const [idSubGrupo, setIdSubGrupo] = useState(null);
  const [idFabricante, setIdFabricante] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [idTecnico, setIdTecnico] = useState(null);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const indicadorRef = useRef(null);

  useEffect(() => {
    setDados([]);
    setTotais({});
  }, [
    dataIni,
    dataFim,
    modo,
    formato,
    indicador,
    maxItens,
    idTipoCliente,
    idRamoAtividade,
    idGrupo,
    idSubGrupo,
    idFabricante,
    idCliente,
    idVendedor,
    idProduto,
    idTecnico,
  ]);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const handleSetModo = (v) => {
    setModo(v);
    setFormato(v === "C" ? formatosCompra[0] : formatosVenda[0]);
    if (v === "C" && indicador === indicadoresVenda[2].value) {
      indicadorRef.current.setValue(indicadoresCompra[0].value);
    }
    setIdVendedor(null);
  };

  const handleSetFormato = (s) => {
    setFormato(s.value);
    setDados([]);
    if (s.value !== "servico") {
      setIdTecnico(null);
    }
  };

  const buscarDados = () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      indicador: indicador,
      max_itens: maxItens,
      id_tipo_cliente: idTipoCliente,
      id_ramo_atividade: idRamoAtividade,
      id_grupo: idGrupo,
      id_sub_grupo: idSubGrupo,
      id_fabricante: idFabricante,
      id_vendedor: idVendedor,
      id_cliente: idCliente,
      id_produto: idProduto,
      id_tecnico: idTecnico,
    };

    const urlModo = modo === "C" ? "compras" : "venda";

    return apiGetV2(`/relatorios/${urlModo}/curva_abc/${formato}/`, params);
  };

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.itens);
      setTotais(ret.totais);
    }
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/curva_abc/",
        {
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          dados: ret.itens,
          totais: ret.totais,
          modo: modo,
          formato: formato,
          indicador: indicadoresVenda.find((e) => e.value === indicador).label,
        },
        "5037_1"
      );
    }

    setLoadingImprimir(false);
  };

  const exportarRelatorio = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const formatarLinha = (row) => {
        let data = {};
        if (modo === "V") {
          if (formato === "cliente") {
            data = {
              Rank: row.rank,
              Cliente: `${row.nome_cliente} (${row.id_cliente})`,
              "Nome Fantasia": row.nome_fantasia,
            };
          } else if (formato === "produto") {
            data = {
              Rank: row.rank,
              Produto: `${row.nome_produto} (${row.id_produto})`,
              Estoque: parseFloat(row.estoque),
            };
          } else if (formato === "cidade") {
            data = {
              Rank: row.rank,
              Cidade: `${row.nome_cidade} (${row.id_cidade})`,
            };
          } else if (formato === "servico") {
            data = {
              Rank: row.rank,
              Serviço: `${row.nome_servico} ${row.id_servico}`,
            };
          } else if (formato === "cliente_produto") {
            data = {
              Rank: row.rank,
              Cliente: `${row.nome_cliente} (${row.id_cliente})`,
              Produto: `${row.nome_produto} (${row.id_produto})`,
            };
          }
          data["Quantidade"] = parseFloat(row.qtd);
          data["Preço Médio"] = parseFloat(row.preco_medio);
          data["Valor Total"] = parseFloat(row.valor);
          data["Valor/Dia"] = parseFloat(row.valor_dia);
          data["Rentabilidade"] = parseFloat(row.rentabilidade);
          data["Participação"] = parseFloat(row.perc_participa);
        } else {
          if (formato === "fornecedor_produto") {
            data = {
              Rank: row.rank,
              Fornecedor: `${row.nome_fornecedor} (${row.id_fornecedor})`,
              Produto: `${row.nome_produto} (${row.id_produto})`,
            };
          } else if (formato === "fornecedor") {
            data = {
              Rank: row.rank,
              Fornecedor: `${row.nome_fornecedor} (${row.id_fornecedor})`,
            };
          } else if (formato === "produto") {
            data = {
              Rank: row.rank,
              Produto: `${row.nome_produto} (${row.id_produto})`,
            };
          }

          data["Quantidade"] = parseFloat(row.qtd);
          data["Preço Médio"] = parseFloat(row.preco_medio);
          data["Valor Total"] = parseFloat(row.valor);
          data["Valor/Dia"] = parseFloat(row.valor_dia);
          data["Participação"] = parseFloat(row.perc_participa);
        }

        return data;
      };

      let nomeFormato;
      let nomeModo;
      if (modo === "V") {
        nomeFormato = formatosVenda.find((e) => e.value === formato)?.label;
        nomeModo = "Venda";
      } else {
        nomeFormato = formatosCompra.find((e) => e.value === formato)?.label;
        nomeModo = "Compra";
      }

      exportDataToSheet(
        ret.itens.map(formatarLinha),
        `Curva ABC ${nomeModo} - ${nomeFormato}`,
        { dataIni: dataIni, dataFim: dataFim }
      );
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer title="Curva ABC" number="5037">
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            md={2}
            label="Indicador"
            options={modo === "C" ? indicadoresCompra : indicadoresVenda}
            defaultValue={indicadoresCompra[1].value}
            onChange={(s) => setIndicador(s?.value ?? null)}
            ref={indicadorRef}
            isSearchable={false}
          />
          <IntegerFormInput
            md={2}
            label="Limite de Itens"
            defaultValue={maxItens}
            onChange={(v) => setMaxItens(v)}
          />
        </Row>
        <Row>
          <RadioGroup label="Consultar" value={modo} onChange={handleSetModo}>
            <RadioItem label="Venda" value="V" />
            <RadioItem label="Compra" value="C" />
          </RadioGroup>
          {modo === "C" ? (
            <ComboBox
              md={3}
              label="Agrupamento"
              options={formatosCompra}
              defaultValue={formatosCompra[0].value}
              onChange={handleSetFormato}
              isSearchable={false}
            />
          ) : (
            <ComboBox
              md={3}
              label="Agrupamento"
              options={formatosVenda}
              defaultValue={formatosVenda[0].value}
              onChange={handleSetFormato}
              isSearchable={false}
            />
          )}
          {modo === "V" && (
            <>
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="colaborador"
                isSearchable
                isClearable
                label="Vendedor"
                defaultOptions
                onChange={(s) => setIdVendedor(s?.map((e) => e.value) ?? [])}
                isMulti
              />
              {formato !== "servico" && (
                <AsyncComboBox
                  md={4}
                  isConcatField
                  concatModelName="produto"
                  isSearchable
                  isClearable
                  label="Produto"
                  onChange={(s) => setIdProduto(s?.map((e) => e.value) ?? [])}
                  isMulti
                />
              )}
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="tipo_cliente"
                isSearchable
                isClearable
                label="Tipo de Cliente"
                onChange={(s) => setIdTipoCliente(s?.map((e) => e.value) ?? [])}
                isMulti
                hideShortcut
                defaultOptions
              />
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="ramo_atividade"
                isSearchable
                isClearable
                label="Ramo de Atividade"
                onChange={(s) =>
                  setIdRamoAtividade(s?.map((e) => e.value) ?? [])
                }
                defaultOptions
                isMulti
              />
            </>
          )}
          {modo === "C" && (
            <AsyncComboBox
              md={4}
              isConcatField
              concatModelName="produto"
              isSearchable
              isClearable
              label="Produto"
              onChange={(s) => setIdProduto(s?.map((e) => e.value) ?? [])}
              isMulti
            />
          )}
          {["produto", "cliente_produto"].includes(formato) && (
            <>
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="grupo"
                isSearchable
                isClearable
                label="Grupo"
                onChange={(s) => setIdGrupo(s?.map((e) => e.value) ?? [])}
                isMulti
                defaultOptions
              />
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="sub_grupo"
                isSearchable
                isClearable
                label="Sub-grupo"
                onChange={(s) => setIdSubGrupo(s?.map((e) => e.value) ?? [])}
                isMulti
                defaultOptions
              />
              <AsyncComboBox
                md={4}
                isConcatField
                concatModelName="fabricante"
                isSearchable
                isClearable
                label="Fabricante"
                onChange={(s) => setIdFabricante(s?.map((e) => e.value) ?? [])}
                isMulti
                defaultOptions
              />
              <AsyncComboBox
                md={5}
                isConcatField
                concatModelName="cliente"
                isSearchable
                isClearable
                label="Cliente"
                onChange={(s) => setIdCliente(s?.map((e) => e.value) ?? [])}
                isMulti
              />
            </>
          )}
          {formato === "servico" && (
            <>
              <AsyncComboBox
                md={4}
                isConcatField
                concatModelName="cliente"
                isSearchable
                isClearable
                label="Cliente"
                onChange={(s) => setIdCliente(s?.map((e) => e.value) ?? [])}
                isMulti
              />
              <AsyncComboBox
                md={4}
                isConcatField
                concatModelName="tecnico"
                isSearchable
                isClearable
                label="Técnico"
                onChange={(s) => setIdTecnico(s?.map((e) => e.value) ?? [])}
                isMulti
                defaultOptions
              />
            </>
          )}
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportarRelatorio}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Itens"
          value={`Q: ${formatarValor(
            totais?.q_itens,
            2,
            0,
            true
          )} | V: ${formatarValor(totais?.v_itens, 2, 2, true)}`}
          format={false}
        />
        <CardTotaisItem
          label="Geral"
          value={`Q: ${formatarValor(
            totais?.q_geral,
            2,
            0,
            true
          )} | V: ${formatarValor(totais?.v_geral, 2, 2, true)}`}
          format={false}
        />
        <CardTotaisItem
          label="Participação"
          value={totais?.perc_partic_itens}
          isPercentage
        />
      </CardTotais>
      <Card body>
        <CurvaABCGrid data={dados} modo={modo} formato={formato} />
      </Card>
    </PageContainer>
  );
};
