import React from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../../components";
import { BsFillSignpost2Fill } from "react-icons/bs";
import { FaHandHoldingDollar, FaTruckArrowRight } from "react-icons/fa6";
import { TbFileDollar } from "react-icons/tb";
import {
  centralPedidosRoute,
  centralPedInternoRoute,
  centralRomaneioRoute,
  pedidosConferenciaValoresRecebidosEmMaosRoute,
  pedidosFlexRepresentantesRoute,
  romaneioRealizadosRoute,
} from "../../../routes/modules/pedidos";
import { MdForklift, MdOutlineFormatAlignCenter } from "react-icons/md";

export const PedidoVenda = () => {
  return (
    <PageContainer title="Pedidos de Venda">
      <Divider className="pl-3 pr-5">Pedido Interno</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Central de Pedidos Internos"
          icon={MdOutlineFormatAlignCenter}
          pathname={centralPedInternoRoute.path}
          nroTela={centralPedInternoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Pedido Externo</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Central de Pedidos Externos"
          icon={BsFillSignpost2Fill}
          pathname={centralPedidosRoute.path}
          nroTela={centralPedidosRoute.nroTela}
        />
        <MenuGroupButton
          label="Conferência de Valores Recebidos em Mãos"
          icon={FaHandHoldingDollar}
          pathname={pedidosConferenciaValoresRecebidosEmMaosRoute.path}
          nroTela={pedidosConferenciaValoresRecebidosEmMaosRoute.nroTela}
        />
        <MenuGroupButton
          label="Flex de Representantes"
          icon={TbFileDollar}
          pathname={pedidosFlexRepresentantesRoute.path}
          nroTela={pedidosFlexRepresentantesRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Romaneio</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Central de Romaneios"
          icon={MdForklift}
          pathname={centralRomaneioRoute.path}
          nroTela={centralRomaneioRoute.nroTela}
        />
        <MenuGroupButton
          label="Romaneios Realizados"
          icon={FaTruckArrowRight}
          pathname={romaneioRealizadosRoute.path}
          nroTela={romaneioRealizadosRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
