import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FormCheckbox,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

const ordenarPorOptions = [
  { label: "Nome", value: "NOM" },
  { label: "Código", value: "COD" },
  { label: "Local", value: "LOC" },
  { label: "Referência", value: "REF" },
];

const mostrarOptions = [
  { label: "Todos", value: "ALL" },
  { label: "Com estoque positivo", value: "POS" },
  { label: "Com estoque negativo", value: "NEG" },
  { label: "Com estoque zerado", value: "ZER" },
];

export const ListagemProdutosContagem = () => {
  const [idFabricante, setIdFabricante] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);
  const [pistaLocal, setPistaLocal] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [ordenarPor, setOrdenarPor] = useState(ordenarPorOptions[0].value);
  const [mostrarLocal, setMostrarLocal] = useState(true);
  const [mostrarQuantidade, setMostrarQuantidade] = useState(true);
  const [loading, setLoading] = useState(false);

  const imprimir = async () => {
    const params = {
      id_fabricante: idFabricante,
      id_grupo: idGrupo,
      pista_local: pistaLocal,
      somente_ativos: somenteAtivos,
      mostrar: mostrar,
      ordenar_por: ordenarPor,
    };

    setLoading(true);
    const [ok, ret] = await apiGetV2(
      "/relatorios/estoque/lista_produtos_contagem/",
      params
    );
    if (ok) {
      const payload = {
        dados: ret,
        mostrar_local: mostrarLocal,
        mostrar_quantidade: mostrarQuantidade,
      };

      await docPrintReport(
        "/relatorios/estoque/lista_produtos_contagem/",
        payload,
        "5066",
        true
      );
    }
    setLoading(false);
  };

  return (
    <PageContainer
      title="Listagem de Produtos para Contagem de Estoque"
      number="5066"
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Fabricante"
            concatModelName="fabricante"
            defaultValue={idFabricante}
            onChange={(s) => setIdFabricante(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={4}
            label="Grupo"
            concatModelName="grupo"
            defaultValue={idGrupo}
            onChange={(s) => setIdGrupo(s?.value)}
            hideShortcut
          />
          <TextInput
            md={3}
            label="Local"
            value={pistaLocal}
            onChange={(e, v) => setPistaLocal(v)}
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Mostrar Produtos"
            options={mostrarOptions}
            defaultValue={mostrar}
            onChange={(s) => setMostrar(s?.value)}
          />
          <FormCheckbox
            label="Mostrar Localização"
            checked={mostrarLocal}
            onChange={() => setMostrarLocal(!mostrarLocal)}
          />
          <FormCheckbox
            label="Mostrar Saldo em Estoque"
            checked={mostrarQuantidade}
            onChange={() => setMostrarQuantidade(!mostrarQuantidade)}
          />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <ComboBox
            md={2}
            label="Ordenar Por"
            options={ordenarPorOptions}
            defaultValue={ordenarPor}
            onChange={(s) => setOrdenarPor(s?.value)}
          />
          <BotaoImprimir onClick={imprimir} loading={loading} />
        </Row>
      </Card>
    </PageContainer>
  );
};
