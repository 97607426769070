import { Outsourcing } from "../../pages/outsourcing/Outsourcing";
import { AddaxTransportes } from "../../pages/outsourcing/tag_transp/AddaxTransportes";
import { FaRegFileCode } from "react-icons/fa";

export const addaxTranspRoute = {
  path: "/outsourcing/tag_transp/",
  name: "Addax",
  id: "AcSkWeb0134",
  nroTela: "0134",
  component: AddaxTransportes,
};

export const addaxTransportesRoutes = [addaxTranspRoute];

export const outsourcingRoutes = {
  path: "/outsourcing",
  name: "Outsourcing",
  icon: FaRegFileCode,
  component: Outsourcing,
  canGoBack: false,
};
