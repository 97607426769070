import React, { useState } from "react";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  NumberInput,
} from "../../../../../components";
import { Label, Row } from "reactstrap";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { apiGetV2, apiPutV2 } from "../../../../../apiV2";
import EntradaNfXmlService from "../../../../../services/compras/EntradaNfXmlService";
import { routesBaseLigacaoProdFornec } from "../../../../cadastro/produtos/ligacao_prod_fornec/LigacaoProdFornec";

export const AlterarVolumeModal = ({
  selectedIdNfEntradaXmlItem,
  selectedUuid,
  selectedLigacaoEncontrada,
  selectedIdLigacaoProdFornec,
  selectedIdProduto,
  selectedUnidProdNf,
  selectedNomeProduto,
  selectedUnidMedProd,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idProduto, setIdProduto] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [codProdFornec, setCodProdFornec] = useState("");
  const [eanProdFornec, setEanProdFornec] = useState("");
  const [considerarEan, setConsiderarEan] = useState(false);
  const [fatorConv, setFatorConv] = useState(1);
  const [arredondaResult, setArredondaResult] = useState(false);
  const [conversaoEmM3, setConversaoEmM3] = useState(false);

  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdProduto(null);
    setIdFornecedor(null);
    setCodProdFornec("");
    setEanProdFornec("");
    setConsiderarEan(false);
    setFatorConv(1);
    setArredondaResult(false);
    setConversaoEmM3(false);

    setNomeFornecedor("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await apiGetV2(
      `${routesBaseLigacaoProdFornec}/buscar/${selectedIdLigacaoProdFornec}/`
    );

    if (ok) {
      setIdProduto(ret.id_produto);
      setIdFornecedor(ret.id_fornecedor);
      setNomeFornecedor(ret.nome_fornecedor);
      setCodProdFornec(ret.cod_prod_for);
      setEanProdFornec(ret.cod_barras);
      setConsiderarEan(ret.considera_ean);
      setFatorConv(parseFloat(ret.fator_conv));
      setArredondaResult(ret.arredonda_result);
      setConversaoEmM3(ret.conversao_em_m3);
    } else {
      toggle();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        id_produto: idProduto,
        id_fornecedor: idFornecedor,
        cod_prod_fornec: codProdFornec,
        ean_prod_fornec: eanProdFornec,
        considerar_ean: considerarEan,
        fator_conv: fatorConv,
        arredonda_result: arredondaResult,
        conversao_em_m3: conversaoEmM3,
      };

      const [ok] = await apiPutV2(`${routesBaseLigacaoProdFornec}/alterar/`, {
        id_ligacao: selectedIdLigacaoProdFornec,
        ...payload,
      });

      if (ok) {
        if (![0, null, undefined].includes(selectedIdNfEntradaXmlItem)) {
          await EntradaNfXmlService.recalcularValoresItem(
            selectedIdNfEntradaXmlItem,
            false
          );
        }
        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  const selecionouProd = ![0, null, undefined].includes(selectedUuid);

  return (
    <>
      <FormButton
        divClassName="no-gutters"
        color="indigo"
        onClick={toggle}
        disabled={!selecionouProd || !selectedLigacaoEncontrada}
        disabledHint={
          !selecionouProd
            ? "Selecione um item da lista"
            : "Selecione um produto que já tenha sido identificado"
        }
      >
        Alterar Volume
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Volume de Entrada"
        number="0101_12"
        onConfirm={handleSubmit}
        cancelButtonText="Cancelar"
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <FixedField
            label="Produto"
            value={formatValueFromAPI(selectedNomeProduto, selectedIdProduto)}
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            divClassName="pr-0"
            label="Fornecedor"
            value={formatValueFromAPI(nomeFornecedor, idFornecedor)}
          />
        </Row>
        <Row>
          <FixedField
            label="Unidade do Cadastro de Produto"
            value={selectedUnidMedProd}
          />
          <FixedField
            label="Unidade Presente na Nota Fiscal"
            value={selectedUnidProdNf}
          />
        </Row>
        <hr className="my-2" />
        <Row>
          <Label md="auto" style={{ fontSize: "0.9rem" }}>
            Para cada {selectedUnidProdNf} de {selectedNomeProduto} adicionar
          </Label>
        </Row>
        <Row>
          <NumberInput
            md={4}
            style={{ textAlign: "center" }}
            decimalPlaces={6}
            value={fatorConv}
            onChange={setFatorConv}
          />
          <Label style={{ fontSize: "0.9rem" }}>
            {selectedUnidMedProd} ao estoque do sistema
          </Label>
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Arredondar Resultado para Inteiro"
            checked={arredondaResult}
            onChange={() => setArredondaResult(!arredondaResult)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
