import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
} from "../../../../../components";
import { PageContainer } from "../../../../../components/PageContainer";
import { useStateWithRef } from "../../../../../coreUtils";
import RomaneioService from "../../../../../services/pedidos/RomaneioService";
import { RomaneioGrid } from "./components/RomaneioGrid";

export const CentralRomaneio = () => {
  const [vendedor, setVendedor] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idPedido, setIdPedido] = useState(null);
  const [idVenda, setIdVenda] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected, selectedRef] = useStateWithRef([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingConfirmar, setLoadingConfirmar] = useState(false);

  useEffect(() => {
    setDados([]);
  }, [vendedor, cliente, dataIni, dataFim, idPedido, idVenda]);

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    setDados(
      await RomaneioService.listarPendentes(
        vendedor,
        cliente,
        dataIni,
        dataFim,
        idVenda,
        idPedido
      )
    );
    setLoadingPesquisar(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSelect = (v, row, isSelected) => {
    const sel = selectedRef.current;
    setSelected(isSelected ? [...sel, v] : sel.filter((e) => e !== v));
  };

  const confirmar = async () => {
    setLoadingConfirmar(true);

    const ret = await RomaneioService.confirmarRomaneio({
      pendencias_confirmar: dados
        .filter((e) => selected.includes(e.id))
        .map((e) => ({
          id: e.id,
          origem: e.origem,
          id_origem: e.id_origem,
          id_transportadora: e.id_transportadora,
        })),
    });

    if (ret?.id_romaneio_realizado) {
      toastr.success("Sucesso", "Romaneio realizado com sucesso.");
      await RomaneioService.imprimirCarga(ret.id_romaneio_realizado);
      setSelected([]);
      carregarDados();
    }

    setLoadingConfirmar(false);
  };

  return (
    <PageContainer title="Central de Romaneios" number="0048" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            label="Vendedor"
            onChange={(s) => setVendedor(s?.value)}
            isSearchable
            isClearable
          />
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            onChange={(s) => setCliente(s?.value)}
            isSearchable
            isClearable
          />
          <IntegerFormInput
            label="Nro. Venda"
            md={2}
            onChange={(v) => setIdVenda(v)}
          />
          <IntegerFormInput
            label="Nro. Pedido"
            md={2}
            onChange={(v) => setIdPedido(v)}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas label="Emissão" onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <FormButton
            md="auto"
            color="success"
            disabled={selected?.length === 0}
            disabledHint="Selecione ao menos um item da lista"
            onClick={confirmar}
            loading={loadingConfirmar}
          >
            Confirmar Romaneio
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <RomaneioGrid
          data={dados}
          selected={selected}
          handleSelectAll={handleSelectAll}
          handleSelect={handleSelect}
        />
      </Card>
    </PageContainer>
  );
};
