import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/pedido_externo";
const URL_BASE_CENTRAL = `${URL_BASE}/central`;

const CentralPedidosService = {
  listar: (params) => apiGetV2(`${URL_BASE_CENTRAL}/listar_pedidos/`, params),
  listarItensEntrega: (idPedido) =>
    apiGetV2(`${URL_BASE_CENTRAL}/listar_itens_entrega/${idPedido}/`),
  entregar: (payload) => apiPostV2(`${URL_BASE_CENTRAL}/entregar/`, payload),
  liberar: (idPedido) =>
    apiPutV2(`${URL_BASE_CENTRAL}/liberacao_pedidos/liberar/`, {
      id_pedido: idPedido,
    }),
  buscarDadosLiberacao: (idPedido) =>
    apiGetV2(`${URL_BASE_CENTRAL}/liberacao_pedidos/dados/${idPedido}/`),
  buscarParametros: () => apiGetV2("/tela/central_pedidos/", {}),
  verificarConfiguracoesEntrega: () =>
    apiGetV2(
      `${URL_BASE_CENTRAL}/entrega_pedidos/verificar_configuracoes_uso/`
    ),
  buscar: (idPedido) => apiGetV2(`${URL_BASE}/buscar/${idPedido}/`),
  naoEntregarRestante: (payload) =>
    apiPutV2(`${URL_BASE}/nao_entregar_restante/`, payload),
  verifPodeAlterar: (id) => apiGetV2(`${URL_BASE}/verif_pode_alterar/${id}/`),
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscarDadosImpressao: (idPedido) =>
    apiGetV2(
      `${URL_BASE_CENTRAL}/buscar_dados_impressao/${idPedido}/`,
      undefined,
      { errorMessageType: "toastr" }
    ),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  cancelar: (payload) => apiPutV2(`${URL_BASE}/cancelar/`, payload),
  enviarEmail: (idPedido) => apiPostV2(`${URL_BASE}/enviar_email/${idPedido}/`),
};

export default CentralPedidosService;
