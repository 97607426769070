import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permite_manipular_vlr_unitario: false,
  id_layout_impressao: 1,
  obs_fixa: "",
};

export const pedidoExternoSlice = createSlice({
  name: "pedido_externo_slice",
  initialState: initialState,
  reducers: {
    setupPedidoExterno: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (key === "obs_fixa" && value === null) {
          value = "";
        }

        state[key] = value;
      });
      return state;
    },
    setPermiteManipularVlrUnitario: (state, action) => {
      state.permite_manipular_vlr_unitario = action.payload;
    },
    setIdLayoutImpressao: (state, action) => {
      state.id_layout_impressao = action.payload;
    },
    setObsFixa: (state, action) => {
      state.obs_fixa = action.payload;
    },
  },
});

export const {
  setupPedidoExterno,
  setPermiteManipularVlrUnitario,
  setIdLayoutImpressao,
  setObsFixa,
} = pedidoExternoSlice.actions;

export default pedidoExternoSlice.reducer;
