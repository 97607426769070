import React from "react";
import { DataTable } from "../../../../../../components/DataTable";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../../coreUtils";

const columns = [
  {
    dataField: "id_pedido_ext_cab",
    text: "Pedido",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_venda_cab",
    text: "Venda",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "data",
    text: "Data",
    align: "center",
    headerAlign: "center",
    sort: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "hora",
    text: "Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    sort: true,
  },
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_vendedor),
    sort: true,
  },
  {
    dataField: "valor_pend",
    text: "Valor Pendente",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sort: true,
    sortFunc: naturalSort,
  },
];

export const ConferenciaDinheiroGrid = ({
  data,
  selected,
  handleOnSelect,
  handleOnSelectAll,
}) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      isMulti
      selected={selected}
      onSelect={handleOnSelect}
      onSelectAll={handleOnSelectAll}
      paginated
    />
  );
};
