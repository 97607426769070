import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../coreUtils";

export const LigacaoProdFornecGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
      sortable: true,
    },
    {
      dataField: "nome_fornecedor",
      text: "Fornecedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
      sortable: true,
    },
    {
      dataField: "cod_prod_for",
      text: "Cód. Fornecedor",
      align: "center",
      sortable: true,
    },
    {
      dataField: "considera_ean",
      text: "Considera Cód. Barras Fornec.",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "cod_barras",
      text: "Cód. Barras Fornec.",
      align: "center",
    },
    {
      dataField: "fator_conv",
      text: "Fator Conversão",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
