import React, { useState } from "react";
import { Label, Row } from "reactstrap";
import { BotaoImprimir, IntegerFormInput } from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";
import PedidoInternoService from "../../../../../../services/pedidos/PedidoInternoService";
import { showWarning } from "../../../../../../components/AlertaModal";

export const ImprimirPedInternoModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viasImprimir, setViasImprimir] = useState(1);

  const imprimirPedidos = async () => {
    setLoading(true);
    const ok = await PedidoInternoService.central.imprimir(
      selected,
      viasImprimir
    );
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = () => {
    if (viasImprimir < 1) {
      showWarning("É necessário imprimir ao menos uma via do(s) pedido(s)");
      return;
    }
    imprimirPedidos();
  };

  return (
    <>
      <BotaoImprimir
        divClassName="pr-0"
        onClick={toggle}
        loading={loading}
        disabled={selected?.length === 0}
        disabledHint="Selecione ao menos um pedido da lista"
        text="Imprimir Pedidos"
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        onOpened={() => setViasImprimir(1)}
        onClosed={() => setViasImprimir(1)}
        title="Imprimir Pedidos"
        number="0100_1"
        onConfirm={onConfirm}
        onKeyDown={(e) => e.key === "Enter" && onConfirm()}
      >
        <Row
          style={{ alignItems: "center", justifyContent: "center", margin: 0 }}
        >
          <Label>Imprimir</Label>
          <IntegerFormInput
            md={5}
            onChange={(v) => setViasImprimir(v)}
            defaultValue={viasImprimir}
          />
          <Label>via(s)</Label>
        </Row>
      </ModalBase>
    </>
  );
};
