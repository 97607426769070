import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { ModalDetalhesIcone, Table } from "../../../../../components";

export const VendasGeralGrid = ({ dados, mostrarDetalhes }) => {
  const columns = [
    {
      dataField: "id",
      text: "Nº Venda",
      align: "center",
    },
    {
      dataField: "n_cli",
      text: "Nome do Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cli),
    },
    {
      dataField: "n_f_cli",
      text: "Nome Fantasia",
      align: "left",
    },
    {
      dataField: "n_vend",
      text: "Vendedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_vend),
    },
    {
      dataField: "d_emi",
      text: "Data / Hora",
      align: "center",
      formatter: (c, row) => `${c} ${row.h_emi}`,
    },
    {
      dataField: "tp_vda",
      text: "Origem",
      align: "center",
    },
    {
      dataField: "id_ped",
      text: "Nº Pedido",
      align: "center",
    },
    {
      dataField: "v_bruto",
      text: "Vlr. Bruto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "v_desc",
      text: "Vlr. Desconto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "v_flex",
      text: "Vlr. Flex",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "v_frete",
      text: "Frete",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_prod",
      text: "Produtos",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_serv",
      text: "Serviços",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "v_tot",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      dummy: true,
      formatter: (c, row) => (
        <ModalDetalhesIcone
          toggle={() => mostrarDetalhes(row.tp_vda, row.id)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      sizePerPage={15}
      showRegisterCount={false}
    />
  );
};
