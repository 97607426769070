import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroLigacaoProdFornecModal } from "./components/CadastroLigacaoProdFornecModal";
import { LigacaoProdFornecGrid } from "./components/LigacaoProdFornecGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
  TextInput,
} from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../coreUtils";

export const modalTitleLigacaoProdFornec = "Ligação Produto-Fornecedor";
export const routesBaseLigacaoProdFornec = "/cadastro/ligacao_prod_fornec";

export const LigacaoProdFornec = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [codProdFor, setCodProdFor] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await apiGetV2(`${routesBaseLigacaoProdFornec}/listar/`, {
      id_produto: idProduto,
      id_fornecedor: idFornecedor,
      cod_prod_for: codProdFor,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Ligação Produto-Fornecedor"
      number="0137"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Produto"
            concatModelName="produto"
            defaultValue={idProduto}
            onChange={(s) => setIdProduto(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Fornecedor"
            concatModelName="fornecedor"
            defaultValue={idFornecedor}
            onChange={(s) => setIdFornecedor(s?.value)}
            hideShortcut
          />
          <TextInput
            md={2}
            label="Cód. Prod. Fornec."
            value={codProdFor}
            onChange={(e, v) => setCodProdFor(v)}
            inputStyle={{ textAlign: "center" }}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroLigacaoProdFornecModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleLigacaoProdFornec}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0137_2"
            selected={selected}
            routeBase={routesBaseLigacaoProdFornec}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <LigacaoProdFornecGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
