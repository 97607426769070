import React from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../../components";

import { AiOutlineDollarCircle, AiOutlineLineChart } from "react-icons/ai";
import { BsDoorOpen, BsListUl, BsPercent } from "react-icons/bs";
import {
  cadastroAmbienteRoute,
  cadastroClienteExcecaoInadBoletoRoute,
  cadastroDescontoPorQuantidadeRoute,
  cadastroFormasRecClienteRoute,
  cadastroGerencSupervisorCabRoute,
  cadastroListaPrecosRoute,
  cadastroMetaVendaRoute,
  cadastroPremiadoRoute,
  cadastroRegraPromocaoRoute,
  cadastroRegrasComissaoOsRoute,
  cadastroRegrasComissaoRoute,
  cadastroRegrasFlexColaboradorRoute,
  cadastroTerminalRoute,
} from "../../../routes/modules/cadastro";
import { TbCurrencyDollarOff, TbDiscount } from "react-icons/tb";
import { IoMedalOutline } from "react-icons/io5";
import { PiDevices } from "react-icons/pi";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { LiaMoneyBillWaveSolid, LiaToolboxSolid } from "react-icons/lia";
import { HiUserGroup } from "react-icons/hi";

export const CadVendas = () => {
  return (
    <PageContainer title="Cadastros - Vendas">
      <MenuGroup>
        <MenuGroupButton
          label="Meta de Venda"
          icon={AiOutlineLineChart}
          pathname={cadastroMetaVendaRoute.path}
          nroTela={cadastroMetaVendaRoute.nroTela}
        />
        <MenuGroupButton
          label="Desconto por Quantidade"
          icon={BsPercent}
          pathname={cadastroDescontoPorQuantidadeRoute.path}
          nroTela={cadastroDescontoPorQuantidadeRoute.nroTela}
        />
        <MenuGroupButton
          label="Lista de Preços"
          icon={BsListUl}
          pathname={cadastroListaPrecosRoute.path}
          nroTela={cadastroListaPrecosRoute.nroTela}
        />
        <MenuGroupButton
          label="Regra de Comissão"
          icon={AiOutlineDollarCircle}
          pathname={cadastroRegrasComissaoRoute.path}
          nroTela={cadastroRegrasComissaoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Frente de Venda</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Ambiente"
          icon={BsDoorOpen}
          pathname={cadastroAmbienteRoute.path}
          nroTela={cadastroAmbienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Premiado"
          icon={IoMedalOutline}
          pathname={cadastroPremiadoRoute.path}
          nroTela={cadastroPremiadoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">PDV</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Terminal"
          icon={PiDevices}
          pathname={cadastroTerminalRoute.path}
          nroTela={cadastroTerminalRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">APP Pedido Externo</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Regra de Promoção"
          icon={TbDiscount}
          pathname={cadastroRegraPromocaoRoute.path}
          nroTela={cadastroRegraPromocaoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Força de Vendas Externas</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Dias Exceção Inadimplência"
          icon={TbCurrencyDollarOff}
          pathname={cadastroClienteExcecaoInadBoletoRoute.path}
          nroTela={cadastroClienteExcecaoInadBoletoRoute.nroTela}
        />
        <MenuGroupButton
          label="Formas de Recebimento"
          icon={LiaMoneyBillWaveSolid}
          pathname={cadastroFormasRecClienteRoute.path}
          nroTela={cadastroFormasRecClienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Regras de Flex"
          icon={FaMoneyCheckDollar}
          pathname={cadastroRegrasFlexColaboradorRoute.path}
          nroTela={cadastroRegrasFlexColaboradorRoute.nroTela}
        />
        <MenuGroupButton
          label="Supervisor"
          icon={HiUserGroup}
          pathname={cadastroGerencSupervisorCabRoute.path}
          nroTela={cadastroGerencSupervisorCabRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">O.S.</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Regras de Comissão de OS"
          icon={LiaToolboxSolid}
          pathname={cadastroRegrasComissaoOsRoute.path}
          nroTela={cadastroRegrasComissaoOsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
