import React from "react";
import { IconButton, Table, TableCheck } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { BsPrinter } from "react-icons/bs";

import ValePresenteService from "../../../../../services/vale_presente/ValePresenteService";
import { dropdownAcoes } from "../../../../../components/cadastro";

export const GerenciamentoValePresenteGrid = ({ dados, excluir }) => {
  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "inclusao",
      text: "Incluído Em",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_caixa_loja",
      text: "Caixa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "operador_inc",
      text: "Operador",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "utilizado",
      text: "Utilizado",
      align: "center",
      headerAlign: "center",
      formatter: (c) => (c ? <TableCheck value={true} /> : ""),
    },
    {
      dataField: "desc_tipo_uso",
      text: "Venda/O.S. Utilizado",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (row.id_uso ? `${c} #${row.id_uso}` : ""),
    },
    {
      dataField: "utilizacao",
      text: "Utilizado Em",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "print",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) =>
        row.utilizado ? (
          <></>
        ) : (
          <IconButton
            icon={BsPrinter}
            onClick={() => ValePresenteService.imprimir(row.id)}
            hint="Imprimir Vale Presente"
            tooltipPlacement="left"
          />
        ),
    },
    dropdownAcoes({
      excluir: excluir,
    }),
  ];

  return <Table data={dados} columns={columns} />;
};
