import React from "react";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const RegraComissaoOsGrid = ({
  dados,
  alterar,
  excluir,
  setSelected,
}) => {
  const columns = [
    {
      dataField: "nome_tecnico",
      text: "Técnico",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_tecnico),
    },
    {
      dataField: "perc_comissao_padrao_prod",
      text: "% Comiss. Padrão Produtos",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "perc_comissao_padrao_serv",
      text: "% Comiss. Padrão Serviços",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
