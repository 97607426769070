import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
} from "../../../../../components";
import { PageContainer } from "../../../../../components/PageContainer";
import { useStateWithRef } from "../../../../../coreUtils";
import RomaneioService from "../../../../../services/pedidos/RomaneioService";
import { RomaneiosRealizadosGrid } from "./components/RomaneiosRealizadosGrid";
import { DetalheRomaneioRealizadoModal } from "./components/DetalheRomaneioRealizadoModal";

export const RomaneiosRealizados = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [selected, setSelected, selectedRef] = useStateWithRef([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimirFinanc, setLoadingImprimirFinanc] = useState(false);
  const [loadingImprimirCarga, setLoadingImprimirCarga] = useState(false);

  const [modalDetalheIsOpen, setModalDetalheIsOpen] = useState(false);
  const [idRomaneioDetalhe, setIdRomaneioDetalhe] = useState(null);

  const carregarDados = async () => {
    setLoading(true);
    setSelected([]);
    setDados(await RomaneioService.listarRealizados(dataIni, dataFim));
    setLoading(false);
  };

  const imprimirFinanc = async () => {
    setLoadingImprimirFinanc(true);

    try {
      for (let i = 0; i < selected.length; i++) {
        await RomaneioService.imprimirFinanceiro(selected[i]);
      }
    } finally {
      setLoadingImprimirFinanc(false);
    }
  };

  const imprimirCarga = async () => {
    setLoadingImprimirCarga(true);

    try {
      for (let i = 0; i < selected.length; i++) {
        await RomaneioService.imprimirCarga(selected[i]);
      }
    } finally {
      setLoadingImprimirCarga(false);
    }
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    setDados([]);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSelect = (v, isSelected) => {
    const sel = selectedRef.current;
    setSelected(isSelected ? [...sel, v] : sel.filter((e) => e !== v));
  };

  const toggleModalDetalhe = () => setModalDetalheIsOpen(!modalDetalheIsOpen);

  const abrirModalDetalhe = (idRomaneioDetalhar) => {
    setIdRomaneioDetalhe(idRomaneioDetalhar);
    toggleModalDetalhe();
  };

  return (
    <PageContainer title="Romaneios Realizados" number="0071" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir
            onClick={imprimirFinanc}
            loading={loadingImprimirFinanc}
            disabled={selected?.length === 0}
            disabledHint="Selecione ao menos um item da lista"
            text="Imprimir Romaneio"
          />
          <BotaoImprimir
            onClick={imprimirCarga}
            loading={loadingImprimirCarga}
            disabled={selected?.length === 0}
            disabledHint="Selecione ao menos um item da lista"
            text="Imprimir Carga"
          />
        </Row>
      </Card>
      <Card body>
        <RomaneiosRealizadosGrid
          data={dados}
          selected={selected}
          handleSelectAll={handleSelectAll}
          handleSelect={handleSelect}
          toggleDetalhe={abrirModalDetalhe}
        />
      </Card>
      <DetalheRomaneioRealizadoModal
        isOpen={modalDetalheIsOpen}
        toggle={toggleModalDetalhe}
        idRomaneio={idRomaneioDetalhe}
        notifyEvent={carregarDados}
      />
    </PageContainer>
  );
};
