import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AsyncComboBox,
  FixedField,
  NumberInput,
} from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import {
  formatNumber,
  roundFloat,
  roundNumber,
} from "../../../../../coreUtils";
import UteisService from "../../../../../services/uteis/UteisService";
import { Badge, Row } from "reactstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showWarning } from "../../../../../components/AlertaModal";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";
import { PRECO_ATACADO } from "../../../../../flags";

const initialProdutoState = {
  idProduto: null,
  descricao: "",
  referencia: "",
  unidade: "",
  quantidade: 1,
  idAmbiente: 0,
  cfop: "",
  estoqueProd: 0,
  tamanhoProd: null,
  corProd: null,
  listaPreco: false,
  precoVenda: 0,
  precoAtacado: 0,
  vlrUnit: 0,
  vlrItem: 0,
  percDesc: 0,
  percComis: 0,
  vlrTotComis: 0,
  vlrTotal: 0,
  vlrDesc: 0,
  vlrAcre: 0,
  vlrTotProd: 0,
  vlrIcmsStUnit: 0,
  vlrIcmsSt: 0,
  percIpi: 0,
  vlrIpi: 0,
};

const RECALCULAR_POR_QUANTIDADE = "QUANTIDADE";
const RECALCULAR_POR_PERC_DESC = "PERC_DESC";
const RECALCULAR_POR_VLR_ITEM = "VLR_ITEM";
const RECALCULAR_POR_VLR_TOT_PROD = "VLR_TOT_PROD";
const RECALCULAR_POR_PERC_COMIS = "PERC_COMIS";

export const IncluirProdutoForm = forwardRef(
  ({ incluirItem, semCliente, precoEmUso, utilizaPrecoAtacado }, ref) => {
    const stateCab = useSelector((state) => state.vendaCab);
    const params = useSelector((state) => state.paramsFrenteVenda);
    const simplesNacional = params.regime_tribut === "Simples Nacional";
    const utilizaAmbiente = params.utiliza_ambiente;
    // Dados do Produto
    const [dadosProduto, setDadosProduto] = useState(initialProdutoState);
    //Controle
    const quantidadeRef = useRef();
    const ambienteRef = useRef();
    const refPesqProd = useRef();
    const [executarInclusao, setExecutarInclusao] = useState(false);
    const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
    const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(4);

    const semProduto = [0, null, undefined].includes(dadosProduto.idProduto);

    const handleChangeProduto = useCallback(
      (novosDados = {}) => {
        setDadosProduto({
          ...dadosProduto,
          ...novosDados,
        });
      },
      [dadosProduto]
    );

    const limparDadosProduto = () => {
      setDadosProduto(initialProdutoState);
      if (refPesqProd.current) {
        refPesqProd.current.clear();
      }
      if (ambienteRef.current) {
        ambienteRef.current.clearValue();
      }
    };

    const selectNextField = () => {
      setTimeout(() => {
        if (utilizaAmbiente) {
          if (ambienteRef.current) {
            ambienteRef.current.setFocus();
          }
        } else {
          if (quantidadeRef.current) {
            quantidadeRef.current.focus();
          }
        }
      }, 35);
    };

    const handleSelectProduto = async ({
      idProduto: id,
      nomeProduto: nome,
      referencia,
      precoVenda,
      unidade,
      cfop,
      quantidade,
      casasDecimaisQtd,
      casasDecimaisVlrs,
      tamanho,
      cor,
      precoAtacado,
    }) => {
      if (dadosProduto.idProduto !== id) {
        let listaPreco = false;
        if (["", 0, null, undefined].includes(id)) {
          limparDadosProduto();
          return;
        } else {
          if (![0, null, undefined].includes(stateCab.id_cliente)) {
            const [ok, ret] = await FrenteVendaService.buscarPrecoListaPreco(
              id,
              stateCab.id_cliente
            );
            if (ok && ret?.preco_venda) {
              precoVenda = parseFloat(ret.preco_venda);
              listaPreco = true;
            }
          }
        }

        setQtdCasasDecimaisQtd(casasDecimaisQtd);
        setQtdCasasDecimaisVlrs(casasDecimaisVlrs);

        const novosDadosProd = {
          idProduto: id,
          descricao: nome,
          quantidade: 1,
          unidade: unidade,
          referencia: referencia,
          estoqueProd: quantidade,
          percDesc: 0,
          vlrDesc: 0,
          vlrAcre: 0,
          cfop: cfop,
          listaPreco: listaPreco,
          tamanhoProd: tamanho,
          corProd: cor,
          precoVenda: precoVenda,
          precoAtacado: precoAtacado,
        };

        if (precoEmUso === PRECO_ATACADO) {
          novosDadosProd.vlrUnit = precoAtacado;
          novosDadosProd.vlrItem = precoAtacado;
          novosDadosProd.vlrTotProd = precoAtacado;
          novosDadosProd.vlrTotal = precoAtacado;
        } else {
          novosDadosProd.vlrUnit = precoVenda;
          novosDadosProd.vlrItem = precoVenda;
          novosDadosProd.vlrTotProd = precoVenda;
          novosDadosProd.vlrTotal = precoVenda;
        }

        handleChangeProduto(novosDadosProd);

        selectNextField();
      }
    };

    const calcularImpostos = async (idProduto, quantidade, vlrItem, cfop) => {
      const _vlrTotProd = vlrItem * quantidade;

      const novosDadosProd = {
        vlrTotComis: 0,
      };

      if (_vlrTotProd > 0) {
        const vlrTotComisCalc = roundFloat(
          _vlrTotProd * (dadosProduto.percComis / 100),
          2
        );

        novosDadosProd.vlrTotComis = vlrTotComisCalc;
      }

      let ok = false;

      // Se o regime da empresa for Simples Nacional, não calcula impostos
      if (simplesNacional) {
        novosDadosProd.vlrTotProd = roundNumber(_vlrTotProd);
        novosDadosProd.vlrTotal = roundNumber(_vlrTotProd);

        ok = true;
      } else {
        const trib = await UteisService.calcularImpostosProduto(
          idProduto,
          stateCab.id_cliente ?? null,
          "VENDA",
          55,
          true,
          cfop,
          quantidade,
          _vlrTotProd - stateCab.vlr_frete,
          stateCab.vlr_frete
        );

        if (trib && Object.keys(trib).length > 0) {
          const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
          const _vlrIpi = parseFloat(trib.vlr_ipi);
          const _percIpi = parseFloat(trib.perc_ipi);
          const _vlrTotal = parseFloat(trib.vlr_total_com_impostos);

          novosDadosProd.vlrIcmsStUnit =
            quantidade > 0 ? roundFloat(_vlrIcmsSt / quantidade, 2) : 0;
          novosDadosProd.vlrIcmsSt = roundFloat(_vlrIcmsSt, 2);
          novosDadosProd.percIpi = roundFloat(_percIpi, 2);
          novosDadosProd.vlrIpi = roundFloat(_vlrIpi, 2);
          novosDadosProd.vlrTotProd = roundFloat(_vlrTotProd, 2);
          novosDadosProd.vlrTotal = roundFloat(_vlrTotal, 2);

          ok = true;
        }
      }

      return [ok, novosDadosProd];
    };

    const recalcularValoresItem = async (novosValores, recalcularPor) => {
      const quantidade = novosValores.quantidade ?? dadosProduto.quantidade;
      const vlrUnit = novosValores.vlrUnit ?? dadosProduto.vlrUnit;
      const vlrItem = novosValores.vlrItem ?? dadosProduto.vlrItem;
      const percDesc = novosValores.percDesc ?? dadosProduto.percDesc;
      const percComis = novosValores.percComis ?? dadosProduto.percComis;
      const vlrTotProd = novosValores.vlrTotProd ?? dadosProduto.vlrTotProd;

      let valorIgual = [
        recalcularPor === RECALCULAR_POR_QUANTIDADE &&
          quantidade === dadosProduto.quantidade,
        recalcularPor === RECALCULAR_POR_VLR_ITEM &&
          vlrItem === dadosProduto.vlrItem,
        recalcularPor === RECALCULAR_POR_PERC_DESC &&
          percDesc === dadosProduto.percDesc,
        recalcularPor === RECALCULAR_POR_PERC_COMIS &&
          percComis === dadosProduto.percComis,
        recalcularPor === RECALCULAR_POR_VLR_TOT_PROD &&
          vlrTotProd === dadosProduto.vlrTotProd,
      ].some((e) => e);

      if (valorIgual) {
        return;
      }

      let novosDadosProd = { vlrUnit: vlrUnit };

      if (recalcularPor === RECALCULAR_POR_QUANTIDADE) {
        novosDadosProd.quantidade = quantidade;

        if (vlrItem <= vlrUnit) {
          novosDadosProd.vlrDesc = roundFloat(
            vlrUnit * (percDesc / 100) * quantidade,
            2
          );
          novosDadosProd.vlrAcre = 0;
        } else {
          novosDadosProd.percDesc = 0;
          novosDadosProd.vlrDesc = 0;
          novosDadosProd.vlrAcre = roundFloat(
            (vlrItem - vlrUnit) * quantidade,
            2
          );
        }
      } else if (recalcularPor === RECALCULAR_POR_VLR_ITEM) {
        novosDadosProd.vlrItem = vlrItem;

        if (vlrItem <= vlrUnit) {
          const vDesc = vlrUnit - vlrItem;
          novosDadosProd.percDesc =
            vlrUnit > 0 ? roundFloat((vDesc / vlrUnit) * 100, 2) : 0;
          novosDadosProd.vlrDesc = roundFloat(vDesc * quantidade, 2);
          novosDadosProd.vlrAcre = 0;
        } else {
          novosDadosProd.percDesc = 0;
          novosDadosProd.vlrDesc = 0;
          novosDadosProd.vlrAcre = roundFloat(
            (vlrItem - vlrUnit) * quantidade,
            2
          );
        }
      } else if (recalcularPor === RECALCULAR_POR_PERC_DESC) {
        if (vlrUnit > 0) {
          const vDesc = (percDesc / 100) * vlrUnit;
          const vItem = roundFloat(
            ((100 - percDesc) / 100) * vlrUnit,
            qtdCasasDecimaisVlrs
          );

          novosDadosProd.percDesc = percDesc;
          novosDadosProd.vlrItem = roundFloat(vItem, qtdCasasDecimaisVlrs);
          novosDadosProd.vlrDesc = roundFloat(vDesc * quantidade, 2);
          novosDadosProd.vlrAcre = 0;
        } else {
          novosDadosProd.percDesc = 0;
          novosDadosProd.vlrDesc = 0;
          novosDadosProd.vlrAcre = vlrItem;
        }
      } else if (recalcularPor === RECALCULAR_POR_PERC_COMIS) {
        novosDadosProd.percComis = percComis;
        novosDadosProd.vlrTotComis = 0;

        if (vlrTotProd > 0) {
          novosDadosProd.vlrTotComis = roundFloat(
            vlrTotProd * (percComis / 100),
            2
          );
        }
      } else if (recalcularPor === RECALCULAR_POR_VLR_TOT_PROD) {
        const vItem = quantidade > 0 ? vlrTotProd / quantidade : 0;

        novosDadosProd.vlrTotProd = vlrTotProd;
        novosDadosProd.vlrItem = roundFloat(vItem, qtdCasasDecimaisVlrs);

        const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);
        const vDesc = vlrTotBruto > 0 ? vlrTotBruto - vlrTotProd : 0;
        const pDesc =
          vlrTotBruto > 0 ? roundFloat((vDesc / vlrTotBruto) * 100, 2) : 0;

        if (vlrTotProd <= vlrTotBruto) {
          novosDadosProd.percDesc = roundFloat(pDesc, 2);
          novosDadosProd.vlrDesc = roundFloat(vDesc, 2);
          novosDadosProd.vlrAcre = 0;
        } else {
          novosDadosProd.percDesc = 0;
          novosDadosProd.vlrDesc = 0;
          novosDadosProd.vlrAcre = roundFloat(vlrTotProd - vlrTotBruto, 2);
        }
      }

      const [ok, ret] = await calcularImpostos(
        dadosProduto.idProduto,
        novosDadosProd.quantidade ?? quantidade,
        novosDadosProd.vlrItem ?? vlrItem,
        dadosProduto.cfop
      );

      if (!ok) return;

      novosDadosProd = { ...novosDadosProd, ...ret };

      handleChangeProduto(novosDadosProd);
    };

    const handleIncluirProd = async () => {
      if (
        utilizaAmbiente &&
        [0, null, undefined].includes(dadosProduto.idAmbiente)
      ) {
        showWarning("Por favor, informe o Ambiente");
        if (ambienteRef.current) {
          ambienteRef.current.setFocus();
        }
        return;
      }

      await recalcularValoresItem(
        { vlrTotProd: dadosProduto.vlrTotProd },
        RECALCULAR_POR_VLR_TOT_PROD
      );

      setExecutarInclusao(true);
    };

    const vlrTotProdKeyDown = (e) => {
      if (["Enter", "Tab"].includes(e.key) && !semProduto) {
        handleIncluirProd();
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      },
      limpar: () => {
        limparDadosProduto();
      },
    }));

    const carregarDadosProduto = () => {
      if (refPesqProd.current && dadosProduto.idProduto) {
        refPesqProd.current.setId(String(dadosProduto.idProduto));
        refPesqProd.current.setDescricao(dadosProduto.descricao);
        refPesqProd.current.setReferencia(String(dadosProduto.referencia));
      }
    };

    useEffect(carregarDadosProduto, [
      refPesqProd.current,
      dadosProduto.idProduto,
    ]);

    const inclusaoItem = async () => {
      try {
        const payload = {
          tipo: "P",
          id_prod_serv: dadosProduto.idProduto,
          descricao: dadosProduto.descricao,
          unidade: dadosProduto.unidade,
          id_ambiente: dadosProduto.idAmbiente,
          quantidade: dadosProduto.quantidade,
          vlr_unit: dadosProduto.vlrUnit,
          vlr_item: dadosProduto.vlrItem,
          perc_desc: dadosProduto.percDesc,
          vlr_tot_prod_serv: dadosProduto.vlrTotProd,
          vlr_total: dadosProduto.vlrTotal,
          vlr_desc: dadosProduto.vlrDesc,
          vlr_acre: dadosProduto.vlrAcre,
          perc_ipi: dadosProduto.percIpi,
          vlr_ipi: dadosProduto.vlrIpi,
          vlr_icms_st: dadosProduto.vlrIcmsSt,
          vlr_icms_st_unit: dadosProduto.vlrIcmsStUnit,
          perc_comis: dadosProduto.percComis ?? 0,
          vlr_tot_comis: dadosProduto.vlrTotComis ?? 0,
          observ: "",
        };

        if (await incluirItem(payload)) {
          limparDadosProduto();
          if (refPesqProd.current) {
            refPesqProd.current.focus();
          }
        }
      } finally {
        setExecutarInclusao(false);
      }
    };

    const verificaIncluiItem = () => {
      if (executarInclusao) {
        inclusaoItem();
      }
    };

    useEffect(verificaIncluiItem, [executarInclusao]);

    useEffect(() => {
      if (!semCliente) {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      }
    }, [semCliente]);

    const trocarPrecoEmUso = () => {
      const novosDadosProd = {};
      if (precoEmUso === PRECO_ATACADO) {
        novosDadosProd.vlrUnit = dadosProduto.precoAtacado;
        novosDadosProd.vlrItem = dadosProduto.precoAtacado;
      } else {
        novosDadosProd.vlrUnit = dadosProduto.precoVenda;
        novosDadosProd.vlrItem = dadosProduto.precoVenda;
      }

      handleChangeProduto(novosDadosProd);

      if (novosDadosProd.vlrItem) {
        recalcularValoresItem(novosDadosProd, RECALCULAR_POR_VLR_ITEM);
      }
    };

    useEffect(trocarPrecoEmUso, [precoEmUso]);

    return (
      <>
        <Row>
          <PesqProduto
            md={8}
            onConfirm={handleSelectProduto}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={(v) => handleChangeProduto({ descricao: v })}
            mdIdent={3}
            mdDesc={7}
            mdIdentAux={2}
            disabled={!simplesNacional && semCliente}
            auxAsLabel
            mostrarAux={
              !params.utiliza_cor_prod && !params.utiliza_tamanho_prod
            }
            idCliente={stateCab.id_cliente}
          />
          {(params.utiliza_cor_prod || params.utiliza_tamanho_prod) && (
            <>
              {params.utiliza_cor_prod && (
                <FixedField
                  divClassName="pt-4"
                  label="Tamanho"
                  md="auto"
                  value={dadosProduto.tamanhoProd}
                  horizontal
                />
              )}
              {params.utiliza_tamanho_prod && (
                <FixedField
                  divClassName="pt-4"
                  label="Cor"
                  md="auto"
                  value={dadosProduto.corProd}
                  horizontal
                />
              )}
            </>
          )}
          <FixedField
            divClassName="pt-4"
            md={"auto"}
            label="Estoque"
            value={formatNumber(dadosProduto.estoqueProd, true, 2, true)}
            horizontal
          />
          <FixedField
            divClassName="pt-4"
            md={2}
            label="Vlr. Unitário"
            value={formatNumber(dadosProduto.vlrUnit, true, 2)}
            horizontal
          />
        </Row>
        {utilizaAmbiente && (
          <Row>
            <AsyncComboBox
              md={4}
              isConcatField
              concatModelName="ambiente"
              label="Ambiente"
              isSearchable
              onChange={(s) =>
                handleChangeProduto({ idAmbiente: s?.value ?? 0 })
              }
              defaultValue={dadosProduto.idAmbiente}
              defaultOptions
              ref={ambienteRef}
              disabled={semProduto}
            />
          </Row>
        )}
        <Row>
          <NumberInput
            md={2}
            label="Quantidade"
            value={dadosProduto.quantidade}
            onChange={(v) =>
              recalcularValoresItem(
                { quantidade: v },
                RECALCULAR_POR_QUANTIDADE
              )
            }
            disabled={semProduto}
            decimalPlaces={qtdCasasDecimaisQtd}
            ref={quantidadeRef}
          />
          <NumberInput
            md={2}
            label={
              <>
                Vlr. Item{" "}
                {dadosProduto.listaPreco && (
                  <Badge
                    pill
                    color="info"
                    style={{ transform: "translateY(-1px)" }}
                  >
                    Preço Tabelado
                  </Badge>
                )}
                {utilizaPrecoAtacado && (
                  <Badge
                    pill
                    color={precoEmUso === PRECO_ATACADO ? "indigo" : "warning"}
                    style={{ transform: "translateY(-1px)" }}
                  >
                    {precoEmUso === PRECO_ATACADO ? "Atacado" : "Varejo"}
                  </Badge>
                )}
              </>
            }
            value={dadosProduto.vlrItem}
            onChange={(v) =>
              recalcularValoresItem({ vlrItem: v }, RECALCULAR_POR_VLR_ITEM)
            }
            disabled={semProduto || dadosProduto.listaPreco}
            decimalPlaces={qtdCasasDecimaisVlrs}
          />
          <NumberInput
            md={1}
            label="% Desc."
            value={dadosProduto.percDesc}
            onChange={(v) =>
              recalcularValoresItem({ percDesc: v }, RECALCULAR_POR_PERC_DESC)
            }
            disabled={semProduto}
            isPercentage
          />
          {params.informar_perc_comis_frente_venda === true && (
            <NumberInput
              md={1}
              label="% Comis."
              value={dadosProduto.percComis}
              onChange={(v) =>
                recalcularValoresItem(
                  { percComis: v },
                  RECALCULAR_POR_PERC_COMIS
                )
              }
              disabled={semProduto}
              isPercentage
            />
          )}
          <NumberInput
            md={2}
            label="Vlr. Tot. Prod."
            value={dadosProduto.vlrTotProd}
            onChange={(v) =>
              recalcularValoresItem(
                { vlrTotProd: v },
                RECALCULAR_POR_VLR_TOT_PROD
              )
            }
            disabled={semProduto}
            decimalPlaces={2}
            onKeyDown={vlrTotProdKeyDown}
          />
          <NumberInput
            md={2}
            label="Vlr. Total"
            value={dadosProduto.vlrTotal}
            disabled
          />
        </Row>
      </>
    );
  }
);
