import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  FixedField,
  FormCheckbox,
  LabelButton,
  ModalBase,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { RadioItem } from "../../../../../components/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";
import {
  setBairroEntrega,
  setComplementoEntrega,
  setDataEntrega,
  setEnderecoEntrega,
  setFormaPagto,
  setIdCidadeEntrega,
  setIdCondPag,
  setNomeCidadeEntrega,
  setNomeLocalEntrega,
  setNroEnderecoEntrega,
  setObserv,
} from "../store/vendaCabSlice";
import { ModoImprOrcamentoModal } from "./ModoImprOrcamentoModal";
import { formatDateISO, formatNumber } from "../../../../../coreUtils";
import { ConfirmSemPremiadoModal } from "./ConfirmSemPremiadoModal";
import PedidoInternoService from "../../../../../services/pedidos/PedidoInternoService";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { BoletoService } from "../../../../../services/bancario/BoletoService";
import { showWarning } from "../../../../../components/AlertaModal";
import moment from "moment";
import DadosOrdemCompraModal from "./DadosOrdemCompraModal";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";

let formasPag = [
  { label: "Contas a Receber COM Boleto Bancário", value: "BB" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Cheque", value: "CQ" },
  { label: "Contas a Receber SEM Boleto - Crediário", value: "CI" },
  { label: "Depósito Bancário", value: "DB" },
  { label: "Dinheiro", value: "DI" },
  { label: "PIX", value: "PX" },
  { label: "Transf. Bancária - TED/DOC", value: "TB" },
  { label: "Sem Pagamento", value: "SE" },
];

export const ConfirmarVendaModal = ({ isOpen, toggle, notifyFinalizar }) => {
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const stateCab = useSelector((state) => state.vendaCab);
  const params = useSelector((state) => state.paramsFrenteVenda);
  const dispatch = useDispatch();
  const [tipoFinaliza, setTipoFinaliza] = useState("VFX");
  const [idVendedor, setIdVendedor] = useState(idUsuario);
  const [idPremiado, setIdPremiado] = useState(null);
  const [faturarVenda, setFaturarVenda] = useState(null);
  const [idContaBancBoleto, setIdContaBancBoleto] = useState(null);
  const [salvarLocalEntrega, setSalvarLocalEntrega] = useState(false);
  // Controle
  const [loading, setLoading] = useState(false);
  const [loadingBuscaEndCadCliente, setLoadingBuscaEndCadCliente] =
    useState(false);
  const [idRegistroResult, setIdRegistroResult] = useState(null);
  const [tipoRegistroResult, setTipoRegistroResult] = useState(null);
  const [modoImprOrcamentoOpen, setModoImprOrcamentoOpen] = useState(false);
  const [confirmSemPremiadoOpen, setConfirmSemPremiadoOpen] = useState(false);
  const [obrigInfoOrdemCompra, setObrigInfoOrdemCompra] = useState(false);

  useEffect(() => {
    setIdVendedor(idUsuario);
  }, [idUsuario]);

  const limparDados = () => {
    if (params.operacao_padrao === "O") {
      setTipoFinaliza("ORC");
    } else if (params.operacao_padrao === "V") {
      setTipoFinaliza("VFX");
    } else {
      setTipoFinaliza("PED");
    }
    setIdVendedor(idUsuario);
    setIdPremiado(null);
    setFaturarVenda(null);
    setIdContaBancBoleto(null);
    setSalvarLocalEntrega(false);
  };

  const onBeforeOpen = async () => {
    setIdRegistroResult(null);
    setTipoRegistroResult(null);
    setIdPremiado(stateCab.id_premiado ?? null);

    if (params.operacao_padrao === "V" || stateCab.venda_bonificada) {
      setTipoFinaliza("VFX");
    } else if (params.operacao_padrao === "O") {
      setTipoFinaliza("ORC");
    } else if (params.operacao_padrao === "P") {
      setTipoFinaliza("PED");
    }
  };

  const buscarEnderecoEntregaCliente = async () => {
    setLoadingBuscaEndCadCliente(true);
    try {
      if (stateCab.id_cliente) {
        const [ok, ret] = await FrenteVendaService.buscarLocalEntrega(
          stateCab.id_venda
        );
        if (ok) {
          dispatch(setEnderecoEntrega(ret.endereco));
          dispatch(setNroEnderecoEntrega(ret.numero));
          dispatch(setBairroEntrega(ret.bairro));
          dispatch(setComplementoEntrega(ret.complemento));
          dispatch(setIdCidadeEntrega(ret.id_cidade));
          dispatch(setNomeCidadeEntrega(ret.nome_cidade));
          dispatch(setNomeLocalEntrega("Endereço do cadastro do cliente"));
        }
      }
    } finally {
      setLoadingBuscaEndCadCliente(false);
    }
  };

  const toggleModoImprOrcamento = () =>
    setModoImprOrcamentoOpen(!modoImprOrcamentoOpen);

  const toggleConfirmSemPremiado = () =>
    setConfirmSemPremiadoOpen(!confirmSemPremiadoOpen);

  const handleSubmit = async () => {
    if (
      params?.permite_faturamento_frente_venda === true &&
      [null, undefined].includes(faturarVenda) &&
      tipoFinaliza === "VFX"
    ) {
      showWarning("Por favor, informe se você deseja faturar a venda ou não");
      return;
    }

    const payload = {
      id_cab: stateCab.id_venda,
      tipo_finaliza: tipoFinaliza,
      id_vendedor: idVendedor,
      id_premiado: idPremiado,
      observ: stateCab.observ,
      vda_bonificada: stateCab.venda_bonificada,
      forma_pag: stateCab.forma_pagto ?? null,
      id_cond_pag: stateCab.id_cond_pag ?? null,
      faturar_venda: faturarVenda ?? false,
      id_conta_banc_fat_bol: idContaBancBoleto ?? null,
      entrega: {
        endereco: stateCab.endereco_entrega ?? "",
        numero: stateCab.nro_endereco_entrega ?? "",
        bairro: stateCab.bairro_entrega ?? "",
        complemento: stateCab.complemento_entrega ?? "",
        id_cidade: stateCab.id_cidade_entrega ?? null,
        data_entrega: stateCab.data_entrega
          ? formatDateISO(stateCab.data_entrega)
          : null,
        salvar_endereco: salvarLocalEntrega,
        nome_local_entrega: stateCab.nome_local_entrega ?? "",
      },
    };
    setLoading(true);
    try {
      const [ok, ret] = await FrenteVendaService.finalizar(payload);
      if (ok) {
        const tipoRegistro = ret.tipo_registro;
        const idRegistro = ret.id_registro;
        const nfEmitida = ret.nf_emitida;
        const idNfe = ret.id_nfe;
        const imprimirBoletos = ret.imprimir_boletos;
        const titulos = ret.titulos;
        if (!nfEmitida) {
          switch (tipoRegistro) {
            case "VFX":
              await GerenciamentoVendasService.imprimir(idRegistro);
              break;
            case "ORC":
              if (ret.layout_impressao === 10) {
                setIdRegistroResult(idRegistro);
                setTipoRegistroResult(tipoRegistro);
                toggleModoImprOrcamento();
              } else {
                await OrcamentosService.imprimirFrenteVenda(idRegistro, true);
              }
              break;
            case "PED":
              await PedidoInternoService.central.imprimir([idRegistro], 1);
              break;
            default:
              break;
          }
        } else {
          await NotaFiscalService.imprimir(idNfe);
          if (imprimirBoletos) {
            titulos.forEach((dup) => {
              BoletoService.imprimirBoleto(dup.id_titulo);
            });
          }
        }
        notifyFinalizar();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  const verifySubmit = () => {
    if (
      params.avisa_identif_premiado &&
      [0, null, undefined].includes(idPremiado)
    ) {
      toggleConfirmSemPremiado();
      return;
    }

    handleSubmit();
  };

  useEffect(() => {
    if (stateCab.forma_pagto === "BB") {
      setIdContaBancBoleto(params?.id_conta_banc_fat_bol);
    }
  }, [stateCab.forma_pagto]);

  const buscarClienteObrigaOrdCompra = async () => {
    if (
      isOpen === true &&
      ![0, null, undefined].includes(stateCab.id_cliente)
    ) {
      const [ok, ret] = await CadastroClienteService.buscarResumo(
        stateCab.id_cliente
      );
      if (ok) {
        setObrigInfoOrdemCompra(ret.obrig_info_ordem_compra_nfe);
      }
    }
  };

  useEffect(() => {
    buscarClienteObrigaOrdCompra();
  }, [isOpen, stateCab.id_cliente]);

  const LinhaFormaCondPag = (
    <Row>
      <ComboBox
        md={6}
        label="Forma de Pagamento"
        options={formasPag}
        defaultValue={stateCab.forma_pagto}
        onChange={(s) => dispatch(setFormaPagto(s?.value))}
        isSearchable={false}
        isDisabled={!faturarVenda && !params?.identifica_forma_rec}
        isClearable={
          params?.permite_faturamento_frente_venda === false ||
          tipoFinaliza !== "VFX"
        }
        clearOnDisable
      />
      <AsyncComboBox
        label="Condição de Pagamento"
        md={6}
        isConcatField
        concatModelName="cond_pag"
        isSearchable
        onChange={(s) => dispatch(setIdCondPag(s?.value))}
        defaultValue={stateCab.id_cond_pag}
        disabled={
          (!faturarVenda || !["BB", "CI"].includes(stateCab.forma_pagto)) &&
          !params?.identifica_forma_rec
        }
        clearOnDisable
        defaultOptions
      />
    </Row>
  );

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title=""
        number="0030_1"
        onConfirm={verifySubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        onBeforeOpen={onBeforeOpen}
        paramsName="frente_venda"
        footerActions={
          tipoFinaliza === "VFX" &&
          obrigInfoOrdemCompra && <DadosOrdemCompraModal />
        }
        numberStyle={{
          marginRight:
            tipoFinaliza === "VFX" && obrigInfoOrdemCompra ? "0" : "auto",
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              fontSize: "1rem",
            }}
          >
            <RadioGroup
              label={null}
              value={tipoFinaliza}
              onChange={setTipoFinaliza}
              hint={
                stateCab.venda_bonificada === true
                  ? "Ao marcar a opção Venda Bonificada, o processo deve ser finalizado como venda"
                  : null
              }
              disabled={stateCab.venda_bonificada}
            >
              <RadioItem className="big-radio" label="Venda" value="VFX" />
              <RadioItem className="big-radio" label="Orçamento" value="ORC" />
              <RadioItem
                className="big-radio"
                label="Pedido Interno"
                value="PED"
              />
            </RadioGroup>
          </div>
        </Row>
        {params?.identifica_vendedor_final === true && (
          <Row>
            <AsyncComboBox
              md={5}
              isConcatField
              concatModelName="colaborador"
              defaultOptions
              label="Vendedor"
              isSearchable
              onChange={(s) => setIdVendedor(s?.value)}
              defaultValue={idVendedor}
            />
          </Row>
        )}
        {params?.identifica_forma_rec === true &&
          (params?.permite_faturamento_frente_venda === false ||
            tipoFinaliza !== "VFX") && <>{LinhaFormaCondPag}</>}
        {params?.identifica_premiado === true && (
          <Row>
            <AsyncComboBox
              md={5}
              isConcatField
              concatModelName="premiado"
              defaultOptions
              label="Premiado"
              isSearchable
              onChange={(s) => setIdPremiado(s?.value ?? null)}
              defaultValue={idPremiado}
            />
            <FixedField
              label="Pontuação"
              value={formatNumber(stateCab.pontos_premiacao, true, 2)}
              horizontal
              divClassName="pt-4"
            />
          </Row>
        )}
        <Row className="mb-1">
          <TextInput
            type="textarea"
            label="Observação"
            md={12}
            onChange={(e, v) => dispatch(setObserv(v))}
            value={stateCab.observ}
            rows={3}
            inputStyle={{ resize: "none" }}
            forceUppercase={false}
          />
        </Row>
        <Row className="mb-2">
          <LabelButton
            onClick={buscarEnderecoEntregaCliente}
            loading={loadingBuscaEndCadCliente}
          >
            Buscar endereço do cadastro do cliente
          </LabelButton>
          <LabelButton
            onClick={() => {}}
            loading={loadingBuscaEndCadCliente}
            divClassName="ml-auto d-none"
          >
            Selecionar endereços de entrega do cliente
          </LabelButton>
        </Row>
        <Row>
          <TextInput
            label="Endereço de Entrega"
            value={stateCab.endereco_entrega}
            onChange={(e, v) => dispatch(setEnderecoEntrega(v))}
            md={10}
            maxLength={60}
          />
          <TextInput
            label="Número"
            value={stateCab.nro_endereco_entrega}
            onChange={(e, v) => dispatch(setNroEnderecoEntrega(v))}
            md={2}
            maxLength={10}
          />
        </Row>
        <Row>
          <TextInput
            label="Bairro"
            value={stateCab.bairro_entrega}
            onChange={(e, v) => dispatch(setBairroEntrega(v))}
            md={5}
            maxLength={40}
          />
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="cidade"
            defaultOptions
            label="Cidade"
            isSearchable
            onChange={(s) => {
              dispatch(setIdCidadeEntrega(s?.value ?? null));
              dispatch(setNomeCidadeEntrega(s?.label ?? null));
            }}
            defaultValue={stateCab.id_cidade_entrega}
          />
          <DatePicker
            md={2}
            label="Data Entrega"
            value={stateCab.data_entrega}
            onChange={(v) =>
              dispatch(setDataEntrega(moment.isMoment(v) ? v.toDate() : v))
            }
          />
        </Row>
        <Row>
          <FormCheckbox
            label="Salvar local de entrega"
            checked={salvarLocalEntrega}
            onChange={() => setSalvarLocalEntrega(!salvarLocalEntrega)}
            className="pt-0"
          />
          <TextInput
            label="Nome Local de Entrega"
            value={stateCab.nome_local_entrega}
            onChange={(e, v) => dispatch(setNomeLocalEntrega(v))}
            md={5}
            disabled={!salvarLocalEntrega}
            maxLength={60}
          />
        </Row>
        {tipoFinaliza === "VFX" &&
          params?.permite_faturamento_frente_venda === true && (
            <>
              <Row>
                <RadioGroup
                  label="Faturar Venda"
                  value={faturarVenda}
                  onChange={(v) => setFaturarVenda(v === "true")}
                >
                  <RadioItem label="Sim, Gerar NFe agora" value={true} />
                  <RadioItem label="Não" value={false} />
                </RadioGroup>
              </Row>
              {LinhaFormaCondPag}
              <Row>
                <AsyncComboBox
                  md={6}
                  isConcatField
                  concatModelName="conta_banc"
                  label="Conta Bancária - Boletos"
                  isSearchable
                  onChange={(s) => setIdContaBancBoleto(s?.value)}
                  defaultValue={idContaBancBoleto}
                  defaultOptions
                  disabled={
                    !faturarVenda || !["BB"].includes(stateCab.forma_pagto)
                  }
                  clearOnDisable
                />
              </Row>
            </>
          )}
      </ModalBase>
      {tipoRegistroResult === "ORC" && (
        <ModoImprOrcamentoModal
          isOpen={modoImprOrcamentoOpen}
          toggle={toggleModoImprOrcamento}
          idOrcamento={idRegistroResult}
        />
      )}
      <ConfirmSemPremiadoModal
        isOpen={confirmSemPremiadoOpen}
        toggle={toggleConfirmSemPremiado}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
