import React from "react";
import { Table } from "../../../../../components";
import { formatarValor, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id_produto",
    text: "Produto",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_produto",
    text: "Nome",
    align: "left",
    fixedColWidth: true,
    colWidth: "400px",
    sortable: true,
  },
  {
    dataField: "unidade_produto",
    text: "Unidade",
    align: "center",
    sortable: true,
  },
  {
    dataField: "quantidade",
    text: "Est. Atual",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "est_min",
    text: "Est. Mínimo",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "diferenca",
    text: "Diferença",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ProdutosAbaixoEstMinGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} />;
};
