import React from "react";
import moment from "moment";
import {
  DatePicker,
  FormButton,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../../components";
import { useState } from "react";
import { Row } from "reactstrap";
import ConferenciaDinheiroService from "../../../../../../services/pedidos/ConferenciaDinheiroService";
import { formatDateISO } from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";

export const ConferirValoresModal = ({
  selected,
  vlrCompensar,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataComp, setDataComp] = useState(new Date());
  const [observ, setObserv] = useState("");
  const [vlrConferido, setVlrConferido] = useState(vlrCompensar);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDataComp(new Date());
    setObserv("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setVlrConferido(vlrCompensar);
  };

  const handleSubmit = async () => {
    if (!(dataComp instanceof Date)) {
      toastr.warning(
        "Data incorreta",
        "Por favor, verifique a data de compensação"
      );
      return;
    }
    setLoading(true);

    const payload = {
      observ_conf: observ,
      data_compensacao: formatDateISO(dataComp),
      vlr_conferido: vlrConferido,
      ids_conferir: selected.map((e) => ({ id_pendencia: e })),
    };

    if (await ConferenciaDinheiroService.conferir(payload)) {
      notifyEvent();
      toggle();
    }

    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        color="success"
        onClick={toggle}
        disabled={selected.length === 0}
        disabledHint="Selecione ao menos uma venda da lista"
      >
        Confirmar Valores
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={limparDados}
        size="sm"
        title="Conferir Valores"
        number="0054_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <IntegerFormInput
            md={6}
            label="Vendas Selecionadas"
            defaultValue={selected.length}
            disabled
          />
          <NumberInput
            md={6}
            label="Valor Selecionado"
            value={vlrCompensar}
            disableGroupSeparators={false}
            disabled
          />
        </Row>
        <Row>
          <DatePicker
            md={6}
            label="Compensção"
            name="data_comp"
            value={dataComp}
            onChange={(v) => setDataComp(moment.isMoment(v) ? v.toDate() : v)}
            inputId="data-comp"
          />
          <NumberInput
            md={6}
            label="Valor Conferido"
            value={vlrConferido}
            onChange={setVlrConferido}
            disableGroupSeparators={false}
            disabled={selected.length > 1}
            id="vlrConferido"
            name="vlrConferido"
            hint={
              selected.length > 1
                ? "Caso deseje alterar o valor conferido, faça a conferência individual da venda onde ocorreu diferença entre o valor recebido e o valor total"
                : undefined
            }
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            type="textarea"
            rows={4}
            label="Observação"
            value={observ}
            onChange={(e, v) => setObserv(v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
