import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/comissao/regra_ordem_servico";
const URL_BASE_DETALHE = `${URL_BASE}/detalhe`;

const CadastroRegrasComissaoOsService = {
  urlBase: URL_BASE,
  listar: () => apiGetV2(`${URL_BASE}/listar/`),
  detalhe: {
    excluir: (idMov) => apiDeleteV2(`${URL_BASE_DETALHE}/excluir/${idMov}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_DETALHE}/incluir/`, payload),
    listar: (idRegra) => apiGetV2(`${URL_BASE_DETALHE}/listar/${idRegra}/`),
  },
};

export default CadastroRegrasComissaoOsService;
