import React from "react";
import { Table } from "../../../../../../../components";
import { formatNumber } from "../../../../../../../coreUtils";

export const ParcelasEntregaPedidoExtGrid = ({ parcelas, alterarParcela }) => {
  const columns = [
    {
      dataField: "parcela",
      text: "P",
      align: "center",
      fixedColWidth: true,
      colWidth: "33%",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      fixedColWidth: true,
      colWidth: "34%",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "date",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "33%",
    },
  ];

  return (
    <Table
      keyField="parcela"
      data={parcelas}
      columns={columns}
      paginated={false}
      showRegisterCount={false}
      pageSize={9}
      growIntoPageSize
    />
  );
};
