import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  relListagemProdutosContagem,
  relListagemValorEstoque,
  relProdutosAbaixoEstMin,
} from "../../../routes/modules/relatorios";
import { MdOutlineInventory2 } from "react-icons/md";
import { BsExclamationSquare } from "react-icons/bs";
import { GoListOrdered } from "react-icons/go";

export const Estoque = () => {
  return (
    <PageContainer title="Relatórios de Estoque">
      <MenuGroup>
        <MenuGroupButton
          label="Listagem de Produtos para Contagem"
          icon={GoListOrdered}
          pathname={relListagemProdutosContagem.path}
          nroTela={relListagemProdutosContagem.nroTela}
        />
        <MenuGroupButton
          label="Listagem de Valor em Estoque"
          icon={MdOutlineInventory2}
          pathname={relListagemValorEstoque.path}
          nroTela={relListagemValorEstoque.nroTela}
        />
        <MenuGroupButton
          label="Produtos Abaixo do Estoque Mínimo"
          icon={BsExclamationSquare}
          pathname={relProdutosAbaixoEstMin.path}
          nroTela={relProdutosAbaixoEstMin.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
