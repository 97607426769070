import React, { useState } from "react";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import CadastroRegrasComissaoOsService from "../../../../services/cadastro/CadastroRegrasComissaoOsService";
import { Card, Row } from "reactstrap";
import { RegraComissaoOsGrid } from "./components/RegraComissaoOsGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { CadastroRegraComissaoOsModal } from "./components/CadastroRegraComissaoOsModal";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { DetalhesRegraComissaoOsModal } from "./components/DetalhesRegraComissaoOsModal";

export const modalTitleRegraComissaoOs = "Regra de Comissão de OS";

export const RegraComissaoOs = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await CadastroRegrasComissaoOsService.listar();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Regras de Comissão de OS" number="0135">
      <Card body>
        <Row>
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            padded={false}
          />
          <BotaoIncluir toggle={toggleCadastro} padded={false} />
          <CadastroRegraComissaoOsModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleRegraComissaoOs}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0135_2"
            selected={selected}
            routeBase={CadastroRegrasComissaoOsService.urlBase}
            notifyEvent={notifyEvent}
          />
          <DetalhesRegraComissaoOsModal selected={selected} />
        </Row>
      </Card>
      <Card body>
        <RegraComissaoOsGrid
          dados={dados}
          alterar={alterar}
          excluir={excluir}
          setSelected={setSelected}
        />
      </Card>
    </PageContainer>
  );
};
