import React, { useState } from "react";
import {
  FormButton,
  LabelButton,
  ModalBase,
  TableDelete,
} from "../../../../../../components";
import { Label, Row } from "reactstrap";
import { resizeImage } from "./CarrosselFotosProd";
import { useRef } from "react";
import ImageViewer from "react-simple-image-viewer";

export const GerencFotosModal = ({
  isOpen,
  toggle,
  fotos = [],
  onAdd,
  onDelete,
  definirPrincipal,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [indexFoto, setIndexFoto] = useState(0);
  const addInputRef = useRef();
  const photoInputRef = useRef();

  const onAddInternal = async (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", async function (e) {
      const imgResize = await resizeImage(e.target.result);
      onAdd(imgResize);
    });

    reader.readAsDataURL(file);
  };

  const openViewer = (i) => {
    setIndexFoto(i);
    setIsViewerOpen(true);
  };

  const closeViewer = () => setIsViewerOpen(false);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Imagens do Produto"
      number="0029_12"
    >
      <Row>
        <FormButton
          md="auto"
          padded={false}
          color="primary"
          onClick={() => addInputRef.current.click()}
          divClassName="ml-auto"
        >
          Carregar Imagem
        </FormButton>
        <input
          type="file"
          multiple={false}
          maxFiles={1}
          accept="image/*"
          ref={addInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            onAddInternal(e.target.files[0]);
          }}
        />
        <FormButton
          md="auto"
          padded={false}
          color="primary"
          onClick={() => photoInputRef.current.click()}
        >
          Tirar Foto
        </FormButton>
        <input
          type="file"
          multiple={false}
          maxFiles={1}
          accept="image/*"
          ref={photoInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            onAddInternal(e.target.files[0]);
          }}
          capture="environment"
        />
      </Row>
      <div style={{ height: "340px", overflowY: "auto" }}>
        {fotos.length === 0 && (
          <Label style={{ width: "100%", textAlign: "center", lineHeight: 27 }}>
            Nenhuma foto associada ao Produto
          </Label>
        )}
        <table style={{ width: "100%" }}>
          <tbody>
            {fotos.map((e, index) => (
              <tr
                style={
                  index < fotos.length - 1
                    ? {
                        borderBottom: "0.5px solid #ddd",
                      }
                    : {}
                }
              >
                <td className="py-3" style={{ width: "120px" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#EEE",
                    }}
                  >
                    <img
                      src={e.base64_foto}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        cursor: "zoom-in",
                      }}
                      onClick={() => openViewer(index)}
                    />
                  </div>
                </td>
                <td className="py-3" style={{ width: "140px" }}>
                  {e.foto_principal && (
                    <span
                      style={{
                        backgroundColor: "rgb(35, 149, 70)",
                        color: "white",
                        borderRadius: "7px",
                        paddingInline: "0.8rem",
                        paddingBlock: "0.15rem",
                        marginRight: "0.8rem",
                      }}
                    >
                      Imagem Principal
                    </span>
                  )}
                </td>
                <td className="py-3">
                  <span>Tam.: {e.tamanho}</span>
                </td>
                <td className="py-3">
                  {!e.foto_principal && (
                    <LabelButton
                      onClick={() => definirPrincipal(index)}
                      divClassName="px-0"
                    >
                      Definir como Principal
                    </LabelButton>
                  )}
                </td>
                <td className="py-3" width="40px">
                  {(!e.foto_principal || fotos.length === 1) && (
                    <TableDelete onClick={() => onDelete(index)} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Label className="mb-auto mt-1">
        A Foto definida como Imagem Principal é a que aparecerá nas listagens de
        produtos dos Aplicativos de Força de Venda
      </Label>
      {isViewerOpen && (
        <ImageViewer
          src={fotos.map((e) => e.base64_foto)}
          currentIndex={indexFoto}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </ModalBase>
  );
};
