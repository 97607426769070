import React from "react";
import { Table } from "../../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../../coreUtils";

const columns = [
  {
    dataField: "nome",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id),
    sortable: true,
  },
  {
    dataField: "gerado_adm",
    text: "Lançado pela ADM",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunct: naturalSort,
  },
  {
    dataField: "gerado_venda",
    text: "Gerado em Vendas",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunct: naturalSort,
  },
  {
    dataField: "utilizado_venda",
    text: "Utilizado em Vendas",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunct: naturalSort,
  },
  {
    dataField: "saldo_flex",
    text: "Saldo Atual",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunct: naturalSort,
  },
];

export const FlexRepresentExtGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} paginated={false} />;
};
