import { apiGetV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/senha";

const CadastroSenhaService = {
  buscar: () => apiGetV2(`${URL_BASE}/buscar/`),
  alterar: (nomeUrl, payload) =>
    apiPutV2(`${URL_BASE}/alterar/${nomeUrl}/`, payload),
};

export default CadastroSenhaService;
