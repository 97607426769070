import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_centro_custo_receita: 0,
  id_conta_banc_fat_boleto: 0,
  gerar_boleto_automatico: true,
  envio_email_automatico: true,
};

export const entregaPedidoExtSlice = createSlice({
  name: "entrega_pedido_ext_slice",
  initialState: initialState,
  reducers: {
    setupEntregaPedidoExt: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (
          ["id_centro_custo_receita", "id_conta_banc_fat_boleto"].includes(
            key
          ) &&
          value === null
        ) {
          value = 0;
        }
        state[key] = value;
      });
      return state;
    },
    setIdCentroCustoReceita: (state, action) => {
      state.id_centro_custo_receita = action.payload;
    },
    setIdContaBancFatBoleto: (state, action) => {
      state.id_conta_banc_fat_boleto = action.payload;
    },
    setGerarBoletoAutomatico: (state, action) => {
      state.gerar_boleto_automatico = action.payload;
    },
    setEnvioEmailAutomatico: (state, action) => {
      state.envio_email_automatico = action.payload;
    },
  },
});

export const {
  setupEntregaPedidoExt,
  setIdCentroCustoReceita,
  setIdContaBancFatBoleto,
  setGerarBoletoAutomatico,
  setLayoutImprPed,
  setEnvioEmailAutomatico,
} = entregaPedidoExtSlice.actions;

export default entregaPedidoExtSlice.reducer;
