import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/outsourcing/tag_transp";

const OutsourcingService = {
  consultar: async (id) =>
    await apiGetV2(`${URL_BASE}/consultar_chave_acesso/${id}/`),
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar_consultas/`, params),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir_etiqueta/`, payload),
};

export default OutsourcingService;
