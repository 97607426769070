import moment from "moment";
import React from "react";
import { Card, Col } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  FormButton,
  NumberInput,
  TextInput,
  DatePicker,
  Divider,
  FixedField,
} from "../../../../../../components";
import {
  formatDateISO,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../../coreUtils";
import { centralPedInternoRoute } from "../../../../../../routes/modules/pedidos";
import { showWarning } from "../../../../../../components/AlertaModal";
import {
  cadastroProdutoRoute,
  incluirAlterarClienteRoute,
} from "../../../../../../routes/modules/cadastro";
import PedidoInternoService from "../../../../../../services/pedidos/PedidoInternoService";
import { ItensEntregaPedidoInternoGrid } from "./ItensEntregaPedidoInternoGrid";

export const EntregarPedidoInterno = ({ location }) => {
  const history = useHistory();
  const idPedido = location.state.idPedido;
  const [infoPedido, setInfoPedido] = useState({});
  const [itens, setItens] = useState([]);
  const [idTransportadora, setIdTransportadora] = useState(null);
  const [obsEntrega, setObsEntrega] = useState("");
  const [retirante, setRetirante] = useState("");
  const [dataPrevEntrega, setDataPrevEntrega] = useState(new Date());
  const [vlrEntrega, setVlrEntrega] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const carregarDados = async (carregarItens = true) => {
    const [ok, ret] = await PedidoInternoService.entrega.buscarDadosEntrega(
      idPedido
    );
    if (ok) {
      setInfoPedido(ret);
      if (carregarItens) {
        debugger;
        setItens(ret.itens.map((e) => ({ ...e, qt_entregar: 0 })));
      }
    }
    return ok;
  };

  const verificaEntregaTotal = () =>
    !itens.some(
      (e) => parseFloat(e.qt_entregar) !== parseFloat(e.qt_saldo_dis)
    );

  const atualizarVlrEntregaPesos = () => {
    let vEntrega = 0;

    itens.forEach((item) => {
      const qtEntregar = parseFloat(item.qt_entregar);
      if (item.tipo === "VDA") {
        vEntrega += parseFloat(item.vlr_final) * qtEntregar;
      }
    });

    setVlrEntrega(roundFloat(vEntrega, 2));
  };

  useEffect(atualizarVlrEntregaPesos, [itens]);

  const onChangeQtdEntrega = (coluna, novoValor, row) => {
    row.qt_entregar = parseFloat(novoValor);

    setItens(itens.map((e) => (e.id === row.id ? row : e)));
  };

  const entregarTudo = () => {
    setItens(
      itens.map((e) => ({
        ...e,
        qt_entregar: parseFloat(e.qt_saldo_dis),
      }))
    );
  };

  const zerarEntrega = () => {
    setItens(itens.map((e) => ({ ...e, qt_entregar: 0 })));
  };

  const verificarQtdEntrega = (row) => {
    if (parseFloat(row.qt_entregar) > parseFloat(row.qt_saldo_dis)) {
      row.qt_entregar = parseFloat(row.qt_saldo_dis);

      setItens(itens.map((e) => (e.id === row.id ? row : e)));

      showWarning("A quantidade de entrega não pode ser superior à restante");
    }
  };

  const alterarCadCliente = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarClienteRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: infoPedido.id_cliente,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      carregarDados(false);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const handleSubmit = async () => {
    if (loadingSubmit) return;

    if (!(dataPrevEntrega instanceof Date)) {
      showWarning("Por favor, revise a Data de Entrega");
      return false;
    }

    const itensEntrega = itens.filter((item) => item.qt_entregar > 0);

    setLoadingSubmit(true);
    try {
      const payload = {
        id_pedido: idPedido,
        id_transportadora: idTransportadora,
        observacao: obsEntrega,
        retirante: retirante ?? "",
        data_entrega: formatDateISO(dataPrevEntrega),
        itens_entregar: itensEntrega.map((e) => ({
          tipo: e.tipo,
          id_item: e.id,
          qtd_entregar: e.qt_entregar,
        })),
      };

      const [ok] = await PedidoInternoService.entrega.entregarPedido(payload);
      if (ok) {
        history.replace(centralPedInternoRoute.path, { refresh: true });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const iniciarTela = async () => {
    if (!(await carregarDados())) {
      history.goBack();
      return;
    }
    setLoading(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  return (
    <PageContainer
      title={`Entrega do Pedido Interno Nº ${idPedido}`}
      number="0100_1"
      canGoBack
      onKeyDown={onKeyDown}
      loading={loading}
    >
      <Card body>
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(
              infoPedido.nome_cliente,
              infoPedido.id_cliente
            )}
          />
          <FixedField label="Fone" value={infoPedido.fone_cliente} />
          <FixedField label="Whatsapp" value={infoPedido.whatsapp_cliente} />
          <FormButton
            md="auto"
            color="info"
            onClick={alterarCadCliente}
            padded={false}
            divStyle={{ paddingTop: "11px" }}
          >
            Cadastro Cliente
          </FormButton>
          <FixedField
            label="Vendedor"
            value={formatValueFromAPI(
              infoPedido.nome_vendedor,
              infoPedido.id_vendedor
            )}
          />
        </Row>
        <Row>
          <FixedField label="Endereço" value={infoPedido.endereco_cliente} />
          <FixedField label="Número" value={infoPedido.numero_cliente} />
          <FixedField label="Bairro" value={infoPedido.bairro_cliente} />
          <FixedField
            label="Cidade"
            value={`${infoPedido.cidade_cliente}-${infoPedido.uf_cidade_cliente}`}
          />
          <FixedField label="Emissão" value={infoPedido.emissao} />
        </Row>
      </Card>
      <Card body>
        <Divider>Entrega</Divider>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="transportadora"
            defaultOptions
            label="Transportadora"
            isSearchable
            name="transportadora"
            onChange={(s) => setIdTransportadora(s?.value ?? null)}
            defaultValue={idTransportadora}
            autoFocus
          />
          <DatePicker
            label="Data de Entrega"
            md="2"
            value={dataPrevEntrega}
            onChange={(v) =>
              setDataPrevEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
          />
        </Row>
        <Row className="mb-3">
          <FormButton
            md="auto"
            color="info"
            onClick={() => window.open(cadastroProdutoRoute.path)}
            padded={false}
          >
            Cadastro Produtos
          </FormButton>
          <FormButton
            md="auto"
            color="light"
            onClick={entregarTudo}
            divClassName="ml-auto"
            padded={false}
          >
            Entregar Tudo
          </FormButton>
          <FormButton
            md="auto"
            color="light"
            onClick={zerarEntrega}
            padded={false}
          >
            Zerar Entrega
          </FormButton>
        </Row>
        <ItensEntregaPedidoInternoGrid
          data={itens}
          onChangeQtdEntrega={onChangeQtdEntrega}
          verificarQtdEntrega={verificarQtdEntrega}
        />
      </Card>
      <Card body>
        <Row>
          <Col md={7}>
            <Row className="mb-2">
              <NumberInput
                md={3}
                label="Valor da Entrega"
                value={
                  verificaEntregaTotal()
                    ? vlrEntrega - (infoPedido.vlr_desc_sobre_total ?? 0)
                    : vlrEntrega
                }
                disabled
              />
            </Row>
          </Col>
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            type="textarea"
            label="Observação"
            md={5}
            value={obsEntrega}
            onChange={(e, v) => setObsEntrega(v)}
          />
          <TextInput
            name="retirante"
            label="Retirante"
            md={5}
            value={retirante}
            onChange={(e, v) => setRetirante(v)}
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
