import React from "react";
import { Card, DropdownItem } from "reactstrap";
import { formatarValor, naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";
import { TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const ProdutosGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
  sistAutomotivo,
  utilizaCorProd,
  utilizaTamanhoProd,
  usaPrecoAtacado,
  mostrarLogs,
  msgTotal,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_fabricante",
      text: "Fabricante",
      align: "left",
      sortable: true,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "center",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaCorProd,
    },
    {
      dataField: "tamanho",
      text: "Tamanho",
      align: "center",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaTamanhoProd,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      sortable: true,
      hidden: localStorage.getItem("vis_custo_pesq_prod") !== "true",
      formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_vlrs),
      sortFunc: naturalSort,
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      sortable: true,
      formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_qtd),
      style: (c) => parseFloat(c ?? 0) <= 0 && { color: "red" },
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_reserva",
      text: "Reserva",
      align: "center",
      sortable: true,
      hidden: !sistAutomotivo,
      formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_qtd),
      sortFunc: naturalSort,
    },
    {
      dataField: "preco_venda",
      text: !usaPrecoAtacado || sistAutomotivo ? "Preço" : "Valor",
      align: "right",
      sortable: true,
      formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_vlrs),
      sortFunc: naturalSort,
    },
    {
      dataField: "preco_atacado",
      text: "Atacado",
      align: "right",
      sortable: true,
      formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_vlrs),
      sortFunc: naturalSort,
      hidden: !usaPrecoAtacado || sistAutomotivo,
    },
    {
      dataField: "ativo",
      text: "",
      align: "center",
      sortable: true,
      formatter: (c) => <TableCheck value={c} />,
      hint: "Ativo",
    },
    iconeAlterar(alterar),
    dropdownAcoes({
      excluir: excluir,
      outrasAcoes: (id) => (
        <>
          <DropdownItem onClick={() => mostrarLogs(id)}>Logs</DropdownItem>
        </>
      ),
    }),
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        onSelect={setSelected}
        pageSize={sistAutomotivo ? 10 : 25}
        paginated={!sistAutomotivo}
        clearSelectionOnUpdate
        onRowDoubleClick={alterar}
        growIntoPageSize
        recordCountFormatter={() => msgTotal}
      />
    </Card>
  );
};
