import React from "react";
import { Row } from "reactstrap";
import { ComboBox, RadioGroup } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setGerarMovCaixaLojaSobre,
  setImprimir,
  setLayoutImpressao,
} from "../store/pedido_venda_slice";
import { RadioItem } from "../../../../components/RadioGroup";

const layoutImpressaoOptions = [
  {
    label:
      "Modelo 1 - Folha A4 - 1 via; Considera Ambiente; Produto, Nome Produto, UN, Qtde, Valor Item, Desconto, Total",
    value: 1,
  },
  { label: "Modelo 2 - Matricial", value: 2 },
  {
    label: "Modelo 3 - Bobina 80mm - Impressão para impressoras térmicas",
    value: 3,
  },
  {
    label:
      "Modelo 5 - Folha A4 - Observação do Item  WEB - Informações e logo da empresa",
    value: 5,
  },
  { label: "Modelo 8 - Folha A4 - Impressão Compacta", value: 8 },
];

// const layoutEtiquetaOptions = [
//   { label: "Modelo 1 - ", value: 1 },
//   { label: "Modelo 2 - ", value: 2 },
// ];

export const PersonPedidoVenda = () => {
  const store = useSelector((state) => state.pedido_venda);
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <RadioGroup
          label="Imprimir Pedido"
          value={store.imprimir}
          onChange={(v) => dispatch(setImprimir(v))}
        >
          <RadioItem label="Sempre Imprimir" value="I" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={8}
          label="Layout de Impressão"
          options={layoutImpressaoOptions}
          defaultValue={store.layout_impressao}
          onChange={(s) => dispatch(setLayoutImpressao(s?.value))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Gerar movimento no Caixa Loja"
          value={store.gerar_mov_caixa_loja_sobre}
          onChange={(v) => dispatch(setGerarMovCaixaLojaSobre(v))}
        >
          <RadioItem label="No registro do Pedido [0030]" value="PED" />
          <RadioItem
            label="Na entrega do Pedido, Geração da Venda"
            value="ENT"
          />
        </RadioGroup>
      </Row>
    </>
  );
};
