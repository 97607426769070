import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { BotaoPesquisar, PageContainer, TextInput } from "../../../components";
import { AddaxTransportesGrid } from "./components/AddaxTransportesGrid";
import OutsourcingService from "../../../services/outsourcing/OutsourcingService";
import { docServiceV2 } from "../../../pdf";
import { printLabel } from "../../../utils/printing";
import ImpressaoEtiquetasProdutoService from "../../../services/cadastro/ImpressaoEtiquetasProdutoService";
import { showError } from "../../../components/AlertaModal";
import { AddaxIncluirModal } from "./components/AddaxIncluirModal";

export const AddaxTransportes = () => {
  const [numeroNf, setNumeroNf] = useState("");

  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await OutsourcingService.listar({
      numero_nf: numeroNf,
    });

    setDados(ok ? ret : []);
  };

  const buscarImpressao = async (dados) => {
    const res = await docServiceV2
      .post("/etiquetas/layout_addax_10x15/", {
        dados: {
          numero_nf: dados.numero_nf,
          nome_cliente: dados.nome_cliente,
          qtd_volumes: dados.qtd_volumes,
          destino: dados.destino
        },
        gerar_lista: true,
      })
      .then((ret) => ret.data)
      .catch(async (err) => {
        showError(
          "Erro",
          "ErrEtqOutsourcing",
          err.response ? JSON.stringify(err.response.data) : err.message
        );
        return false;
      });

    if (res) {
      const [okParams, paramsImpr] =
        await ImpressaoEtiquetasProdutoService.buscarParams();

      if (okParams) {
        for (let i = 0; i < dados.qtd_volumes; i++) {
          await printLabel(res, paramsImpr?.camin_impressora_etiqueta ?? "");
        }
      }
    }
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  return (
    <PageContainer
      title="Impressão de Etiquetas para Entrega"
      number="0134"
      canGoBack
    >
      <Card body>
        <Row>
          <TextInput
            value={numeroNf}
            onChange={(e, v) => setNumeroNf(v)}
            label={"Número da NF"}
            md={3}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <AddaxIncluirModal buscarImpressao={buscarImpressao} />
        </Row>
      </Card>
      <Card body>
        <AddaxTransportesGrid dados={dados} buscarImpressao={buscarImpressao} />
      </Card>
    </PageContainer>
  );
};
