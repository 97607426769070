import React from "react";
import { DataTable } from "../../../../../../components/DataTable";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";

const columns = [
  {
    dataField: "id_venda",
    text: "Nº Venda",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_pedido_vda",
    text: "Nº Pedido",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "numero_nf",
    text: "Nro. NF",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_transportadora",
    text: "Transportadora",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) =>
      row.id_transportadora
        ? formatValueFromAPI(c, row.id_transportadora)
        : "Sem Transportadora",
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "nome_cidade",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "qtd_volume",
    text: "Volumes",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "vlr_total",
    text: "Valor Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const RomaneioGrid = ({
  data,
  selected,
  handleSelect,
  handleSelectAll,
}) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      isMulti
      selected={selected}
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      paginated
    />
  );
};
