import { BarChart } from "react-feather";
import { ComissaoFixa } from "../../pages/relatorios/comissionamento/comissao_fixa/ComissaoFixa";
import ComissaoFixaOS from "../../pages/relatorios/comissionamento/ComissaoFixaOS";
import { ComissaoPorMeta } from "../../pages/relatorios/comissionamento/ComissaoPorMeta/ComissaoPorMeta";
import { ComissaoGerencia } from "../../pages/relatorios/comissionamento/Gerencia/ComissaoGerencia";
import { ComissionamentoVTS } from "../../pages/relatorios/comissionamento/vts/ComissionamentoVTS";
import { ParticipacaoDeVendas } from "../../pages/relatorios/vendas/participacao_de_vendas/ParticipacaoDeVendas";
import { VendasAgrupadasPorProduto } from "../../pages/relatorios/vendas/vendas_agrupadas_por_produto/VendasAgrupadasPorProduto";
import { VendasPorGrupo } from "../../pages/relatorios/vendas/vendas_por_grupo/VendasPorGrupo";
import { VendasPorVendedor } from "../../pages/relatorios/vendas/vendas_por_vendedor/VendasPorVendedor";
import { RepresGruposProd } from "../../pages/relatorios/vendas/repres_grupos_prod/RepresGruposProd";
import { ComissaoVendExt } from "../../pages/relatorios/comissionamento/comissao_vend_ext/ComissaoVendExt";
import { ExtratoCreditoRotativo } from "../../pages/relatorios/financeiro_receber/extrato_credito_rotativo/ExtratoCreditoRotativo";
import { DemonstrativoDespesasPorCC } from "../../pages/relatorios/financeiro_pagar/demonstrativo_despesas_por_cc/DemonstrativoDespesasPorCC";
import { DemonstrativoDespesasPorPlanoConta } from "../../pages/relatorios/financeiro_pagar/demonstrativo_despesas_por_plano_conta/DemonstrativoDespesasPorPlanoConta";
import { CadastralCliente } from "../../pages/relatorios/cadastrais/clientes/CadastralCliente";
import { CadastralCFOP } from "../../pages/relatorios/cadastrais/cfop/CadastralCFOP";
import { CurvaABC } from "../../pages/relatorios/curva_abc/CurvaABC";
import { DesempenhoVendasMetaLucro } from "../../pages/relatorios/vendas/desempenho_vendas_meta_lucro/DesempenhoVendasMetaLucro";
import { VendasGeral } from "../../pages/relatorios/vendas/vendas_geral/VendasGeral";
import { ComissaoLucro } from "../../pages/relatorios/comissionamento/comissao_lucro/ComissaoLucro";
import { NfSaidaAgrupadoProduto } from "../../pages/relatorios/nf_saida/agrupado_produto/NfSaidaAgrupadoProduto";
import { NfSaida } from "../../pages/relatorios/nf_saida/NfSaida";
import { Cadastrais } from "../../pages/relatorios/cadastrais/Cadastrais";
import { Comissionamento } from "../../pages/relatorios/comissionamento/Comissionamento";
import { FinanceiroPagar } from "../../pages/relatorios/financeiro_pagar/FinanceiroPagar";
import { Vendas } from "../../pages/relatorios/vendas/Vendas";
import { VendaVsCompra } from "../../pages/relatorios/vendas/venda_vs_compra/VendaVsCompra";
import { FinanceiroReceber } from "../../pages/relatorios/financeiro_receber/FinanceiroReceber";
import { ClientesInadimplentes } from "../../pages/relatorios/financeiro_receber/clientes_inadimplentes/ClientesInadimplentes";
import { ClientesNaoVendidos } from "../../pages/relatorios/vendas/clientes_nao_vendidos/ClientesNaoVendidos";
import { NfSaidaGeral } from "../../pages/relatorios/nf_saida/geral/NfSaidaGeral";
import { ClientesNovos } from "../../pages/relatorios/vendas/clientes_novos/ClientesNovos";
import { ProdutosNcmInvalida } from "../../pages/relatorios/nf_saida/produtos_ncm_invalida/ProdutosNcmInvalida";
import { NfDebitoLocacao } from "../../pages/relatorios/nf_saida/debito_locacao/NfDebitoLocacao";
import { ExclusaoICMSST } from "../../pages/relatorios/nf_saida/exclusao_icms_st/ExclusaoICMSST";
import { VisitasNaoVenda } from "../../pages/relatorios/vendas/visitas_nao_venda/VisitasNaoVenda";
import { ProdutosBalanca } from "../../pages/relatorios/cadastrais/produtos_balanca/ProdutosBalanca";
import { ClientesPorVendedor } from "../../pages/relatorios/cadastrais/clientes_por_vendedor/ClientesPorVendedor";
import { VendasExcetoGrupos } from "../../pages/relatorios/outros/vendas_exceto_grupos/VendasExcetoGrupos";
import { RelOutros } from "../../pages/relatorios/outros/RelOutros";
import { VendaOSFormaRecNF } from "../../pages/relatorios/vendas/venda_os_forma_rec_nf/VendaOSFormaRecNF";
import { RelControladoria } from "../../pages/relatorios/controladoria/RelControladoria";
import { DemonstrativoResultadosPage } from "../../pages/relatorios/controladoria/dre/DemonstrativoResultados";
import { FluxoDeCaixa } from "../../pages/relatorios/controladoria/fluxo_de_caixa/FluxoDeCaixa";
import { ResumoResultado } from "../../pages/relatorios/controladoria/resumo_resultado/ResumoResultado";
import { CustoVsPrecoVenda } from "../../pages/relatorios/vendas/custo_vs_preco_venda/CustoVsPrecoVenda";
import { ContasReceberPendentes } from "../../pages/relatorios/financeiro_receber/contas_receber_pendentes/ContasReceberPendentes";
import { DemonstrativoReceitasPorCC } from "../../pages/relatorios/financeiro_receber/demonstrativo_receitas_por_cc/DemonstrativoDespesasPorCC";
import { DemonstrativoReceitasPorPlanoConta } from "../../pages/relatorios/financeiro_receber/demonstrativo_receitas_por_plano_conta/DemonstrativoReceitasPorPlanoConta";
import { Estoque } from "../../pages/relatorios/estoque/Estoque";
import { ListagemValorEstoque } from "../../pages/relatorios/estoque/listagem_valor_estoque/ListagemValorEstoque";
import { PedidosPendentesEntrega } from "../../pages/relatorios/pedidos/pedidos_pendentes_entrega/PedidosPendentesEntrega";
import { Pedidos } from "../../pages/relatorios/pedidos/Pedidos";
import { ProdutosAbaixoEstMin } from "../../pages/relatorios/estoque/produtos_abaixo_est_min/ProdutosAbaixoEstMin";
import { ListagemProdutosContagem } from "../../pages/relatorios/estoque/listagem_produtos_contagem/ListagemProdutosContagem";

export const pedPendentesEntregaRoute = {
  path: "/relatorios/pedidos/pedidos_pendentes_entrega",
  name: "Pedidos Pendentes de Entrega",
  id: "AcSkWeb5018",
  nroTela: "5018",
  component: PedidosPendentesEntrega,
};

export const relatoriosPedidosRoutes = [pedPendentesEntregaRoute];

export const relNfSaiAgrupadoProdutoRoute = {
  path: "/relatorios/nf_saida/agrupado_produto",
  name: "Agrupado por Produto",
  id: "AcSkWeb5043",
  nroTela: "5043",
  component: NfSaidaAgrupadoProduto,
};

export const relNfSaidaGeralRoute = {
  path: "/relatorios/nf_saida/geral",
  name: "Geral",
  id: "AcSkWeb5046",
  nroTela: "5046",
  component: NfSaidaGeral,
};

export const relnfSaidaProdNcmInvalidaRoute = {
  path: "/relatorios/nf_saida/produtos_ncm_invalida",
  name: "Produtos com NCM Inválida",
  id: "AcSkWeb5049",
  nroTela: "5049",
  component: ProdutosNcmInvalida,
};

export const relNfSaiDebitoLocacaoRoute = {
  path: "/relatorios/nf_saida/debito_locacao",
  name: "NF Débito de Locação",
  id: "AcSkWeb5050",
  nroTela: "5050",
  component: NfDebitoLocacao,
};

export const relNfSaiExclusaoIcmsStRoute = {
  path: "/relatorios/nf_saida/exclusao_icms_st",
  name: "Exclusão de ICMS ST",
  id: "AcSkWeb5029",
  nroTela: "5029",
  component: ExclusaoICMSST,
};

export const relatoriosNfSaidaRoutes = [
  relNfSaiAgrupadoProdutoRoute,
  relNfSaidaGeralRoute,
  relnfSaidaProdNcmInvalidaRoute,
  relNfSaiDebitoLocacaoRoute,
  relNfSaiExclusaoIcmsStRoute,
];

export const relCadClienteRoute = {
  path: "/relatorios/cadastrais/cliente",
  name: "Cliente",
  id: "AcSkWeb5035",
  nroTela: "5035",
  component: CadastralCliente,
};

export const relCadCfopRoute = {
  path: "/relatorios/cadastrais/cfop",
  name: "CFOP",
  id: "AcSkWeb5036",
  nroTela: "5036",
  component: CadastralCFOP,
};

export const relCadProdutosBalancaRoute = {
  path: "/relatorios/cadastrais/produtos_balanca",
  name: "Produtos com Código de Balança",
  id: "AcSkWeb5052",
  nroTela: "5052",
  component: ProdutosBalanca,
};

export const relCadClientesPorVendedorRoute = {
  path: "/relatorios/cadastrais/clientes_por_vendedor",
  name: "Clientes por Vendedor",
  id: "AcSkWeb5053",
  nroTela: "5053",
  component: ClientesPorVendedor,
};

export const relatoriosCadastraisRoutes = [
  relCadClienteRoute,
  relCadCfopRoute,
  relCadProdutosBalancaRoute,
  relCadClientesPorVendedorRoute,
];

export const relComissaoFixaOsRoute = {
  path: "/relatorios/comissionamento/comissao_fixa_os",
  name: "Comissao Fixa OS",
  id: "AcSkWeb5009",
  nroTela: "5009",
  component: ComissaoFixaOS,
};

export const relComissaoFixaRoute = {
  path: "/relatorios/comissionamento/comissao_fixa",
  name: "Comissão Fixa",
  id: "AcSkWeb5021",
  nroTela: "5021",
  component: ComissaoFixa,
};

export const relComissaoLucroRoute = {
  path: "/relatorios/comissionamento/comissao_lucro",
  name: "Comissão por Lucro",
  id: "AcSkWeb5042",
  nroTela: "5042",
  component: ComissaoLucro,
};

export const relComissaoMetaRoute = {
  path: "/relatorios/comissionamento/comissao_por_meta",
  name: "Comissão por Meta",
  id: "AcSkWeb5022",
  nroTela: "5022",
  component: ComissaoPorMeta,
};

export const relComissaoVendedorExternoRoute = {
  path: "/relatorios/comissionamento/comissao_vendedor_externo",
  name: "Comissão por Vendedor Externo",
  id: "AcSkWeb5032",
  nroTela: "5032",
  component: ComissaoVendExt,
};

export const relComissaoGerenciaRoute = {
  path: "/relatorios/comissionamento/gerencia",
  name: "Gerência",
  id: "AcSkWeb5012",
  nroTela: "5012",
  component: ComissaoGerencia,
};

export const relComissaoVtsRoute = {
  path: "/relatorios/comissionamento/vendedor_tecnico_servico",
  name: "Vendedor Técnico de Serviços",
  id: "AcSkWeb5014",
  nroTela: "5014",
  component: ComissionamentoVTS,
};

export const relatoriosComissionamentoRoutes = [
  relComissaoFixaOsRoute,
  relComissaoFixaRoute,
  relComissaoLucroRoute,
  relComissaoMetaRoute,
  relComissaoVendedorExternoRoute,
  relComissaoGerenciaRoute,
  relComissaoVtsRoute,
];

export const relFinancPagarDespesasLiquidadasCentroCustoRoute = {
  path: "/relatorios/financeiro_pagar/despesas_liquidadas_centro_custo",
  name: "Despesas Liquidadas por Centro de Custo",
  id: "AcSkWeb5016",
  nroTela: "5016",
  component: DemonstrativoDespesasPorCC,
};

export const relFinancPagarDespesasLiquidadasPlanoContasRoute = {
  path: "/relatorios/financeiro_pagar/despesas_liquidadas_plano_contas",
  name: "Despesas Liquidadas por Plano de Contas",
  id: "AcSkWeb5017",
  nroTela: "5017",
  component: DemonstrativoDespesasPorPlanoConta,
};

export const relatoriosFinPagarRoutes = [
  relFinancPagarDespesasLiquidadasCentroCustoRoute,
  relFinancPagarDespesasLiquidadasPlanoContasRoute,
];

export const relVendasGeralRoute = {
  path: "/relatorios/vendas/vendas_geral",
  name: "Geral",
  id: "AcSkWeb5040",
  nroTela: "5040",
  component: VendasGeral,
};

export const relVendasCustoVsPrecoVendaRoute = {
  path: "/relatorios/vendas/custo_vs_preco_venda",
  name: "Geral",
  nroTela: "5058",
  component: CustoVsPrecoVenda,
};

export const relVendasOsFormaRecebimentoNfRoute = {
  path: "/relatorios/vendas/venda_os_forma_recebimento_nf",
  name: "Venda/O.S. - Forma de Recebimento e NF",
  id: "AcSkWeb5056",
  nroTela: "5056",
  component: VendaOSFormaRecNF,
};

export const relVendasParticipacaoVendasRoute = {
  path: "/relatorios/vendas/percentual_vendas_vendedor",
  name: "Participação de Vendas",
  id: "AcSkWeb5005",
  nroTela: "5005",
  component: ParticipacaoDeVendas,
};

export const relVendasComparativoGruposRoute = {
  path: "/relatorios/vendas/cumulativo_vendas_vendedor",
  name: "Representatividade de Grupo de Produtos",
  id: "AcSkWeb5006",
  nroTela: "5006",
  component: RepresGruposProd,
};

export const relVendasAbaixoMargLucroRoute = {
  path: "/relatorios/vendas/desempenho_vendas_meta_lucro",
  name: "Desempenho de Vendas por Meta de Lucro",
  id: "AcSkWeb5038",
  nroTela: "5038",
  component: DesempenhoVendasMetaLucro,
};
export const relVendasAgrupadasPorProdutoRoute = {
  path: "/relatorios/vendas/vendas_agrupadas_por_produto",
  name: "Vendas Agrupadas por Produto",
  id: "AcSkWeb5015",
  nroTela: "5015",
  component: VendasAgrupadasPorProduto,
};

export const relVendasPorGrupoRoute = {
  path: "/relatorios/vendas/vendas_por_grupo",
  name: "Vendas por Grupo",
  id: "AcSkWeb5003",
  nroTela: "5003",
  component: VendasPorGrupo,
};

export const relVendasPorVendedorRoute = {
  path: "/relatorios/vendas/vendas_por_vendedor",
  name: "Vendas por Vendedor",
  id: "AcSkWeb5004",
  nroTela: "5004",
  component: VendasPorVendedor,
};

export const relVendaXCompraRoute = {
  path: "/relatorios/vendas/venda_x_compra",
  name: "Venda x Compra por Forma de Pagamento",
  id: "AcSkWeb5019",
  nroTela: "5019",
  component: VendaVsCompra,
};

export const relVendasClientesNaoVendidosRoute = {
  path: "/relatorios/vendas/clientes_nao_vendidos",
  name: "Clientes Não Vendidos",
  id: "AcSkWeb5045",
  nroTela: "5045",
  component: ClientesNaoVendidos,
};

export const relVendasClientesNovosRoute = {
  path: "/relatorios/vendas/clientes_novos",
  name: "Clientes Novos",
  id: "AcSkWeb5048",
  nroTela: "5048",
  component: ClientesNovos,
};

export const relVendasVistasNaoVendaRoute = {
  path: "/relatorios/vendas/visitas_nao_venda",
  name: "Visitas (Não-venda)",
  id: "AcSkWeb5051",
  nroTela: "5051",
  component: VisitasNaoVenda,
};

export const relatoriosVendasRoutes = [
  relVendasGeralRoute,
  relVendasOsFormaRecebimentoNfRoute,
  relVendasParticipacaoVendasRoute,
  relVendasComparativoGruposRoute,
  relVendasAbaixoMargLucroRoute,
  relVendasAgrupadasPorProdutoRoute,
  relVendasPorGrupoRoute,
  relVendasPorVendedorRoute,
  relVendaXCompraRoute,
  relVendasClientesNaoVendidosRoute,
  relVendasClientesNovosRoute,
  relVendasVistasNaoVendaRoute,
  relVendasCustoVsPrecoVendaRoute,
];

export const relControlaDreRoute = {
  path: "/relatorios/controladoria/dre",
  name: "DRE",
  id: "AcSkWeb5007",
  nroTela: "5007",
  component: DemonstrativoResultadosPage,
};

export const relControlaFluxoCaixaRoute = {
  path: "/relatorios/controladoria/fluxo_caixa",
  name: "Fluxo de Caixa",
  id: "AcSkWeb5010",
  nroTela: "5010",
  component: FluxoDeCaixa,
};

export const relControlaResumoResultadoRoute = {
  path: "/relatorios/controladoria/resumo_resultado",
  name: "Resumo de Resultado",
  id: "AcSkWeb5047",
  nroTela: "5047",
  component: ResumoResultado,
};

export const relatoriosControladoriaRoutes = [
  relControlaDreRoute,
  relControlaFluxoCaixaRoute,
  relControlaResumoResultadoRoute,
];

export const relFinancReceberExtratoCreditoRotativoRoute = {
  path: "/relatorios/financeiro_receber/extrato_credito_rotativo",
  name: "Extrato de Crédito Rotativo por Cliente",
  id: "AcSkWeb5034",
  nroTela: "5034",
  component: ExtratoCreditoRotativo,
};

export const relFinancReceberClienteInadimplentesRoute = {
  path: "/relatorios/financeiro_receber/clientes_inadimplentes",
  name: "Clientes Inadimplentes",
  id: "AcSkWeb5044",
  nroTela: "5044",
  component: ClientesInadimplentes,
};

export const relFinancReceberContasReceberPendentesRoute = {
  path: "/relatorios/financeiro_receber/contas_receber_pendentes",
  name: "Contas a Receber Pendentes",
  nroTela: "5060",
  component: ContasReceberPendentes,
};

export const relFinancReceberReceitasLiquidadasCentroCustoRoute = {
  path: "/relatorios/financeiro_receber/receitas_liquidadas_centro_custo",
  name: "Receitas Liquidadas por Centro de Custo",
  id: "AcSkWeb5061",
  nroTela: "5061",
  component: DemonstrativoReceitasPorCC,
};

export const relFinancReceberReceitasLiquidadasPlanoContasRoute = {
  path: "/relatorios/financeiro_receber/receitas_liquidadas_plano_contas",
  name: "Receitas Liquidadas por Plano de Contas",
  id: "AcSkWeb5062",
  nroTela: "5062",
  component: DemonstrativoReceitasPorPlanoConta,
};

export const relatoriosFinReceberRoutes = [
  relFinancReceberExtratoCreditoRotativoRoute,
  relFinancReceberClienteInadimplentesRoute,
  relFinancReceberContasReceberPendentesRoute,
  relFinancReceberReceitasLiquidadasPlanoContasRoute,
  relFinancReceberReceitasLiquidadasCentroCustoRoute,
];

export const relListagemValorEstoque = {
  path: "/relatorios/estoque/listagem_valor_estoque",
  name: "Listagem de Valor em Estoque",
  id: "AcSkWeb5063",
  nroTela: "5063",
  component: ListagemValorEstoque,
};

export const relProdutosAbaixoEstMin = {
  path: "/relatorios/estoque/produtos_abaixo_est_min",
  name: "Produtos Abaixo do Estoque Mínimo",
  nroTela: "5065",
  component: ProdutosAbaixoEstMin,
};

export const relListagemProdutosContagem = {
  path: "/relatorios/estoque/listagem_produtos_contagem",
  name: "Listagem de Produtos para Contagem de Estoque",
  nroTela: "5066",
  component: ListagemProdutosContagem,
};

export const relatoriosEstoqueRoutes = [
  relListagemProdutosContagem,
  relListagemValorEstoque,
  relProdutosAbaixoEstMin,
];

export const relOutrosVendasExcetoGruposRoute = {
  path: "/relatorios/outros/vendas_exceto_grupos",
  name: "Vendas Exceto Grupos",
  id: "AcSkWeb5054",
  nroTela: "5054",
  component: VendasExcetoGrupos,
};

export const relatoriosOutrosRoutes = [relOutrosVendasExcetoGruposRoute];

const relatoriosRoutes = {
  path: "/relatorios",
  name: "Relatórios",
  icon: BarChart,
  children: [
    {
      path: "/relatorios/cadastrais",
      name: "Cadastrais",
      component: Cadastrais,
      canGoBack: false,
    },
    {
      path: "/relatorios/comissionamento",
      name: "Comissionamento",
      component: Comissionamento,
      canGoBack: false,
    },
    {
      path: "/relatorios/controladoria",
      name: "Controladoria",
      component: RelControladoria,
      canGoBack: false,
    },
    {
      path: "/relatorios/curva_abc",
      name: "Curva ABC",
      id: "AcSkWeb5037",
      nroTela: "5037",
      component: CurvaABC,
      canGoBack: false,
    },
    {
      path: "/relatorios/financeiro_pagar/",
      name: "Financeiro - Pagar",
      component: FinanceiroPagar,
      canGoBack: false,
    },
    {
      path: "/relatorios/financeiro_receber/",
      name: "Financeiro - Receber",
      component: FinanceiroReceber,
      canGoBack: false,
    },
    {
      path: "/relatorios/nf_saida",
      name: "NF Saída",
      component: NfSaida,
      canGoBack: false,
    },
    {
      path: "/relatorios/pedidos",
      name: "Pedidos",
      component: Pedidos,
      canGoBack: false,
    },
    {
      path: "/relatorios/vendas",
      name: "Vendas",
      component: Vendas,
      canGoBack: false,
    },
    {
      path: "/relatorios/estoque",
      name: "Estoque",
      component: Estoque,
      canGoBack: false,
    },
    {
      path: "/relatorios/outros",
      name: "Outros",
      component: RelOutros,
      canGoBack: false,
    },
  ],
};

export default relatoriosRoutes;
