import React, { useEffect, useState } from "react";
import { FormButton, ModalBase, TextInput } from "../../../../../components";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";
import { useSelector } from "react-redux";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { showSuccess } from "../../../../../components/AlertaModal";

const ItensOrdemCompraModalGrid = ({ dados, alterarOrdemCompraItem }) => {
  const columns = [
    { dataField: "item", text: "I", align: "center" },
    {
      dataField: "id_prod_serv",
      text: "Código",
      align: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "ordem_compra",
      text: "Ordem de Compra",
      align: "center",
      colWidth: "75px",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarOrdemCompraItem,
    },
  ];

  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={10} />
  );
};

const DadosOrdemCompraModal = () => {
  const stateCab = useSelector((state) => state.vendaCab);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [itens, setItens] = useState([]);

  const [numeroOrdCompra, setNumeroOrdCompra] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const carregarItens = async () => {
    const [ok, ret] = await FrenteVendaService.buscarItens(stateCab.id_venda);
    if (ok) {
      setItens(ret.itens.filter((e) => e.tipo === "P"));
    }
  };

  useEffect(() => {
    if (isOpen) {
      carregarItens();
    } else {
      setItens([]);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      for (const item of itens) {
        const ok = await atualizarItem(item);
        if (!ok) {
          return;
        }
      }

      toggle();
    } finally {
      setLoadingSubmit(false);
    }
  };

  const alterarOrdemCompraItem = (_, novoValor, row) => {
    if (novoValor === row.ordem_compra) {
      return row;
    }

    row.ordem_compra = novoValor;

    setItens(
      itens.map((element) => {
        if (element.id === row.id && element.tipo === row.tipo) {
          return { ...row };
        } else {
          return element;
        }
      })
    );

    return row;
  };

  const repetirNumeroTodosProd = () => {
    itens.forEach((e) => {
      alterarOrdemCompraItem(undefined, numeroOrdCompra, e);
    });
  };

  const atualizarItem = async (row) => {
    const payload = {
      id_item: row.id,
      tipo: row.tipo,
      id_prod_serv: row.id_prod_serv,
      descricao: row.descricao,
      unidade: row.unidade,
      id_ambiente: row.id_ambiente,
      quantidade: parseFloat(row.quantidade ?? "0") ?? 0,
      vlr_unit: parseFloat(row.vlr_unit ?? "0") ?? 0,
      vlr_item: parseFloat(row.vlr_item ?? "0") ?? 0,
      perc_desc: parseFloat(row.perc_desc ?? "0") ?? 0,
      vlr_tot_prod_serv: parseFloat(row.vlr_tot_prod_serv ?? "0") ?? 0,
      vlr_total: parseFloat(row.vlr_total ?? "0") ?? 0,
      vlr_desc: parseFloat(row.vlr_desc ?? "0") ?? 0,
      vlr_acre: parseFloat(row.vlr_acre ?? "0") ?? 0,
      perc_ipi: parseFloat(row.perc_ipi ?? "0") ?? 0,
      vlr_ipi: parseFloat(row.vlr_ipi ?? "0") ?? 0,
      vlr_icms_st: parseFloat(row.vlr_icms_st ?? "0") ?? 0,
      vlr_icms_st_unit: parseFloat(row.vlr_icms_st_unit ?? "0") ?? 0,
      perc_comis: parseFloat(row.perc_comis ?? "0") ?? 0,
      vlr_tot_comis: parseFloat(row.vlr_tot_comis ?? "0") ?? 0,
      observ: row.observ ?? "",
      nro_ord_compra: row.ordem_compra ?? null,
    };

    const [ok] = await FrenteVendaService.alterarItem(payload);
    return ok;
  };

  return (
    <>
      <FormButton
        color="magenta"
        onClick={toggle}
        padded={false}
        divClassName="mr-auto"
      >
        Informar Ordem de Compra
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Informar Ordem de Compra nos Produtos"
        number="0030_12"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
      >
        <Row>
          <TextInput
            label="Número da O.C."
            value={numeroOrdCompra}
            onChange={(e, v) => setNumeroOrdCompra(v)}
            md={2}
            maxLength={15}
            inputStyle={{
              textAlign: "center",
            }}
          />
          <FormButton color="info" onClick={repetirNumeroTodosProd}>
            Repetir em todos os produtos
          </FormButton>
        </Row>
        <ItensOrdemCompraModalGrid
          dados={itens}
          alterarOrdemCompraItem={alterarOrdemCompraItem}
        />
      </ModalBase>
    </>
  );
};

export default DadosOrdemCompraModal;
