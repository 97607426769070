import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AsyncComboBox,
  DatePicker,
  FixedField,
  FormButton,
  PageContainer,
  UnlockToEdit,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  init,
  setDataEmi,
  setDataEntrega,
  setIdCondPag,
  setIdFornecedor,
  setIdOrdemCompra,
  setItens,
  setNomeFornecedor,
  setup,
  setVlrTotal,
} from "./store/ordem_compra_slice";
import { storeOrdemCompra } from "./store";
import { IncluirProdutoOrdemCompraForm } from "./components/IncluirProdutoOrdemCompraForm";
import { OrdemCompraService } from "../../../../services/compras/OrdemCompraService";
import { OrdemCompraGrid } from "./components/OrdemCompraGrid";
import { formatNumber, roundFloat } from "../../../../coreUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showWarning } from "../../../../components/AlertaModal";
import moment from "moment";
import { ProdutosAbaixoEstMinModal } from "../components/ProdutosAbaixoEstMinModal";
import { SIGLA_TIPO_FILTROS_SUGESTAO_COMPRA } from "../gerar_sugestao/GerarSugestaoCompra";

const IncluirOrdemCompraContainer = forwardRef(({ location }, ref) => {
  const idAlterar = location?.state?.id_ordem_compra;
  const history = useHistory();
  const stateOrdemCompra = useSelector((state) => {
    return state.ordemCompra;
  });
  const dispatch = useDispatch();

  const refCondPag = useRef();
  const refProdEstMin = useRef();

  // Controle
  const semFornecedor = [0, null, undefined].includes(
    stateOrdemCompra.id_fornecedor
  );
  const semItens = stateOrdemCompra.itens.length === 0;
  const disabledSalvarFinalizar =
    semItens ||
    semFornecedor ||
    [0, null, undefined].includes(stateOrdemCompra.id_ordem_compra);

  let hintSalvarFinalizar;
  if (semItens) {
    hintSalvarFinalizar = "Inclua ao menos um item";
  } else if (semFornecedor) {
    hintSalvarFinalizar = "Informe o Fornecedor da Ordem de Compra";
  }

  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);

  const iniciarTela = async () => {
    let dadosCarregar = {};
    if (idAlterar) {
      const [ok, ret] = await OrdemCompraService.buscar(idAlterar);
      if (ok) {
        dadosCarregar.id_ordem_compra = idAlterar;
        dadosCarregar.data_emi = moment(ret.data_emi, "YYYY-MM-DD").toDate();
        dadosCarregar.id_fornecedor = ret.id_fornecedor;
        dadosCarregar.nome_fornecedor = ret.nome_fornecedor;
        dadosCarregar.id_cond_pag = ret.id_cond_pag;
        dadosCarregar.vlr_total = parseFloat(ret.vlr_total);
        dadosCarregar.itens = ret.itens;
        dadosCarregar.data_entrega = ret.data_entrega;
        if (dadosCarregar.data_entrega) {
          dadosCarregar.data_entrega = moment(
            dadosCarregar.data_entrega,
            "YYYY-MM-DD"
          ).toDate();
        }
      }
    }

    dispatch(setup(dadosCarregar));
  };

  const onActivate = () => {
    iniciarTela();
  };
  useEffect(onActivate, []);

  const buscarItens = async (idOrdemCompra) => {
    const [ok, ret] = await OrdemCompraService.buscar(idOrdemCompra);
    if (ok) {
      dispatch(setItens(ret.itens));
      dispatch(setVlrTotal(parseFloat(ret.vlr_total)));
    }
  };

  const verificarIncluirCab = async () => {
    if ([0, null, undefined].includes(stateOrdemCompra.id_ordem_compra)) {
      const payload = {
        data_emi: moment(stateOrdemCompra.data_emi).format("YYYY-MM-DD"),
        id_fornecedor: stateOrdemCompra.id_fornecedor,
        nome_fornecedor: stateOrdemCompra.nome_fornecedor ?? "",
        data_entrega: stateOrdemCompra.data_entrega
          ? moment(stateOrdemCompra.data_entrega).format("YYYY-MM-DD")
          : null,
      };
      const [ok, ret] = await OrdemCompraService.incluirCab(payload);
      if (ok) {
        dispatch(setIdOrdemCompra(ret.id_ordem_compra));
      }
      return ok ? ret.id_ordem_compra : null;
    } else {
      return stateOrdemCompra.id_ordem_compra;
    }
  };

  const incluirItem = async (payloadItem) => {
    const idOrdemCompra = await verificarIncluirCab();
    if (idOrdemCompra) {
      const payload = {
        id_cab: idOrdemCompra,
        ...payloadItem,
      };

      const [ok] = await OrdemCompraService.produtos.incluir(payload);
      if (ok) {
        buscarItens(idOrdemCompra);
      }
      return ok;
    } else {
      return false;
    }
  };

  const incluirMultiplosItens = async (produtos) => {
    const idOrdemCompra = await verificarIncluirCab();
    if (idOrdemCompra) {
      const payload = {
        id_cab: idOrdemCompra,
        produtos: produtos.map((e) => ({
          id_produto: e.id_produto,
          quantidade: e.qtd_compra,
        })),
      };

      const [ok] = await OrdemCompraService.produtos.incluirMultiplos(payload);
      if (ok) {
        buscarItens(idOrdemCompra);
      }
      return ok;
    } else {
      return false;
    }
  };

  const recalcularValoresGrade = async (coluna, novoValor, row) => {
    const casasDecTest =
      coluna === "vlr_item_compra"
        ? row.qtd_casas_decimais_vlrs
        : row.qtd_casas_decimais_qtd;

    const novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);

    if (novoValorTest === valorAntesTest) {
      return false;
    }

    if (coluna === "quantidade") {
      const quantidade = parseFloat(novoValor);
      const vlrItem = parseFloat(row.vlr_item_compra);

      const vlrTotProd = roundFloat(vlrItem * quantidade, 2);
      row.quantidade = quantidade;
      row.vlr_total_compra = vlrTotProd;
    } else if (coluna === "vlr_item_compra") {
      const quantidade = parseFloat(row.quantidade);
      const vlrItem = parseFloat(novoValor);

      const vlrTotProd = roundFloat(vlrItem * quantidade, 2);
      row.vlr_item_compra = vlrItem;
      row.vlr_total_compra = vlrTotProd;
    }

    setAtualizarValoresItens(true);
    dispatch(
      setItens(
        stateOrdemCompra.itens.map((element) => {
          if (element.id === row.id) {
            return { ...row };
          } else {
            return element;
          }
        })
      )
    );
  };

  const atualizarItem = async (row) => {
    const payload = {
      id_item: row.id,
      quantidade: parseFloat(row.quantidade ?? "0") ?? 0,
      vlr_item_compra: parseFloat(row.vlr_item_compra ?? "0") ?? 0,
      vlr_total_compra: parseFloat(row.vlr_total_compra ?? "0") ?? 0,
    };

    const [ok] = await OrdemCompraService.produtos.alterar(payload);
    return ok;
  };

  const atualizarDadosItemGrid = async (row) => {
    if (atualizarValoresItens) {
      await atualizarItem(row);
      buscarItens(stateOrdemCompra.id_ordem_compra);
    }
  };

  const excluirProduto = async (idItem) => {
    const [ok] = await OrdemCompraService.produtos.excluir(idItem);
    if (ok) {
      buscarItens(stateOrdemCompra.id_ordem_compra);
    }
  };

  const handleSalvar = async () => {
    setLoadingSalvar(true);
    try {
      const payload = {
        id_ordem_compra: stateOrdemCompra.id_ordem_compra,
        data_emi: moment(stateOrdemCompra.data_emi).format("YYYY-MM-DD"),
        id_fornecedor: stateOrdemCompra.id_fornecedor,
        nome_fornecedor: stateOrdemCompra.nome_fornecedor,
        id_cond_pag: stateOrdemCompra.id_cond_pag ?? null,
        data_entrega: stateOrdemCompra.data_entrega
          ? moment(stateOrdemCompra.data_entrega).format("YYYY-MM-DD")
          : null,
      };
      await OrdemCompraService.salvar(payload);
    } finally {
      setLoadingSalvar(false);
    }
  };

  const handleFinalizar = async () => {
    if (disabledSalvarFinalizar) return;

    setLoadingFinalizar(true);
    try {
      if ([0, null, undefined].includes(stateOrdemCompra.id_fornecedor)) {
        showWarning(
          "Por favor, verifique o campo do Fornecedor e tente novamente"
        );
        return;
      }

      const payload = {
        id_ordem_compra: stateOrdemCompra.id_ordem_compra,
        data_emi: moment(stateOrdemCompra.data_emi).format("YYYY-MM-DD"),
        id_fornecedor: stateOrdemCompra.id_fornecedor,
        nome_fornecedor: stateOrdemCompra.nome_fornecedor,
        id_cond_pag: stateOrdemCompra.id_cond_pag ?? null,
        data_entrega: stateOrdemCompra.data_entrega
          ? moment(stateOrdemCompra.data_entrega).format("YYYY-MM-DD")
          : null,
      };
      const [ok] = await OrdemCompraService.finalizar(payload);
      if (ok) {
        OrdemCompraService.imprimir(stateOrdemCompra.id_ordem_compra);
        dispatch(init());
        history.goBack();
      }
    } finally {
      setLoadingFinalizar(false);
    }
  };

  const formatarMultifilterFornec = () => {
    if (![0, null, undefined].includes(stateOrdemCompra.id_fornecedor)) {
      return [
        {
          tipo: SIGLA_TIPO_FILTROS_SUGESTAO_COMPRA.fornecedor,
          id_filtro: stateOrdemCompra.id_fornecedor,
        },
      ];
    } else {
      return [];
    }
  };

  const atualizarContagemEstMin = () => {
    if (refProdEstMin.current) {
      refProdEstMin.current.atualizarContagem();
    }
  };

  useEffect(atualizarContagemEstMin, [stateOrdemCompra.id_fornecedor]);

  useImperativeHandle(ref, () => ({
    reset: () => dispatch(init()),
    continuar: () => handleFinalizar(),
  }));

  useEffect(() => {
    if ([0, null, undefined].includes(stateOrdemCompra.id_cond_pag)) {
      if (refCondPag.current) {
        refCondPag.current.clearValue();
      }
    }
  }, [stateOrdemCompra.id_cond_pag]);

  return (
    <>
      <Card body>
        <Row>
          <AsyncComboBox
            label="Fornecedor"
            md={6}
            concatModelName="fornecedor"
            onChange={(s) => {
              dispatch(setIdFornecedor(s?.value));
              dispatch(setNomeFornecedor(s?.label));
              dispatch(setIdCondPag(s?.id_cond_pag));
            }}
            autoFocus
            defaultValue={stateOrdemCompra.id_fornecedor}
          />
          <UnlockToEdit>
            <DatePicker
              md={2}
              label="Emissão"
              value={stateOrdemCompra.data_emi}
              onChange={(v) =>
                dispatch(setDataEmi(moment.isMoment(v) ? v.toDate() : v))
              }
              style={{ textAlign: "center" }}
            />
          </UnlockToEdit>
        </Row>
        <IncluirProdutoOrdemCompraForm
          semFornecedor={semFornecedor}
          incluirItem={incluirItem}
        />
      </Card>
      <Card body>
        <OrdemCompraGrid
          dados={stateOrdemCompra.itens}
          excluirItem={excluirProduto}
          recalcularValoresGrade={recalcularValoresGrade}
          atualizarDadosItem={atualizarDadosItemGrid}
        />
      </Card>
      <Card body>
        <Row>
          <AsyncComboBox
            label="Condição de Pagamento"
            md={3}
            concatModelName="cond_pag"
            onChange={(s) => {
              dispatch(setIdCondPag(s?.value));
            }}
            defaultValue={stateOrdemCompra.id_cond_pag}
            defaultOptions
            ref={refCondPag}
          />
          <DatePicker
            md={2}
            label="Entrega"
            value={stateOrdemCompra.data_entrega}
            onChange={(v) =>
              dispatch(setDataEntrega(moment.isMoment(v) ? v.toDate() : v))
            }
            style={{ textAlign: "center" }}
          />
          <ProdutosAbaixoEstMinModal
            formatarMultifilter={formatarMultifilterFornec}
            handleSubmit={incluirMultiplosItens}
            disabled={[0, null, undefined].includes(
              stateOrdemCompra.id_fornecedor
            )}
            disabledHint="Informe o Fornecedor"
            ref={refProdEstMin}
          />
          <FixedField
            divClassName="ml-auto"
            labelStyle={{ fontSize: "1.2rem", fontWeight: "bold" }}
            label="Valor Total"
            value={formatNumber(stateOrdemCompra.vlr_total, true, 2)}
            labelClassName="mb-2 mt-auto"
            horizontal
            verticallyCentered
            boldValue
          />
          <FormButton
            md="auto"
            color="info"
            onClick={handleSalvar}
            loading={loadingSalvar}
            disabled={disabledSalvarFinalizar}
            disabledHint={hintSalvarFinalizar}
          >
            Salvar
          </FormButton>
          <FormButton
            md="auto"
            color="success"
            onClick={handleFinalizar}
            disabled={disabledSalvarFinalizar}
            disabledHint={hintSalvarFinalizar}
            loading={loadingFinalizar}
          >
            F9 - Confirmar
          </FormButton>
        </Row>
      </Card>
    </>
  );
});

export const IncluirOrdemCompra = ({ location }) => {
  const refContainer = useRef();

  const onKeyDown = (e) => {
    if (refContainer.current) {
      if (e.key === "F9") {
        refContainer.current.continuar();
      }
    }
  };

  return (
    <PageContainer
      title="Incluir Ordem de Compra"
      number="0016_2"
      onBeforeGoingBack={() => {
        if (refContainer.current) {
          refContainer.current.reset();
        }
      }}
      formClassName="pb-0"
      onKeyDown={onKeyDown}
    >
      <Provider store={storeOrdemCompra}>
        <IncluirOrdemCompraContainer ref={refContainer} location={location} />
      </Provider>
    </PageContainer>
  );
};
