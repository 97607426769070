import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { TableCheck } from "../../../../../../../components/TableCheck";
import { formatNumber } from "../../../../../../../coreUtils";
import { Table } from "../../../../../../../components";

export const ItensEntregaPedidoExtGrid = ({
  data,
  onChangeQtdEntrega,
  verificarQtdEntrega,
  identificaProdutoRevenda,
}) => {
  const _columns = [
    {
      dataField: "tipo_item",
      text: "Tipo",
      align: "center",
    },
    {
      dataField: "id_tipo",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      formatter: (c, row) => (
        <>
          {c}
          {row.tem_pesagem && (
            <Badge
              color="attention"
              className="ml-1"
              style={{ transform: "translateY(-1px)" }}
            >
              Pesagem
            </Badge>
          )}
        </>
      ),
    },
    {
      dataField: "revenda",
      text: "Revenda",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      hidden: !identificaProdutoRevenda,
    },
    {
      dataField: "peso_unit_bruto",
      text: "Peso Bruto",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "peso_unit_liquido",
      text: "Peso Líq",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_final",
      text: "V. Item",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "V. Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_entregue",
      text: "Qt. Entregue",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_entregue",
      text: "V. Entregue",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_saldo_dis",
      text: "Qt. Restante",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_pend_entrega",
      text: "V. Restante",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_entregar",
      text: "Qt. Entregar",
      align: "center",
      headerStyle: { width: "12%" },
      editable: (c, row) => parseFloat(row.qt_saldo_dis) > 0,
      formatter: (c, row) =>
        parseFloat(row.qt_saldo_dis) > 0 ? (
          formatNumber(c, true, 2, true)
        ) : (
          <>
            <div id={`item-ped-${row.id}`}>{c}</div>
            <UncontrolledTooltip target={`item-ped-${row.id}`}>
              O item foi totalmente entregue
            </UncontrolledTooltip>
          </>
        ),
      onChange: onChangeQtdEntrega,
      onBlur: verificarQtdEntrega,
      editorType: "number",
      decimalPlaces: 4,
      alwaysShowEditor: true,
    },
  ];

  return (
    <Table
      columns={_columns}
      data={data}
      fixedSize={false}
      showRegisterCount={false}
      pageSize={(data ?? []).length}
    />
  );
};
