import React from "react";
import {
  ColorField,
  IconButton,
  MoreDropdown,
  Table,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { BsPrinter } from "react-icons/bs";

import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";
import { DropdownItem } from "reactstrap";

export const coresStatus = [
  { value: "P", hint: "Aberto", color: "#00d500" },
  { value: "C", hint: "Aprovado Parcialmente", color: "#BCE300" },
  { value: "A", hint: "Aprovado Totalmente", color: "#3E9900" },
  { value: "N", hint: "Rejeitado/Não Aprovado", color: "#FF0D0D" },
  { value: "I", hint: "Em Digitação", color: "#CF369A" },
  { value: "TV", hint: "Transformado em Venda", color: "#0080FF" },
];

export const GerencOrcamentosGrid = ({
  dados,
  setSelected,
  rejeitar,
  detalhes,
  excluir,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "nome_vendedor",
      text: "Vendedor",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_hora_emi",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_desc",
      text: "Desconto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_frete",
      text: "Frete",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "situacao",
      text: "Situação",
      align: "center",
      headerAlign: "center",
      style: { verticalAlign: "middle", padding: 0 },
      formatter: (c, row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ColorField
            scheme={coresStatus}
            value={row.status}
            showHint={false}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginLeft: "0.4rem",
              marginRight: "0.4rem",
            }}
          />
          {c}
        </div>
      ),
    },
    {
      dataField: "print",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <IconButton
          icon={BsPrinter}
          onClick={() => OrcamentosService.imprimir(row.id)}
          hint="Imprimir Orçamento"
          tooltipPlacement="left"
        />
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => detalhes(row.id)}>Detalhes</DropdownItem>
          {row.aberto && (
            <DropdownItem onClick={() => rejeitar(row.id)}>
              Rejeitar
            </DropdownItem>
          )}
          <DropdownItem divider />
          <DropdownItem onClick={() => excluir(row.id)}>Excluir</DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return <Table data={dados} columns={columns} onSelect={setSelected} />;
};
