import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import {
  setFormaImpressao,
  setLayoutImpr,
  setMsgFixa_1,
  setMsgFixa_2,
  setMsgFixa_3,
  setMsgFixa_4,
  setEcfNroVias,
  setNomeImpressora,
  setImprimirValePorQtd,
  setUtilizaVendExterno,
  setUtilizaCashback,
  setSenhaPorColaborador,
  setIdentificaVendedorTecnServ,
  setSolicSenhaReimprVenda,
  setConsideraLimiteCredCadCli,
  setIdPlanoContaImportacaoBroker,
  setIdCentroCustoImportacaoBroker,
  setIdOrganizacaoImportacaoBroker,
  setIdCfopValePresente,
  setIdProdutoValePresente,
} from "../store/venda_slice";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  HintLabel,
  IntegerFormInput,
  PesqPlanoCtaCentroCusto,
  TextInput,
} from "../../../../components";
import { RadioGroup, RadioItem } from "../../../../components/RadioGroup";

const layoutsImprVenda = [
  {
    label:
      "Modelo 1 - Folha A4 - 1 via; Considera Ambiente; Produto, Nome Produto, UN, Qtde, Valor Item, Desconto, Total",
    value: 1,
  },
  {
    label:
      "Modelo 2 - Folha A4 - 1 via; Quantidade, Nome item, Referência, Un, Valor item, Valor total",
    value: 2,
  },
  { label: "Modelo 3 - Folha A4 - 1 via;", value: 3 },
  {
    label:
      "Modelo 5 - Folha A4 - 2 vias; Quantidade, Nome item, Un, Valor item, Valor total",
    value: 5,
  },
  {
    label: "Modelo 6 - Folha A4 - 1 via; Informações complementares do cliente",
    value: 6,
  },
  {
    label:
      "Modelo 7 - Folha A4 - 1 Via; Título Venda; Quantidade, Nome item, Un, Valor item, Valor total ",
    value: 7,
  },
  { label: "Modelo 8 - Postgres - Folha A4 - 1 via;", value: 8 },
  {
    label: "Modelo 9 - Folha A4 - 1 via; Informações de pagamento",
    value: 9,
  },
  {
    label: "Modelo 13 - Folha A4; Sempre aparece local de entrega",
    value: 13,
  },
  {
    label: "Modelo 14 - Folha A4 - Inclui valores originais (sem desconto)",
    value: 14,
  },
  { label: "Modelo 100 - Mini impressora não fiscal", value: 100 },
  {
    label:
      "Modelo 101 - Mini impressora não fiscal; Sem Informações da Empresa e Vendedor",
    value: 101,
  },
  { label: "Modelo 102 - Mini impressora não fiscal PDV", value: 102 },
  {
    label:
      "Modelo 104 - Mini impressora não fiscal; Com Info Empresa e Avanço maior na margem a Esquerda",
    value: 104,
  },
  {
    label: "Modelo 105 - Mini impressora não fiscal PDV com desconto",
    value: 105,
  },
  {
    label:
      "Modelo 106 - Mini impressora não fiscal PDV com descrição mais completa",
    value: 106,
  },
  { label: "Modelo 107 - Mini impressora 72mm", value: 107 },
  {
    label:
      "Modelo 108 - Mini impressora 80mm - Imprime produtos com complementos",
    value: 108,
  },
  {
    label:
      "Modelo 109 - Mini impressora - Bobina Menor - 57.5mm área de impressão 48mm",
    value: 109,
  },
  {
    label: "Modelo 110 - Mini impressora não fiscal; Com Assinatura do Cliente",
    value: 110,
  },
  {
    label:
      "Modelo 111 - Mini impressora não fiscal; Igual ao 106, com Formas de Pagamento",
    value: 111,
  },
  {
    label:
      "Modelo 112 - Mini impressora não fiscal; Igual ao 110, com a NCM do Produto",
    value: 112,
  },
  { label: "Modelo 200 - Matricial", value: 200 },
  {
    label:
      "Modelo 201 - Matricial - Cotação de Preços; Imprime Referência e Valor Item",
    value: 201,
  },
];

export const PersonVenda = () => {
  const store = useSelector((state) => state.venda);
  const dispatch = useDispatch();

  const miniImpressora = [
    100, 101, 102, 104, 105, 106, 107, 108, 109, 110, 111,
  ].includes(store.layout_impr);

  const onChangeLayoutImpr = () => {
    if (!miniImpressora) {
      dispatch(setNomeImpressora(""));
      dispatch(setEcfNroVias(1));
    }
  };
  useEffect(onChangeLayoutImpr, [store.layout_impr]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Forma Impressao"
          value={store.forma_impressao}
          onChange={(v) => dispatch(setFormaImpressao(v))}
        >
          <RadioItem label="Sempre Imprimir" value="IMP" />
          <RadioItem label="Visualizar" value="VIS" />
          <RadioItem label="Perguntar se Deseja Imprmir" value="PER" />
          <RadioItem label="Não Imprimir" value="NAO" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={8}
          label="Layout de Impressão"
          options={layoutsImprVenda}
          defaultValue={store.layout_impr}
          onChange={(s) => dispatch(setLayoutImpr(s?.value))}
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Nome da Impressora"
          value={store.nome_impressora}
          onChange={(e, v) => dispatch(setNomeImpressora(v))}
          maxLength={30}
          forceUppercase={false}
          disabled={!miniImpressora}
        />
        <IntegerFormInput
          md={4}
          label="Número de Vias da Impressão da Venda"
          defaultValue={store.ecf_nro_vias}
          onChange={(v) => dispatch(setEcfNroVias(v))}
          disabled={!miniImpressora}
          mdInput={6}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gerar Vales por Quantidade"
          checked={store.imprimir_vale_por_qtd}
          onChange={() =>
            dispatch(setImprimirValePorQtd(!store.imprimir_vale_por_qtd))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Vendedor Externo"
          checked={store.utiliza_vend_externo}
          onChange={() =>
            dispatch(setUtilizaVendExterno(!store.utiliza_vend_externo))
          }
        />
      </Row>
      <Row>
        <Col className="no-gutters">
          <TextInput
            label="Mensagens Fixas"
            md={10}
            value={store.msg_fixa_1}
            onChange={(e, v) => dispatch(setMsgFixa_1(v))}
            maxLength={180}
          />
          <TextInput
            md={10}
            value={store.msg_fixa_2}
            onChange={(e, v) => dispatch(setMsgFixa_2(v))}
            maxLength={180}
          />
          <TextInput
            md={10}
            value={store.msg_fixa_3}
            onChange={(e, v) => dispatch(setMsgFixa_3(v))}
            maxLength={180}
          />
          <TextInput
            md={10}
            value={store.msg_fixa_4}
            onChange={(e, v) => dispatch(setMsgFixa_4(v))}
            maxLength={180}
          />
        </Col>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Cashback"
          checked={store.utiliza_cashback}
          onChange={() => dispatch(setUtilizaCashback(!store.utiliza_cashback))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Vendedor Técnico de Serviços"
          checked={store.identifica_vendedor_tecn_serv}
          onChange={() =>
            dispatch(
              setIdentificaVendedorTecnServ(
                !store.identifica_vendedor_tecn_serv
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Senha Por Colaborador"
          checked={store.senha_por_colaborador}
          onChange={() =>
            dispatch(setSenhaPorColaborador(!store.senha_por_colaborador))
          }
        />
        <Col className="no-gutters">
          <Label className="pb-0" md={12}>
            Se esta opção estiver marcada, permite identificar quem foi o
            responsável por determinada liberação, pois a senha será única para
            cada colaborador;
          </Label>
          <Label className="py-0" md={12}>
            Se estiver desmarcada, as senhas serão universais para todo o
            sistema, não permitindo a idenificação do responsável pela
            liberação.
          </Label>
        </Col>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Solicitar Senha para Reimpressão de Venda"
          checked={store.solic_senha_reimpr_venda}
          onChange={() =>
            dispatch(setSolicSenhaReimprVenda(!store.solic_senha_reimpr_venda))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label={
            <HintLabel
              label="Considerar Limites de Crediário e Cheque do Cadastro de Cliente e Bloquear Venda"
              hintText="Os Limites são definidos no Cadastro de Cliente, em Informações Adicionais > Limites"
            />
          }
          value={store.considera_limite_cred_cad_cli}
          onChange={(v) => dispatch(setConsideraLimiteCredCadCli(v))}
        >
          <RadioItem label="Não Considerar" value="NAO" />
          <RadioItem label="Considerar se for Maior que Zero" value="SEH" />
          <RadioItem label="Sempre Considerar" value="SIM" />
        </RadioGroup>
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Organização Padrão para Importação de Vendas Broker"
          concatModelName="organizacao"
          defaultValue={store.id_organizacao_importacao_broker}
          onChange={(s) =>
            dispatch(setIdOrganizacaoImportacaoBroker(s?.value ?? null))
          }
          defaultOptions
          isClearable
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          mode="plano_contas"
          label="Plano de Contas para Importação de Vendas Broker"
          value={store.id_plano_conta_importacao_broker}
          onChange={(v) => dispatch(setIdPlanoContaImportacaoBroker(v ?? null))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          mode="centro_custo"
          label="Centro de Custo para Importação de Vendas Broker"
          value={store.id_centro_custo_importacao_broker}
          onChange={(v) =>
            dispatch(setIdCentroCustoImportacaoBroker(v ?? null))
          }
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          concatModelName="produto"
          label="Produto para emissão de NFC-e do Vale Presente"
          defaultValue={store.id_produto_vale_presente}
          onChange={(s) => dispatch(setIdProdutoValePresente(s?.value ?? null))}
        />
        <AsyncComboBox
          md={5}
          concatModelName="cfop"
          label="CFOP para emissão de NFC-e do Vale Presente"
          defaultValue={store.id_cfop_vale_presente}
          onChange={(s) => dispatch(setIdCfopValePresente(s?.value ?? null))}
          defaultOptions
        />
      </Row>
    </>
  );
};
