import React, { useState } from "react";
import { Col, Label, Row } from "reactstrap";
import {
  DragAndDropArquivos,
  FormButton,
  HintLabel,
  MaskedInput,
  PasswordInput,
  TextInput,
} from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { ModalGerarAuthCode } from "./ModalGerarAuthCode";
import {
  setApiAuthorizationCode,
  setApiClientId,
  setApiClientSecret,
  setApiPassword,
  setApiUsername,
  setArqCertificadoApi,
  setArqKeyApi,
  setCodCedente,
  setEspecieTit,
  setPosto,
} from "../store/cadastroContaBancSlice";
import { BsX } from "react-icons/bs";
import { hintsEspecieTitulo } from "./HintsCampos";

const rowClassName = "mb-2";

const DadosIntegracaoApiAuthCode = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);

  const [modalGerarAuthCodeIsOpen, setModalGerarAuthCodeIsOpen] =
    useState(false);

  const toggleModalGerarAuthorizationCode = () =>
    setModalGerarAuthCodeIsOpen(!modalGerarAuthCodeIsOpen);

  return (
    <>
      <Row className={rowClassName}>
        <FormButton
          color="warning"
          md={4}
          onClick={toggleModalGerarAuthorizationCode}
        >
          Gerar Authorization Code
        </FormButton>
        <TextInput
          label="Authorization Code"
          md={6}
          value={store.api_authorization_code}
          disabled
          forceUppercase={false}
        />
      </Row>
      <ModalGerarAuthCode
        toggle={toggleModalGerarAuthorizationCode}
        isOpen={modalGerarAuthCodeIsOpen}
      />
    </>
  );
};

const DadosIntegracaoApiAccessKey = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const dispatch = useDispatch();

  return (
    <Row className={rowClassName}>
      <TextInput
        label="Chave de Acesso API"
        md={6}
        value={store.api_authorization_code}
        onChange={(e, v) => {
          dispatch(setApiAuthorizationCode(v));
        }}
        forceUppercase={false}
      />
    </Row>
  );
};

const DragDropCert = ({
  certificado,
  handleSetCertificado,
  certificadoConfigurado,
  descCampo,
  accept = "*",
}) => (
  <>
    {certificado ? (
      <Col md="auto">
        <h5 className="mb-1">{descCampo}:</h5>
        <span>
          <strong>{certificado?.file?.name}</strong>
          <BsX
            size={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleSetCertificado(null)}
          />
        </span>
      </Col>
    ) : (
      <DragAndDropArquivos
        md="auto"
        divClassName="no-gutters"
        text={
          !certificadoConfigurado ? (
            <>Selecionar {descCampo}</>
          ) : (
            <>Atualizar {descCampo}</>
          )
        }
        onDrop={handleSetCertificado}
        accept={accept}
        style={{
          padding: "0.5rem 1rem",
          marginTop: "0",
          color: "black",
          cursor: "pointer",
          borderColor: "#ddd",
          marginLeft: "2px",
        }}
        textStyle={{
          marginTop: "0",
          marginBottom: "0",
          color: "white",
        }}
      />
    )}
  </>
);

const DadosIntegracaoApiMTLS = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const storeRoot = useSelector((state) => state.cadastroContaBanc);
  const dispatch = useDispatch();

  const certificadoConfigurado = storeRoot.api_certif_config;
  const chaveConfigurada = storeRoot.api_key_config;

  return (
    <>
      <Row className={rowClassName}>
        <TextInput
          label="Client ID da Integração"
          md={6}
          value={store.api_client_id}
          onChange={(e, v) => {
            dispatch(setApiClientId(v));
          }}
          forceUppercase={false}
        />
        {store.layout !== "SIB" && (
          <PasswordInput
            label="Client Secret da Integração"
            md={6}
            value={store.api_client_secret}
            onChange={(v) => dispatch(setApiClientSecret(v))}
          />
        )}
      </Row>
      {store.layout !== "SIB" && (
        <Row className={rowClassName}>
          <Col md="6">
            <Row>
              <Col md="auto" style={{ display: "flex", paddingRight: "0px" }}>
                <Row className="no-gutters">
                  <Label
                    className="mb-0 no-gutters"
                    style={{
                      fontWeight: "bold",
                      color: certificadoConfigurado ? "blue" : "#495057",
                      alignSelf: "center",
                      margin: "0 !important",
                      padding: "0px",
                    }}
                  >
                    {certificadoConfigurado
                      ? "Certificado Configurado"
                      : "Importe o Certificado da Integração"}
                  </Label>
                </Row>
              </Col>

              <DragDropCert
                certificado={storeRoot.arq_certificado_api}
                handleSetCertificado={(f) => {
                  if (f === null) {
                    dispatch(setArqCertificadoApi(null));
                    return;
                  }
                  var reader = new FileReader();

                  reader.addEventListener("load", async function (e) {
                    const res = {
                      file: f,
                      base64: e.target?.result,
                    };

                    dispatch(setArqCertificadoApi(res));
                  });

                  reader.readAsDataURL(f);
                }}
                certificadoConfigurado={certificadoConfigurado}
                descCampo="Certificado"
                accept=".crt"
              />
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md="auto" style={{ display: "flex", paddingRight: "0px" }}>
                <Row className="no-gutters">
                  <Label
                    className="mb-0 no-gutters"
                    style={{
                      fontWeight: "bold",
                      color: chaveConfigurada ? "blue" : "#495057",
                      alignSelf: "center",
                      margin: "0 !important",
                      padding: "0px",
                    }}
                  >
                    {chaveConfigurada
                      ? "Chave Configurada"
                      : "Importe a Chave da Integração"}
                  </Label>
                </Row>
              </Col>
              <DragDropCert
                certificado={storeRoot.arq_key_api}
                handleSetCertificado={(f) => {
                  if (f === null) {
                    dispatch(setArqKeyApi(null));
                    return;
                  }
                  var reader = new FileReader();

                  reader.addEventListener("load", async function (e) {
                    const res = {
                      file: f,
                      base64: e.target?.result,
                    };

                    dispatch(setArqKeyApi(res));
                  });

                  reader.readAsDataURL(f);
                }}
                certificadoConfigurado={chaveConfigurada}
                descCampo="Chave"
                accept=".key"
              />
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const DadosIntegracaoApiUsername = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const dispatch = useDispatch();

  return (
    <>
      <Row className={rowClassName}>
        <TextInput
          label="Client ID"
          md={6}
          value={store.api_client_id}
          onChange={(e, v) => {
            dispatch(setApiClientId(v));
          }}
          forceUppercase={false}
        />
      </Row>
      <Row className={rowClassName}>
        <TextInput
          label="Usuário de Acesso a API"
          md={6}
          value={store.api_username}
          onChange={(e, v) => {
            dispatch(setApiUsername(v));
          }}
          forceUppercase={false}
        />
        <TextInput
          label="Senha de Acesso a API"
          md={6}
          value={store.api_password}
          onChange={(e, v) => {
            dispatch(setApiPassword(v));
          }}
          forceUppercase={false}
        />
      </Row>
    </>
  );
};

export const DadosCobrancaAPI = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const dispatch = useDispatch();

  switch (store.layout) {
    case "BTG":
      return <DadosIntegracaoApiAuthCode />;
    case "ASA":
      return <DadosIntegracaoApiAccessKey />;
    case "INT":
    case "SIB":
      return (
        <>
          <DadosIntegracaoApiMTLS />
          {store.layout === "SIB" && (
            <Row className={rowClassName}>
              <MaskedInput
                label={
                  <HintLabel
                    label="Código do Cedente"
                    hintText={"Informar Acessório Escritural"}
                    enabled={store.layout === "BRA"}
                  />
                }
                md={3}
                onChange={(e, v) => dispatch(setCodCedente(v))}
                value={store.cod_cedente}
                placeholder={store.layout === "BRA" && "Acessório Escritural"}
                mask="999999999999999"
                maskChar={null}
              />
              <MaskedInput
                md={2}
                label={
                  <HintLabel
                    label="Espécie do Título"
                    hintText={hintsEspecieTitulo(store.layout)}
                    tooltipPlacement="right"
                  />
                }
                value={store.especie_tit}
                onChange={(e, v) => dispatch(setEspecieTit(v))}
                mask={store.layout === "SIC" ? "a" : "99"}
                maskChar={null}
              />
            </Row>
          )}
        </>
      );
    case "SAF":
    case "SIC":
      return (
        <>
          <DadosIntegracaoApiUsername />
          <Row className={rowClassName}>
            {store.layout === "SIC" && (
              <>
                <MaskedInput
                  label={
                    <HintLabel
                      label="Código do Cedente"
                      hintText={"Informar Acessório Escritural"}
                      enabled={store.layout === "BRA"}
                    />
                  }
                  md={3}
                  onChange={(e, v) => dispatch(setCodCedente(v))}
                  value={store.cod_cedente}
                  placeholder={store.layout === "BRA" && "Acessório Escritural"}
                  mask="999999999999999"
                  maskChar={null}
                />
                <MaskedInput
                  md={2}
                  label="Posto"
                  value={store.posto}
                  onChange={(e, v) => dispatch(setPosto(v))}
                  mask="99999"
                  maskChar={null}
                />
              </>
            )}
            <MaskedInput
              md={2}
              label={
                <HintLabel
                  label="Espécie do Título"
                  hintText={hintsEspecieTitulo(store.layout)}
                  tooltipPlacement="right"
                />
              }
              value={store.especie_tit}
              onChange={(e, v) => dispatch(setEspecieTit(v))}
              mask={store.layout === "SIC" ? "a" : "99"}
              maskChar={null}
            />
          </Row>
        </>
      );
    default:
      return <></>;
  }
};
