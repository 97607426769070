import { Truck } from "react-feather";
import { IncluirAlterarPedidoExt } from "../../pages/pedidos/pedido_venda/pedidos_externos/central_ped_externo/incluir_alterar/IncluirAlterarPedidoExt";
import { CentralPedExterno } from "../../pages/pedidos/pedido_venda/pedidos_externos/central_ped_externo/CentralPedExterno";
import { EntregarPedidoExt } from "../../pages/pedidos/pedido_venda/pedidos_externos/central_ped_externo/entregar_pedido/EntregarPedidoExt";
import { ConferenciaDinheiro } from "../../pages/pedidos/pedido_venda/pedidos_externos/conferencia_dinheiro/ConferenciaDinheiro";
import { RomaneiosRealizados } from "../../pages/pedidos/pedido_venda/romaneio/realizados/RomaneiosRealizados";
import { CentralRomaneio } from "../../pages/pedidos/pedido_venda/romaneio/central_de_romaneios/CentralRomaneio";
import { FlexRepresentExt } from "../../pages/pedidos/pedido_venda/pedidos_externos/flex_pedido_externo/FlexRepresentExt";
import { CentralPedInterno } from "../../pages/pedidos/pedido_venda/pedidos_internos/central_ped_interno/CentralPedInterno";
import { EntregarPedidoInterno } from "../../pages/pedidos/pedido_venda/pedidos_internos/central_ped_interno/components/EntregarPedidoInterno";
import { PedidoVenda } from "../../pages/pedidos/pedido_venda/PedidoVenda";

export const centralPedInternoRoute = {
  path: "/pedidos_venda/pedidos_internos/central",
  name: "Central de Pedidos Internos",
  id: "AcSkWeb0100",
  nroTela: "0100",
  component: CentralPedInterno,
};

export const entregarPedidoInternoRoute = {
  path: "/pedidos_venda/pedidos_internos/central/entregar_pedido/",
  name: "Entrega de Pedidos Internos",
  component: EntregarPedidoInterno,
};

export const pedidosInternosRoutes = [centralPedInternoRoute];

export const entregarPedidoRoute = {
  path: "/pedidos_venda/pedidos_externos/central_de_pedidos/entregar",
  name: "Entregar Pedido Externo",
  component: EntregarPedidoExt,
};

export const incluirAlterarPedidoRoute = {
  path: "/pedidos_venda/pedidos_externos/central_de_pedidos/pedido",
  name: "Pedido Externo",
  component: IncluirAlterarPedidoExt,
};

export const centralRomaneioRoute = {
  path: "/pedidos_venda/romaneio/central_romaneios",
  name: "Central de Romaneios",
  id: "AcSkWeb0048",
  nroTela: "0048",
  component: CentralRomaneio,
};

export const romaneioRealizadosRoute = {
  path: "/pedidos_venda/romaneio/romaneios_realizados",
  name: "Romaneios Realizados",
  id: "AcSkWeb0071",
  nroTela: "0071",
  component: RomaneiosRealizados,
};

export const romaneioRoutes = [centralRomaneioRoute, romaneioRealizadosRoute];

export const centralPedidosRoute = {
  path: "/pedidos_venda/pedidos_externos/central_de_pedidos",
  name: "Central de Pedidos Externos",
  id: "AcSkWeb0034",
  nroTela: "0034",
  component: CentralPedExterno,
};

export const pedidosConferenciaValoresRecebidosEmMaosRoute = {
  path: "/pedidos_venda/pedidos_externos/conferencia_valores_recebidos_em_mao",
  name: "Conferência de Valores Recebidos em Mãos",
  id: "AcSkWeb0054",
  nroTela: "0054",
  component: ConferenciaDinheiro,
};

export const pedidosFlexRepresentantesRoute = {
  path: "/pedidos_venda/pedidos_externos/flex_represent_ext",
  name: "Flex de Representantes",
  id: "AcSkWeb0072",
  nroTela: "0072",
  component: FlexRepresentExt,
};

export const pedidosExternosRoutes = [
  centralPedidosRoute,
  pedidosConferenciaValoresRecebidosEmMaosRoute,
  pedidosFlexRepresentantesRoute,
];

const pedidosRoutes = {
  path: "/pedidos_venda",
  name: "Pedidos de Venda",
  icon: Truck,
  component: PedidoVenda,
  canGoBack: false,
};

export default pedidosRoutes;
