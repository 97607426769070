import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { ProdServIcone } from "../../../../../../components/ProdServIcone";
import { formatNumber } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

export const ItensEntregaPedidoInternoGrid = ({
  data,
  onChangeQtdEntrega,
  verificarQtdEntrega,
}) => {
  const _columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "30px",
      cellContentTag: null,
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "id_prod_serv",
      text: "Produto/Serviço",
      align: "center",
    },
    {
      dataField: "nome_prod_serv",
      text: "Descrição",
      align: "left",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_item",
      text: "V. Item",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "V. Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_entregue",
      text: "Qt. Entregue",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_entregue",
      text: "V. Entregue",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_saldo_dis",
      text: "Qt. Restante",
      align: "center",
      formatter: (c) => formatNumber(c, true, 4, true),
    },
    {
      dataField: "vlr_pend_entrega",
      text: "V. Restante",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qt_entregar",
      text: "Qt. Entregar",
      align: "center",
      headerStyle: { width: "12%" },
      editable: (c, row) => parseFloat(row.qt_saldo_dis) > 0,
      formatter: (c, row) =>
        parseFloat(row.qt_saldo_dis) > 0 ? (
          formatNumber(c, true, 2, true)
        ) : (
          <>
            <div id={`item-ped-${row.id}`}>{c}</div>
            <UncontrolledTooltip target={`item-ped-${row.id}`}>
              O item foi totalmente entregue
            </UncontrolledTooltip>
          </>
        ),
      onChange: onChangeQtdEntrega,
      onBlur: verificarQtdEntrega,
      editorType: "number",
      decimalPlaces: 4,
      alwaysShowEditor: true,
    },
  ];

  return (
    <Table
      columns={_columns}
      data={data}
      fixedSize={false}
      showRegisterCount={false}
      pageSize={(data ?? []).length}
    />
  );
};
