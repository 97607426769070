import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost } from "../../pdf";
import moment from "moment";

const URL_BASE = "/venda/vale_presente";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;

const ValePresenteService = {
  gerenciamento: {
    excluir: (idValePresente) =>
      apiDeleteV2(`${URL_BASE_GERENCIAMENTO}/excluir/${idValePresente}/`),
    listar: (params) => apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
  },
  imprimir: async (idValePresente) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE_GERENCIAMENTO}/buscar/${idValePresente}/`
    );
    if (ok) {
      const arq = await docPost(
        "/venda/vale_presente/",
        {
          nome_empresa: ret.dados_empresa.nome_fant_empresa,
          endereco_empresa: ret.dados_empresa.ender_empresa,
          fone_empresa: ret.dados_empresa.fone_empresa,
          cod_bar: ret.cod_bar,
          id: idValePresente,
          inclusao: ret.inclusao,
          valor: ret.valor,
        },
        {
          errorMessage: "Erro ao Imprimir Vale Presente",
        },
        true
      );

      if (!arq) return false;

      const fileName = `VALE_PRESENTE_${idValePresente}_${moment(
        new Date()
      ).format("YYYYMMDD")}`;

      viewDownloadPDF(arq, fileName);
      return true;
    }
  },
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
};

export default ValePresenteService;
