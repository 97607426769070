import React, { useEffect } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row } from "reactstrap";
import { RadioItem } from "../../../../components/RadioGroup";
import {
  setTipoDocPadrao,
  setAbrirTelaCheia,
  setModeloImprFecCxa,
  setIdProdutoAcresc,
  setInformarQtdSempre,
  setPesqProdSomenteCodBarras,
  setRecebDetalInfoCartao,
  setRecebIdAdmCartaoPadrao,
  setAvisoSonoroProdNaoEnc,
  setUsaProdutoComComplemento,
  setSemprePreencheVlrRecDinheiro,
  setRetornoEanBalanca,
  setPermiteDescontoRecMoeda,
  setNumCaracterCodigoBalanca,
  setUsaRegraDesconto,
  setIdentificaColabVendedor,
  setCalcQtdBalancaPorValor,
  setConsidera2ProdBalanca,
  setPedeSenhaExcluirItem,
  setPermiteReimprimirVenda,
  setSolicSenhaFechaPdvComItens,
} from "../store/pdv_slice";

const modeloImprFecCxaOptions = [
  {
    label:
      "Modelo 1 - Impressão de valores de fechamento + quantidade de produtos vendidos",
    value: 1,
  },
];

export const PersonPDV = () => {
  const store = useSelector((state) => state.pdv);
  const dispatch = useDispatch();

  const onChangeRecebDetalInfoCartao = () => {
    if (!store.receb_detal_info_cartao) {
      dispatch(setRecebIdAdmCartaoPadrao(null));
    }
  };
  useEffect(onChangeRecebDetalInfoCartao, [store.receb_detal_info_cartao]);

  return (
    <>
      <Row>
        <AsyncComboBox
          md={5}
          label="Tipo de documento padrão"
          concatModelName="tipo_documento"
          defaultValue={store.tipo_doc_padrao}
          onChange={(s) => dispatch(setTipoDocPadrao(s?.value ?? null))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Executar sistema PDV em tela cheia"
          checked={store.abrir_tela_cheia}
          onChange={() => dispatch(setAbrirTelaCheia(!store.abrir_tela_cheia))}
        />
      </Row>
      <Row>
        <ComboBox
          md={7}
          label="Modelo da impressão de fechamento de caixa loja"
          options={modeloImprFecCxaOptions}
          defaultValue={store.modelo_impr_fec_cxa}
          onChange={(s) => dispatch(setModeloImprFecCxa(s?.value))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Produto de acréscimo individual"
          concatModelName="produto"
          defaultValue={store.id_produto_acresc}
          onChange={(s) => dispatch(setIdProdutoAcresc(s?.value ?? null))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Informa a quantidade em todos os itens da venda"
          checked={store.informar_qtd_sempre}
          onChange={() =>
            dispatch(setInformarQtdSempre(!store.informar_qtd_sempre))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Pesquisa de produtos considerando APENAS o código de barras"
          checked={store.pesq_prod_somente_cod_barras}
          onChange={() =>
            dispatch(
              setPesqProdSomenteCodBarras(!store.pesq_prod_somente_cod_barras)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Detalhar informações do cartão do recebimento do PDV"
          checked={store.receb_detal_info_cartao}
          onChange={() =>
            dispatch(setRecebDetalInfoCartao(!store.receb_detal_info_cartao))
          }
        />
        <AsyncComboBox
          md={5}
          label="Administradora Padrão"
          concatModelName="administradora_cartao"
          defaultValue={store.receb_id_adm_cartao_padrao}
          onChange={(s) =>
            dispatch(setRecebIdAdmCartaoPadrao(s?.value ?? null))
          }
          disabled={store.receb_detal_info_cartao}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Aviso sonoro na impressora caso não encontrar o produto"
          checked={store.aviso_sonoro_prod_nao_enc}
          onChange={() =>
            dispatch(setAvisoSonoroProdNaoEnc(!store.aviso_sonoro_prod_nao_enc))
          }
        />
        <span className="mt-2">
          Atenção: O aviso sonoro será disparado pela impressora identificada na
          aba de parâmetros de Venda
        </span>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza produtos com complementos"
          checked={store.usa_produto_com_complemento}
          onChange={() =>
            dispatch(
              setUsaProdutoComComplemento(!store.usa_produto_com_complemento)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sempre preencher valor recebido em dinheiro na tela de recebimento no PDV"
          checked={store.sempre_preenche_vlr_rec_dinheiro}
          onChange={() =>
            dispatch(
              setSemprePreencheVlrRecDinheiro(
                !store.sempre_preenche_vlr_rec_dinheiro
              )
            )
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Retorno do código de barras da balança"
          value={store.retorno_ean_balanca}
          onChange={(v) => dispatch(setRetornoEanBalanca(v))}
        >
          <RadioItem label="Valor" value="V" />
          <RadioItem label="Quantidade" value="Q" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite desconto no recebimento na identificação da moeda"
          checked={store.permite_desconto_rec_moeda}
          onChange={() =>
            dispatch(
              setPermiteDescontoRecMoeda(!store.permite_desconto_rec_moeda)
            )
          }
        />
      </Row>
      <Row>
        <Label md="auto">
          Número de caracteres configurados para impressão do código da balança
        </Label>
        <IntegerFormInput
          md={1}
          defaultValue={store.num_caracter_codigo_balanca}
          onChange={(v) => dispatch(setNumCaracterCodigoBalanca(v))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Regra de Desconto"
          checked={store.usa_regra_desconto}
          onChange={() =>
            dispatch(setUsaRegraDesconto(!store.usa_regra_desconto))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Vendedor"
          checked={store.identifica_colab_vendedor}
          onChange={() =>
            dispatch(
              setIdentificaColabVendedor(!store.identifica_colab_vendedor)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Calcular quantidade do produto com base no valor do código de barras da balança"
          checked={store.calc_qtd_balanca_por_valor}
          onChange={() =>
            dispatch(
              setCalcQtdBalancaPorValor(!store.calc_qtd_balanca_por_valor)
            )
          }
        />
        <span style={{ marginTop: "0.2rem" }}>
          Atenção: Para o correto funcionamento desta função, recomenda-se a
          integração entre balança e sistema
        </span>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Considerar 2 (dois) no início do código de barras para identificar como produto de balança"
          checked={store.considera_2_prod_balanca}
          onChange={() =>
            dispatch(setConsidera2ProdBalanca(!store.considera_2_prod_balanca))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Exigir senha para cancelamento de itens da venda"
          checked={store.pede_senha_excluir_item}
          onChange={() =>
            dispatch(setPedeSenhaExcluirItem(!store.pede_senha_excluir_item))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Reimpressão de Venda"
          checked={store.permite_reimprimir_venda}
          onChange={() =>
            dispatch(setPermiteReimprimirVenda(!store.permite_reimprimir_venda))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Solicitar Senha para Fechar PDV quando houverem Itens incluídos na Venda"
          checked={store.solic_senha_fecha_pdv_com_itens}
          onChange={() =>
            dispatch(
              setSolicSenhaFechaPdvComItens(
                !store.solic_senha_fecha_pdv_com_itens
              )
            )
          }
        />
      </Row>
    </>
  );
};
