import React from "react";
import { IconButton, Table } from "../../../../components";
import { BsPrinter } from "react-icons/bs";

export const AddaxTransportesGrid = ({ dados, buscarImpressao }) => {
  const columns = [
    {
      dataField: "numero_nf",
      text: "Nro. NF",
      align: "center",
      colWidth: "200px",
      fixedColWidth: true,
    },
    { dataField: "nome_cliente", text: "Cliente", align: "left" },
    {
      dataField: "destino",
      text: "Destino",
      align: "center",
    },
    {
      dataField: "qtd_volumes",
      text: "Volumes",
      align: "center",
      colWidth: "180px",
      fixedColWidth: true,
    },
    {
      dataField: "print",
      text: "",
      align: "center",
      colWidth: "32px",
      fixedColWidth: true,
      formatter: (c, row) => (
        <IconButton
          icon={BsPrinter}
          onClick={() => buscarImpressao(row)}
          hint="Imprimir"
          tooltipPlacement="left"
        />
      ),
    },
  ];

  return <Table data={dados} columns={columns} />;
};
