import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { FormCheckbox } from "../../../../components";
import {
  setSolicitaSenhaAbreContaAlteraLimite,
  setBloqUsoCliComSaldoDevedor,
} from "../store/credito_rotativo_slice";

export const PersonCreditoRotativo = () => {
  const store = useSelector((state) => state.credito_rotativo);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Solicita Senha para Abertura de Conta e Alteração de Limite"
          checked={store.solicita_senha_abre_conta_altera_limite}
          onChange={() =>
            dispatch(
              setSolicitaSenhaAbreContaAlteraLimite(
                !store.solicita_senha_abre_conta_altera_limite
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloqueia Uso do Rotativo por Cliente com Valores em Aberto"
          checked={store.bloq_uso_cli_com_saldo_devedor}
          onChange={() =>
            dispatch(
              setBloqUsoCliComSaldoDevedor(
                !store.bloq_uso_cli_com_saldo_devedor
              )
            )
          }
        />
      </Row>
    </>
  );
};
