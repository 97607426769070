import { showError } from "../../../../components/AlertaModal";
import { docServiceV2 } from "../../../../pdf";

export const imprimirEtqL35 = async (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    const ret = await docServiceV2
      .post("/etiquetas/layout_35/", etq)
      .then((ret) => ret.data)
      .catch(async (err) => {
        showError(
          "Erro",
          "ErrLayoutBecca",
          err.response ? JSON.stringify(err.response.data) : err.message
        );
        return false;
      });
    if (!ret) {
      return [false, null];
    }

    return [true, ret];
  } catch (err) {
    return [false, err.message];
  }
};
