import React from "react";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import { ModalDetalhesIcone, Table } from "../../../../../../components";

export const RomaneiosRealizadosGrid = ({
  data,
  handleSelect,
  handleSelectAll,
  toggleDetalhe,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_transportadora",
      text: "Transportadora",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) =>
        row.id_transportadora
          ? formatValueFromAPI(c, row.id_transportadora)
          : "",
    },
    {
      dataField: "nome_colaborador",
      text: "Colaborador",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_usuario),
    },
    {
      dataField: "data_hora_realizado",
      text: "Realizado",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "qtd_vendas_romaneio",
      text: "Qtd. Vendas",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "cidades",
      text: "Cidades",
      align: "left",
      headerAlign: "left",
      formatter: (c) => (
        <>
          {c.map((e) => (
            <span
              style={{
                backgroundColor: "#ccc",
                paddingInline: "0.3rem",
                marginRight: "0.3rem",
                borderRadius: "0.2rem",
              }}
            >
              {e}
            </span>
          ))}
        </>
      ),
    },
    {
      dataField: "detalhes_act",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      formatter: (_, row) => (
        <ModalDetalhesIcone toggle={() => toggleDetalhe(row.id)} />
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      multiSelect
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      paginated
      clearSelectionOnUpdate
    />
  );
};
