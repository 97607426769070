import React, { useEffect, useRef, useState } from "react";
import {
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { Card, Label, Row } from "reactstrap";
import { ProdutosSugestaoCompraGrid } from "./components/ProdutosSugestaoCompraGrid";
import FiltrosModal from "./components/FiltrosModal";
import { ProdutosAbaixoEstMinModal } from "../components/ProdutosAbaixoEstMinModal";
import SugestaoCompraService from "../../../../services/compras/SugestaoCompraService";
import {
  dateFromLocaleString,
  formatDateISO,
  roundFloat,
} from "../../../../coreUtils";
import { IncluirProdutoSugestaoCompraModal } from "./components/IncluirProdutoSugestaoCompraModal";
import { GerarOrdemCompraModal } from "./components/GerarOrdemCompraModal";
import { showWarning } from "../../../../components/AlertaModal";

export const SIGLA_TIPO_FILTROS_SUGESTAO_COMPRA = {
  classe_prod: "CLASS",
  fornecedor: "FORNC",
  fabricante: "FABRI",
  grupo: "GRUPO",
  sub_grupo: "SUBGR",
  tipo_produto: "TIPOP",
};

export const GerarSugestaoCompra = ({ location }) => {
  const idAlterar = location?.state?.id_sugestao;
  const [dataIniBase, setDataIniBase] = useState(new Date());
  const [dataFimBase, setDataFimBase] = useState(new Date());
  const [considerarDevolucoes, setConsiderarDevolucoes] = useState(true);
  const [considerarEstAtual, setConsiderarEstAtual] = useState(true);
  const [ignorarEstNegativo, setIgnorarEstNegativo] = useState(false);
  const [considerarProdInativos, setConsiderarProdInativos] = useState(false);
  const [considerarQtdSugZero, setConsiderarQtdSugZero] = useState(false);
  const [diasSuprir, setDiasSuprir] = useState(30);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [qtdProdutos, setQtdProdutos] = useState(0);
  const [vlrTotalSugestaoSis, setVlrTotalSugestaoSis] = useState(0);
  const [gerarOrdemCompraOpen, setGerarOrdemCompraOpen] = useState(false);

  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [idSugestao, setIdSugestao] = useState(null);

  const refFiltros = useRef();
  const refProdEstMin = useRef();

  const handleDate = (di, df) => {
    setDataIniBase(di);
    setDataFimBase(df);
  };

  const formatarMultifilter = () => {
    let filtrosMultiplos = [];
    if (refFiltros.current) {
      filtrosMultiplos = refFiltros.current.buscarFiltros();
    }

    filtrosMultiplos = filtrosMultiplos.map((e) => ({
      tipo: SIGLA_TIPO_FILTROS_SUGESTAO_COMPRA[e.tipo],
      id_filtro: e.id_filtro,
    }));
    return filtrosMultiplos;
  };

  const verificaAbrirSugestao = async () => {
    const payload = {
      data_ini: formatDateISO(dataIniBase),
      data_fim: formatDateISO(dataFimBase),
      dias_suprir: diasSuprir,
      considerar_devolucoes: considerarDevolucoes,
      considerar_est_atual: considerarEstAtual,
      ignorar_est_negativo: ignorarEstNegativo,
      considerar_prod_inativos: considerarProdInativos,
      considerar_qtd_sug_zero: considerarQtdSugZero,
      filtros: formatarMultifilter(),
    };
    if ([0, null, undefined].includes(idSugestao)) {
      const [ok, ret] = await SugestaoCompraService.abrirSugestao(payload);
      if (ok) {
        setIdSugestao(ret.id_sugestao);
      }
      return ok ? ret.id_sugestao : null;
    } else {
      payload["id_sugestao"] = idSugestao;
      const [ok] = await SugestaoCompraService.gerarItensSugestao(payload);
      if (!ok) {
        return null;
      }
      return idSugestao;
    }
  };

  const buscarItens = async (idSugestaoBuscar) => {
    const [ok, ret] = await SugestaoCompraService.listarProdutos(
      idSugestaoBuscar
    );
    if (ok) {
      const totais = ret.totais;
      setQtdProdutos(totais.qtd_produtos);
      setVlrTotalSugestaoSis(totais.vlr_total_sugestao_sis);
      setProdutos(
        ret.itens.map((e) => ({ ...e, qtd_compra: parseFloat(e.qtd_compra) }))
      );
    } else {
      setQtdProdutos(0);
      setVlrTotalSugestaoSis(0);
      setProdutos([]);
    }
    return ok;
  };

  const gerarSugestao = async () => {
    setLoading(true);
    try {
      const idSugAberta = await verificaAbrirSugestao();
      if (idSugAberta) {
        await buscarItens(idSugAberta);
      }
    } finally {
      setLoading(false);
    }
  };

  const atualizarContagemEstMin = () => {
    if (refProdEstMin.current) {
      refProdEstMin.current.atualizarContagem();
    }
  };

  useEffect(() => {
    atualizarContagemEstMin();
  }, [ignorarEstNegativo, considerarProdInativos]);

  const recalcularValoresGrade = async (coluna, novoValor, row) => {
    const novoValorTest = roundFloat(
      parseFloat(novoValor),
      row.qtd_casas_decimais_qtd
    );
    const valorAntesTest = roundFloat(
      parseFloat(row[coluna]),
      row.qtd_casas_decimais_qtd
    );

    if (novoValorTest === valorAntesTest) {
      return false;
    }

    row.qtd_compra = parseFloat(novoValor);

    setAtualizarValoresItens(true);
    setProdutos(
      produtos.map((element) => {
        if (element.id === row.id) {
          return { ...row };
        } else {
          return element;
        }
      })
    );
  };

  const atualizarDadosItemGrid = async (row) => {
    if (atualizarValoresItens) {
      await atualizarItem(row);
      setAtualizarValoresItens(false);
    }
  };

  const atualizarItem = async (row) => {
    const payload = {
      id_item: row.id,
      qtd_compra: parseFloat(row.qtd_compra ?? "0") ?? 0,
    };

    const [ok] = await SugestaoCompraService.alterarQtdCompraItem(payload);
    return ok;
  };

  const incluirMultiplosItens = async (produtos) => {
    const idSugAberta = await verificaAbrirSugestao();
    if (idSugAberta) {
      const payload = {
        id_cab: idSugAberta,
        itens: produtos.map((e) => ({
          id_produto: e.id_produto,
          qtd_compra: e.qtd_compra,
        })),
      };

      const [ok] = await SugestaoCompraService.incluirMultiplosItem(payload);
      if (ok) {
        buscarItens(idSugAberta);
      }
      return ok;
    } else {
      return false;
    }
  };

  const incluirItem = async (prod) => {
    const idSugAberta = await verificaAbrirSugestao();
    if (idSugAberta) {
      const payload = {
        id_cab: idSugAberta,
        id_produto: prod.id_produto,
        qtd_compra: prod.quantidade,
      };

      const [ok] = await SugestaoCompraService.incluirItem(payload);
      if (ok) {
        buscarItens(idSugAberta);
      }
      return ok;
    } else {
      return false;
    }
  };

  const toggleGerarOrdemCompra = () =>
    setGerarOrdemCompraOpen(!gerarOrdemCompraOpen);

  const iniciarTela = async () => {
    if (idAlterar) {
      const [ok, ret] = await SugestaoCompraService.buscarCab(idAlterar);
      if (ok) {
        setDiasSuprir(ret.dias_suprir);
        setConsiderarDevolucoes(ret.considerar_devolucoes);
        setConsiderarEstAtual(ret.considerar_est_atual);
        setIgnorarEstNegativo(ret.ignorar_est_negativo);
        setConsiderarProdInativos(ret.considerar_prod_inativos);
        setConsiderarQtdSugZero(ret.considerar_qtd_sug_zero);
        setDataIniBase(dateFromLocaleString(ret.periodo_inicio));
        setDataFimBase(dateFromLocaleString(ret.periodo_final));

        if (await buscarItens(idAlterar)) {
          setIdSugestao(idAlterar);
        }
      }
    }
    setLoadingFetch(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      if ([0, null, undefined].includes(idSugestao)) {
        showWarning(
          "Não há nenhum item na Sugestão de Compra." +
            'Por favor, clique em Gerar Sugestão ou "+ Produto" ' +
            "para incluir um item e tente novamente."
        );
        return;
      }

      toggleGerarOrdemCompra();
    }
  };

  return (
    <PageContainer
      title="Gerar Sugestão de Compra"
      number="0016_1"
      loading={loadingFetch}
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row className="mb-2">
          <FiltroPeriodoDatas
            label="Período Base de Vendas"
            onChange={handleDate}
            defaultOption={null}
            defaultStart={dataIniBase}
            defaultEnd={dataFimBase}
          />
          <Label md="auto" className="pr-0 pt-4">
            Suprir Estoque Para
          </Label>
          <IntegerFormInput
            label={" "}
            md={1}
            defaultValue={diasSuprir}
            onChange={setDiasSuprir}
            colClassName="px-2"
          />
          <Label md="auto" className="pl-0 pt-4">
            Dias
          </Label>
          <FormButton color="success" onClick={gerarSugestao} loading={loading}>
            Gerar Sugestão
          </FormButton>
          <ProdutosAbaixoEstMinModal
            ignorarEstNegativo={ignorarEstNegativo}
            considerarProdInativos={considerarProdInativos}
            formatarMultifilter={formatarMultifilter}
            ref={refProdEstMin}
            handleSubmit={incluirMultiplosItens}
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Deduzir Devoluções da Qtd. Vendida"
            checked={considerarDevolucoes}
            onChange={() => setConsiderarDevolucoes(!considerarDevolucoes)}
          />
          <FormCheckbox
            padded={false}
            label="Deduzir o Estoque da Qtd. Sugerida"
            checked={considerarEstAtual}
            onChange={() => {
              setConsiderarEstAtual(!considerarEstAtual);
              if (considerarEstAtual) {
                setIgnorarEstNegativo(true);
              }
            }}
          />
          <FormCheckbox
            padded={false}
            label="Qtd. Sugerida Ignora Estoque Negativo"
            checked={ignorarEstNegativo}
            onChange={() => setIgnorarEstNegativo(!ignorarEstNegativo)}
            disabled={!considerarEstAtual}
          />
          <FiltrosModal
            divClassName="ml-auto pr-0"
            onFilterUpdate={atualizarContagemEstMin}
            ref={refFiltros}
          />
          <IncluirProdutoSugestaoCompraModal
            incluirItem={incluirItem}
            produtos={produtos}
          />
          <GerarOrdemCompraModal
            isOpen={gerarOrdemCompraOpen}
            toggle={toggleGerarOrdemCompra}
            idSugestao={idSugestao}
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Adicionar Produtos Inativos na Sugestão"
            checked={considerarProdInativos}
            onChange={() => setConsiderarProdInativos(!considerarProdInativos)}
          />
          <FormCheckbox
            padded={false}
            label="Adicionar Produtos com Qtd. Sugerida Zerada"
            checked={considerarQtdSugZero}
            onChange={() => setConsiderarQtdSugZero(!considerarQtdSugZero)}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Qtd Produtos Incluídos na Sugestão"
          value={qtdProdutos}
          checkFloat={true}
          className="col-md-3 ml-auto"
        />
        <CardTotaisItem
          label="Valor Total Sugestão"
          value={vlrTotalSugestaoSis}
          className="col-md-2"
        />
      </CardTotais>
      <Card body>
        <ProdutosSugestaoCompraGrid
          dados={produtos}
          atualizarDadosItem={atualizarDadosItemGrid}
          recalcularValoresGrade={recalcularValoresGrade}
        />
        <Row>
          <FormButton
            color="success"
            divClassName="ml-auto"
            onClick={toggleGerarOrdemCompra}
            disabled={[0, null, undefined].includes(idSugestao)}
            disabledHint="Clique em Gerar Sugestão ou inclua um produto"
          >
            F9 - Gerar Ordem de Compra
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
