import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import ConferenciaDinheiroService from "../../../../../services/pedidos/ConferenciaDinheiroService";
import { BotaoPesquisar } from "../../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../../components/PageContainer";
import { ConferenciaDinheiroGrid } from "./components/ConferenciaDinheiroGrid";
import { sumDataField, useStateWithRef } from "../../../../../coreUtils";
import { AsyncComboBox, IntegerFormInput } from "../../../../../components";
import { ConferirValoresModal } from "./components/ConferirValoresModal";

export const ConferenciaDinheiro = () => {
  const [dados, setDados] = useState([]);
  const [vendedor, setVendedor] = useState(null);
  const [idPedido, setIdPedido] = useState(null);
  const [selected, setSelected, selectedRef] = useStateWithRef([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    const params = {
      id_vendedor: vendedor,
      id_pedido: idPedido,
    };
    setDados(await ConferenciaDinheiroService.listar(params));
    setSelected([]);
    setLoadingPesquisar(false);
  };

  const handleOnSelect = (id, _, isSelected) => {
    setSelected(
      isSelected
        ? [...selectedRef.current, id]
        : selectedRef.current.filter((e) => e !== id)
    );
  };

  const handleOnSelectAll = (isSelected) =>
    setSelected(isSelected ? dados.map((e) => e.id) : []);

  return (
    <PageContainer
      title="Conferência de Valores Recebidos Em Mãos"
      number="0054"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            label="Vendedor"
            onChange={(s) => setVendedor(s?.value)}
            isSearchable
            isClearable
          />
          <IntegerFormInput
            md={2}
            label="Nro. Pedido"
            value={idPedido}
            onChange={setIdPedido}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <ConferirValoresModal
            selected={selected}
            vlrCompensar={sumDataField(
              dados.filter((e) => selectedRef.current.includes(e.id)),
              "valor_pend"
            )}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <ConferenciaDinheiroGrid
          data={dados}
          selected={selected}
          handleOnSelect={handleOnSelect}
          handleOnSelectAll={handleOnSelectAll}
        />
      </Card>
    </PageContainer>
  );
};
