import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../components";
import { CiShoppingTag } from "react-icons/ci";
import { addaxTranspRoute } from "../../routes/modules/outsourcing";

export const Outsourcing = () => {
  return (
    <PageContainer title="Outsourcing">
      <MenuGroup>
        <MenuGroupButton
          label="Impressão de Etiquetas Addax"
          icon={CiShoppingTag}
          pathname={addaxTranspRoute.path}
          nroTela={addaxTranspRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
