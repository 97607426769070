import React, { useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  LabelButton,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import GerencContasReceberService from "../../../../services/financeiro/GerencContasReceberService";
import { formatNumber } from "../../../../coreUtils";
import { RadioItem } from "../../../../components/RadioGroup";
import { apiGetV2 } from "../../../../apiV2";
import { GerencContasReceberGrid } from "./components/GerencContasReceberGrid";
import { IncluirTituloReceberModal } from "./components/IncluirTituloReceberModal";
import { DetalhesTituloReceberModal } from "./components/DetalhesTituloReceberModal";
import { ReceberTitulosModal } from "./components/ReceberTitulosModal";
import { showWarning } from "../../../../components/AlertaModal";
import { AlterarTituloReceberModal } from "./components/AlterarTituloReceberModal";
import { ExcluirTitulosReceberModal } from "./components/ExcluirTitulosReceberModal";
import { docPrintReport } from "../../../../pdf";
import { ExportarContasReceberModal } from "./components/ExportarContasReceberModal";

export const periodoContasReceberOptions = [
  { label: "Vencimento", value: "VCT" },
  { label: "Recebimento", value: "REC" },
  { label: "Emissão", value: "EMI" },
];

const mostrarCarteiraOptions = [
  { label: "Todos", value: "T" },
  { label: "Somente Carteira", value: "C" },
  { label: "Somente Não Carteira", value: "N" },
];

const origemOptions = [
  { label: "Todas", value: "T" },
  { label: "Ordem de Serviço", value: "VOS" },
  { label: "Venda no Balcão", value: "VFX" },
  { label: "Inclusão Manual", value: "IMD" },
];

export const GerencContasReceber = ({ location }) => {
  const [idCliente, setIdCliente] = useState(null);
  const [numero, setNumero] = useState(null);
  const [idTitulo, setIdTitulo] = useState(null);
  const [nossoNro, setNossoNro] = useState(null);
  const [origem, setOrigem] = useState(origemOptions[0].value);
  const [nroOrigem, setNroOrigem] = useState(null);
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [mostrarCarteira, setMostrarCarteira] = useState(
    mostrarCarteiraOptions[0].value
  );
  const [periodo, setPeriodo] = useState(periodoContasReceberOptions[0].value);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [mostrarStatus, setMostrarStatus] = useState("PD");
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [alterarOpen, setAlterarOpen] = useState(false);
  // Parâmetros da tela
  const [utilizaOrganizacao, setUtilizaOrganizacao] = useState(false);

  const filtroPeriodoRef = useRef();

  const titulosSelected = (dados ?? []).filter((e) => selected.includes(e.id));

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = (outrosParams) => {
    const params = {
      id_cliente: idCliente,
      numero: numero,
      id_titulo: idTitulo,
      nosso_nro: nossoNro,
      origem: origem !== "T" ? origem : undefined,
      nro_origem: nroOrigem,
      mostrar_carteira: mostrarCarteira,
      id_organizacao: idOrganizacao,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_status: mostrarStatus,
      ...outrosParams,
    };

    return GerencContasReceberService.listar(params);
  };

  const carregarDados = async (outrosParams) => {
    setLoading(true);
    const [ok, ret] = await buscarDados(outrosParams);
    if (ok) {
      setDados(ret.titulos);
      setTotais(ret.totais);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const notifyEvent = () => carregarDados();

  const handleSelect = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetStatus = (v) => {
    setMostrarStatus(v);
    setPeriodo(v === "PG" ? "PGT" : "VCT");
    if (v !== mostrarStatus) {
      carregarDados({ mostrar_status: v });
    }
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleAlterar = () => setAlterarOpen(!alterarOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 100);
  };

  let cidade = localStorage.getItem("cidade_empresa");

  const imprimirRecibo = async () => {
    if (selected.length <= 20) {
      for (const id of selected) {
        const [ok, ret] =
          await GerencContasReceberService.buscarImpressaoRecibo(id);
        if (ok) {
          await docPrintReport(
            "/financeiro/contas_receber/recibo/",
            { dados: ret, cidade },
            "0120"
          );
        }
      }
    } else {
      showWarning(
        "O número de recibos deve ser, no máximo, 20. Por favor, selecione até 20 títulos e tente novamente."
      );
    }
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/inc_titulo_receber/");
    if (ok) {
      setUtilizaOrganizacao(ret.utiliza_organizacao);
    }
  };

  const verificarRecebido = async (id) => {
    const [ok, ret] = await GerencContasReceberService.buscarStatus(id);
    if (ok) {
      if (ret.recebido) {
        showWarning(
          "O Título selecionado já foi recebido e não pode ser alterado."
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const alterar = async (id) => {
    if (!(await verificarRecebido(id))) return;
    setLastSelected(id);
    setTimeout(() => {
      toggleAlterar();
    }, 100);
  };

  const onActivate = () => {
    carregarParametros();

    let outrosParams = {};
    const state = location?.state ?? {};
    if (Object.keys(state).length > 0) {
      if (state.data_ini !== undefined || state.data_fim !== undefined) {
        if (state.data_ini !== undefined) {
          outrosParams.data_ini = state.data_ini;
        }

        if (state.data_fim !== undefined) {
          outrosParams.data_fim = state.data_fim;
        }

        if (filtroPeriodoRef.current) {
          if (state.data_ini !== undefined && state.data_fim !== undefined) {
            filtroPeriodoRef.current.updateDataIniFim(
              state.data_ini,
              state.data_fim
            );
          } else if (state.data_ini !== undefined) {
            filtroPeriodoRef.current.updateDataIni(state.data_ini);
          } else {
            filtroPeriodoRef.current.updateDataFim(state.data_fim);
          }
        }
      }

      if (state.periodo !== undefined) {
        setPeriodo(state.periodo);
        outrosParams.periodo = state.periodo;
      }

      if (state.mostrar_status !== undefined) {
        handleSetStatus(state.mostrar_status);
        outrosParams.mostrar_status = state.mostrar_status;
      }

      window.history.replaceState({}, "");
    }

    carregarDados(outrosParams);
  };

  useEffect(onActivate, []);

  return (
    <PageContainer title="Gerenciamento de Contas a Receber" number="0120">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Cliente"
            isConcatField
            isClearable
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
          />
          {utilizaOrganizacao && (
            <AsyncComboBox
              md={2}
              label="Organização"
              isConcatField
              isClearable
              concatModelName="organizacao"
              defaultValue={idOrganizacao}
              onChange={(s) => setIdOrganizacao(s?.value)}
              defaultOptions
            />
          )}
          <TextInput
            md={2}
            label="Número"
            value={numero}
            onChange={(e, v) => setNumero(v)}
            maxLength={12}
          />
          <IntegerFormInput
            md={1}
            label="ID"
            defaultValue={idTitulo}
            onChange={setIdTitulo}
          />
          <TextInput
            md={2}
            label="Nosso Nro"
            value={nossoNro}
            onChange={(e, v) => setNossoNro(v)}
          />
          <ComboBox
            md={2}
            label="Mostrar"
            options={mostrarCarteiraOptions}
            defaultValue={mostrarCarteira}
            onChange={(s) => setMostrarCarteira(s?.value)}
            isSearchable={false}
          />
          <ExportarContasReceberModal
            utilizaOrganizacao={utilizaOrganizacao}
            periodo={periodo}
            dataIni={dataIni}
            dataFim={dataFim}
            buscarDados={buscarDados}
          />
        </Row>
        <Row className="mb-2">
          <ComboBox
            md={2}
            label="Origem"
            options={origemOptions}
            defaultValue={origem}
            onChange={(s) => setOrigem(s?.value)}
            isSearchable={false}
          />
          <IntegerFormInput
            md={1}
            label="Nro Origem"
            defaultValue={nroOrigem}
            onChange={setNroOrigem}
          />
          <ComboBox
            md={2}
            label="Período"
            options={periodoContasReceberOptions}
            defaultValue={periodo}
            onChange={(s) => setPeriodo(s?.value)}
            isSearchable={false}
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={"MESATU"}
            ref={filtroPeriodoRef}
          />
          <RadioGroup
            label="Status"
            value={mostrarStatus}
            onChange={handleSetStatus}
            divClassName="mb-2"
          >
            <RadioItem label="Todos" value="TD" />
            <RadioItem label="Pendentes" value="PD" />
            <RadioItem label="Recebidos" value="RC" />
          </RadioGroup>
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={() => carregarDados()}
            loading={loading}
            padded={false}
          />
          <IncluirTituloReceberModal notifyEvent={notifyEvent} />
          <ExcluirTitulosReceberModal
            titulosSelected={titulosSelected}
            notifyEvent={notifyEvent}
          />
          <LabelButton
            onClick={imprimirRecibo}
            disabled={titulosSelected.length === 0}
            style={{ paddingTop: 5 }}
          >
            Imprimir Recibo
          </LabelButton>
          <ReceberTitulosModal
            titulosSelected={titulosSelected}
            notifyEvent={notifyEvent}
          />
          <DetalhesTituloReceberModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={lastSelected}
            utilizaOrganizacao={utilizaOrganizacao}
          />
          <AlterarTituloReceberModal
            isOpen={alterarOpen}
            toggle={toggleAlterar}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total a Receber"
          value={
            formatNumber(totais?.qtd_receber ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_receber ?? 0, true, 2)
          }
          format={false}
        />
        <CardTotaisItem
          label="Total Recebido"
          value={
            formatNumber(totais?.qtd_recebido ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_recebido ?? 0, true, 2)
          }
          format={false}
        />
        <CardTotaisItem
          label="Total Geral"
          value={
            formatNumber(totais?.qtd_geral ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_geral ?? 0, true, 2)
          }
          format={false}
        />
      </CardTotais>
      <Card body>
        <GerencContasReceberGrid
          dados={dados}
          selected={selected}
          utilizaOrganizacao={utilizaOrganizacao}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          mostrarDetalhes={mostrarDetalhes}
          alterar={alterar}
        />
      </Card>
    </PageContainer>
  );
};
