import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  SearchInput,
  TextInput,
} from "../../../../components";
import { BotaoConfirmarVoltar } from "../../../../components/BotaoConfirmarVoltar";
import { LinkButton } from "../../../../components/LinkButton";
import { PageContainer } from "../../../../components/PageContainer";
import {
  debounce,
  defaultDebounceTime,
  formatarMsgTotalProdutoCliente,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import CadastroClienteService from "../../../../services/cadastro/CadastroClienteService";
import { ClientesGrid } from "./components/ClientesGrid";
import VeiculosClienteGrid from "./components/VeiculosClienteGrid";
import {
  incluirAlterarClienteRoute,
  extratoClienteRoute,
  ligacaoVeicCliRoute,
} from "../../../../routes/modules/cadastro";
import { useHistory } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  setIdVeiculo,
  setMostrarInativos,
  setPista,
  setPlaca,
} from "./store/listagemClientesSlice";
import store from "./store";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { docPrintReport } from "../../../../pdf";

const verificarSistAutomovivo = (tipoSist) => tipoSist === "MECANICA";

const ClienteContainer = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.listagemClientes);
  const history = useHistory();
  const [dados, setDados] = useState([]);
  const [msgTotal, setMsgTotal] = useState("");
  const [selected, setSelected] = useState(null);
  const [nome, setNome] = useState("");
  const [veiculosCliente, setVeiculosCliente] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [params, setParams] = useState({});
  const [excluirOpen, setExcluirOpen] = useState(false);

  const sistAutomotivo = verificarSistAutomovivo(params.tipo_sist);

  const buscarDados = async (pista, sistAuto, placa) => {
    const getFunc = sistAuto
      ? CadastroClienteService.listarAutomotivo
      : CadastroClienteService.listar;

    const getParams = {
      pista: pista,
      apenas_ativos: !store.mostrarInativos,
      id_veiculo: store.idVeiculo,
      placa: placa,
    };

    const [ok, ret] = await getFunc(getParams);

    if (ok) {
      setDados(ret.clientes);
      setMsgTotal(
        formatarMsgTotalProdutoCliente({
          descricao: "cliente",
          qtdEncontrada: ret.clientes.length,
          qtdTotal: ret.qtd_total,
          somenteAtivos: !store.mostrarInativos,
        })
      );
    } else {
      setDados([]);
      setMsgTotal("");
    }
  };

  const handlePista = useCallback(
    debounce(async (v) => {
      setSelected(null);
      dispatch(setPista(v));
      setLoadingPista(true);
      await buscarDados(v, sistAutomotivo, store.placa);
      setLoadingPista(false);
    }, defaultDebounceTime),
    []
  );

  const handlePlaca = useCallback(
    debounce(async (v) => {
      setSelected(null);
      dispatch(setPlaca(v));
      setLoading(true);
      await buscarDados(store.pista, sistAutomotivo, v);
      setLoading(false);
    }, defaultDebounceTime),
    []
  );

  const carregarDados = async (sistAuto) => {
    setSelected(null);
    setLoading(true);
    await buscarDados(store.pista, sistAuto, store.placa);
    setLoading(false);
  };

  const onSelect = (id, is, row) => {
    setSelected(id);
    setNome(is ? row?.nome : "");
    setVeiculosCliente(is ? row?.veiculos ?? [] : []);
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2(`/tela/cad_cliente/`, {});
    if (ok) {
      setParams(ret);
    }
    return [ok, ret];
  };

  const iniciarTela = async () => {
    const [ok, ret] = await buscarParams();
    if (ok) {
      await carregarDados(verificarSistAutomovivo(ret.tipo_sist));
    }
  };

  const notifyDelete = () => {
    setSelected(null);
    carregarDados(sistAutomotivo);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const incluir = () => {
    history.push(incluirAlterarClienteRoute.path, {
      action: MODAL_ACTIONS.ADD,
    });
  };

  const alterar = (id) => {
    history.push(incluirAlterarClienteRoute.path, {
      action: MODAL_ACTIONS.EDIT,
      selected: id,
    });
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const imprimirFicha = async (id) => {
    const [ok, ret] = await CadastroClienteService.buscar(id);
    if (ok) {
      await docPrintReport(
        "/cadastro/ficha_cadastral_cliente/",
        { dados: ret },
        "0040"
      );
    }
  };

  const acoes = (
    <>
      <BotaoPesquisar
        onClick={() => carregarDados(sistAutomotivo)}
        loading={loading}
      />
      <BotaoIncluir toggle={incluir} />
      <ModalExcluirV2
        title="Cliente"
        isOpen={excluirOpen}
        toggle={toggleExcluir}
        number="0040_2"
        selected={selected}
        routeBase={CadastroClienteService.urlBase}
        notifyEvent={notifyDelete}
      />
      <LinkButton
        pathname={extratoClienteRoute.path}
        state={{ id_cliente: selected, nome: nome }}
        color="indigo"
        md="auto"
        disabled={[0, null, undefined].includes(selected)}
      >
        Histórico Financeiro
      </LinkButton>
      {sistAutomotivo && (
        <LinkButton
          pathname={ligacaoVeicCliRoute.path}
          state={{ id_cliente: selected, nome: nome }}
          color="primary"
          md="auto"
          disabled={[0, null, undefined].includes(selected)}
        >
          Veículos
        </LinkButton>
      )}
      <BotaoConfirmarVoltar
        selected={selected}
        carregarDados={() => carregarDados(sistAutomotivo)}
      />
    </>
  );

  return (
    <>
      <Card body>
        <Row>
          <SearchInput
            value={store.pista}
            md={5}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome, Código, Rede, Cidade, Endereço, Telefone, Cpf, Cnpj"
          />
          {sistAutomotivo && (
            <>
              <TextInput
                label="Placa"
                md={2}
                onChange={(e, v) => handlePlaca(v)}
                value={store.placa}
              />
              <AsyncComboBox
                md={3}
                label="Veículo"
                onChange={(s) => dispatch(setIdVeiculo(s?.value))}
                isSearchable
                isClearable
                isConcatField
                concatModelName="veiculo"
                defaultValue={store.idVeiculo}
                hideShortcut
              />
            </>
          )}
          <FormCheckbox
            label="Mostrar Inativos"
            name="mostrar_inativos"
            checked={store.mostrarInativos}
            onChange={() =>
              dispatch(setMostrarInativos(!store.mostrarInativos))
            }
          />
          {!sistAutomotivo && acoes}
        </Row>
        {sistAutomotivo && <Row>{acoes}</Row>}
      </Card>
      <ClientesGrid
        data={dados}
        onSelect={onSelect}
        selected={selected}
        sistAutomotivo={sistAutomotivo}
        alterar={alterar}
        excluir={excluir}
        imprimirFicha={imprimirFicha}
        msgTotal={msgTotal}
      />
      {sistAutomotivo && (
        <Card body>
          <VeiculosClienteGrid veiculos={veiculosCliente ?? []} />
        </Card>
      )}
    </>
  );
};

export const Cliente = ({ location }) => {
  const history = useHistory();
  return (
    <PageContainer
      title="Cadastro de Clientes"
      number="0040"
      topOptions={
        <span
          style={{
            margin: 0,
            padding: 0,
            cursor: "pointer",
            opacity: 0.6,
            color: "black",
            fontSize: 11,
          }}
          onClick={() =>
            history.push("/cadastro/campos_person", { tabela: "cliente" })
          }
        >
          Criar Campo
        </span>
      }
      canGoBack
    >
      <Provider store={store}>
        <ClienteContainer location={location} />
      </Provider>
    </PageContainer>
  );
};
