import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  tipo_doc_padrao: null,
  abrir_tela_cheia: true,
  modelo_impr_fec_cxa: 1,
  id_produto_acresc: null,
  informar_qtd_sempre: false,
  pesq_prod_somente_cod_barras: false,
  receb_detal_info_cartao: true,
  receb_id_adm_cartao_padrao: null,
  aviso_sonoro_prod_nao_enc: false,
  usa_produto_com_complemento: false,
  sempre_preenche_vlr_rec_dinheiro: false,
  retorno_ean_balanca: "V",
  permite_desconto_rec_moeda: false,
  num_caracter_codigo_balanca: 5,
  usa_regra_desconto: false,
  identifica_colab_vendedor: false,
  calc_qtd_balanca_por_valor: false,
  considera_2_prod_balanca: true,
  pede_senha_excluir_item: false,
  permite_reimprimir_venda: false,
  solic_senha_fecha_pdv_com_itens: false,
};

const fieldsToNull = ["tipo_doc_padrao", "id_produto_acresc"];

export const pdvSlice = createSlice({
  name: "pdv_slice",
  initialState: initialState,
  reducers: {
    setupPdv: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        state[key] = value;
      });
      return state;
    },
    setTipoDocPadrao: (state, action) => {
      state.tipo_doc_padrao = action.payload;
    },
    setAbrirTelaCheia: (state, action) => {
      state.abrir_tela_cheia = action.payload;
    },
    setModeloImprFecCxa: (state, action) => {
      state.modelo_impr_fec_cxa = action.payload;
    },
    setIdProdutoAcresc: (state, action) => {
      state.id_produto_acresc = action.payload;
    },
    setInformarQtdSempre: (state, action) => {
      state.informar_qtd_sempre = action.payload;
    },
    setPesqProdSomenteCodBarras: (state, action) => {
      state.pesq_prod_somente_cod_barras = action.payload;
    },
    setRecebDetalInfoCartao: (state, action) => {
      state.receb_detal_info_cartao = action.payload;
    },
    setRecebIdAdmCartaoPadrao: (state, action) => {
      state.receb_id_adm_cartao_padrao = action.payload;
    },
    setAvisoSonoroProdNaoEnc: (state, action) => {
      state.aviso_sonoro_prod_nao_enc = action.payload;
    },
    setUsaProdutoComComplemento: (state, action) => {
      state.usa_produto_com_complemento = action.payload;
    },
    setSemprePreencheVlrRecDinheiro: (state, action) => {
      state.sempre_preenche_vlr_rec_dinheiro = action.payload;
    },
    setRetornoEanBalanca: (state, action) => {
      state.retorno_ean_balanca = action.payload;
    },
    setPermiteDescontoRecMoeda: (state, action) => {
      state.permite_desconto_rec_moeda = action.payload;
    },
    setNumCaracterCodigoBalanca: (state, action) => {
      state.num_caracter_codigo_balanca = action.payload;
    },
    setUsaRegraDesconto: (state, action) => {
      state.usa_regra_desconto = action.payload;
    },
    setIdentificaColabVendedor: (state, action) => {
      state.identifica_colab_vendedor = action.payload;
    },
    setCalcQtdBalancaPorValor: (state, action) => {
      state.calc_qtd_balanca_por_valor = action.payload;
    },
    setConsidera2ProdBalanca: (state, action) => {
      state.considera_2_prod_balanca = action.payload;
    },
    setPedeSenhaExcluirItem: (state, action) => {
      state.pede_senha_excluir_item = action.payload;
    },
    setPermiteReimprimirVenda: (state, action) => {
      state.permite_reimprimir_venda = action.payload;
    },
    setSolicSenhaFechaPdvComItens: (state, action) => {
      state.solic_senha_fecha_pdv_com_itens = action.payload;
    },
  },
});

export const {
  setupPdv,
  setTipoDocPadrao,
  setAbrirTelaCheia,
  setModeloImprFecCxa,
  setIdProdutoAcresc,
  setInformarQtdSempre,
  setPesqProdSomenteCodBarras,
  setRecebDetalInfoCartao,
  setRecebIdAdmCartaoPadrao,
  setAvisoSonoroProdNaoEnc,
  setUsaProdutoComComplemento,
  setSemprePreencheVlrRecDinheiro,
  setRetornoEanBalanca,
  setPermiteDescontoRecMoeda,
  setNumCaracterCodigoBalanca,
  setUsaRegraDesconto,
  setIdentificaColabVendedor,
  setCalcQtdBalancaPorValor,
  setConsidera2ProdBalanca,
  setPedeSenhaExcluirItem,
  setPermiteReimprimirVenda,
  setSolicSenhaFechaPdvComItens,
} = pdvSlice.actions;

export default pdvSlice.reducer;
