import React, { useRef, useState } from "react";
import {
  Divider,
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
} from "../../../../../components";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { MODAL_ACTIONS, formatValueFromAPI } from "../../../../../coreUtils";
import { apiGetV2, apiPostV2, apiPutV2 } from "../../../../../apiV2";
import EntradaNfXmlService from "../../../../../services/compras/EntradaNfXmlService";
import { showWarning } from "../../../../../components/AlertaModal";
import { routesBaseLigacaoProdFornec } from "../../../../cadastro/produtos/ligacao_prod_fornec/LigacaoProdFornec";

export const IdentificarProdutoModal = ({
  idFornecedorNf,
  nomeFornecedorNf,
  selectedIdNfEntradaXmlItem,
  selectedUuid,
  selectedLigacaoEncontrada,
  selectedIdLigacaoProdFornec,
  selectedCodProdNf,
  selectedEanProdNf,
  selectedDescProdNf,
  selectedUnidProdNf,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [idProdutoAntes, setIdProdutoAntes] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [codProdFornec, setCodProdFornec] = useState("");
  const [eanProdFornec, setEanProdFornec] = useState("");
  const [considerarEan, setConsiderarEan] = useState(false);
  const [fatorConv, setFatorConv] = useState(1);
  const [arredondaResult, setArredondaResult] = useState(false);
  const [conversaoEmM3, setConversaoEmM3] = useState(false);

  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [unidadeProd, setUnidadeProd] = useState(null);
  const [codBarProd, setCodBarProd] = useState("");

  const [loading, setLoading] = useState(false);
  const pesqProdRef = useRef();

  const limparDados = () => {
    setIdProdutoAntes(null);
    setIdProduto(null);
    setIdFornecedor(null);
    setCodProdFornec("");
    setEanProdFornec("");
    setConsiderarEan(false);
    setFatorConv(1);
    setArredondaResult(false);
    setConversaoEmM3(false);

    setNomeFornecedor("");
    setUnidadeProd(null);
    setCodBarProd("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectProduto = ({
    idProduto,
    nomeProduto,
    referencia,
    unidade,
    codBarras,
  }) => {
    setIdProduto(idProduto);
    setUnidadeProd(unidade);
    setCodBarProd(codBarras);
    if (pesqProdRef.current) {
      pesqProdRef.current.setDescricao(nomeProduto);
      pesqProdRef.current.setId(String(idProduto));
      pesqProdRef.current.setReferencia(String(referencia));
    }
  };

  const carregarDados = async () => {
    if (selectedLigacaoEncontrada) {
      setAction(MODAL_ACTIONS.EDIT);

      const [ok, ret] = await apiGetV2(
        `${routesBaseLigacaoProdFornec}/buscar/${selectedIdLigacaoProdFornec}/`
      );

      if (ok) {
        setIdProdutoAntes(ret.id_produto);
        setIdProduto(ret.id_produto);
        setIdFornecedor(ret.id_fornecedor);
        setNomeFornecedor(ret.nome_fornecedor);
        setCodProdFornec(ret.cod_prod_for);
        setEanProdFornec(ret.cod_barras ?? "");
        setConsiderarEan(ret.considera_ean);
        setFatorConv(parseFloat(ret.fator_conv));
        setArredondaResult(ret.arredonda_result);
        setConversaoEmM3(ret.conversao_em_m3);

        if (pesqProdRef.current) {
          pesqProdRef.current.setValueByID(ret.id_produto);
        }
      } else {
        toggle();
      }
    } else {
      setAction(MODAL_ACTIONS.ADD);
      limparDados();

      setIdFornecedor(idFornecedorNf);
      setNomeFornecedor(nomeFornecedorNf ?? "");
      setCodProdFornec(selectedCodProdNf ?? "");
      setEanProdFornec(selectedEanProdNf ?? "");
    }
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, informe o Produto");
      return;
    }

    setLoading(true);

    try {
      const payload = {
        id_produto: idProduto,
        id_fornecedor: idFornecedor,
        cod_prod_fornec: codProdFornec,
        ean_prod_fornec: eanProdFornec,
        considerar_ean: considerarEan,
        fator_conv: fatorConv,
        arredonda_result: arredondaResult,
        conversao_em_m3: conversaoEmM3,
      };

      let ok = false;

      if (action === MODAL_ACTIONS.EDIT) {
        [ok] = await apiPutV2(`${routesBaseLigacaoProdFornec}/alterar/`, {
          id_ligacao: selectedIdLigacaoProdFornec,
          ...payload,
        });
      } else {
        [ok] = await apiPostV2(
          `${routesBaseLigacaoProdFornec}/incluir/`,
          payload
        );
      }

      if (ok) {
        if (![0, null, undefined].includes(selectedIdNfEntradaXmlItem)) {
          await EntradaNfXmlService.recalcularValoresItem(
            selectedIdNfEntradaXmlItem,
            idProdutoAntes !== idProduto
          );
        }
        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormButton
        color="warning"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selectedUuid)}
      >
        Identificar Produto
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Identificar Produto"
        number="0101_13"
        onOpened={carregarDados}
        bodyStyle={{ paddingTop: "0.5rem" }}
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Divider>Informações da Nota Fiscal</Divider>
        <Row className="mb-2">
          <FixedField
            divClassName="pr-0"
            label="Nome do Produto no Fornecedor"
            value={selectedDescProdNf}
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            divClassName="pr-0"
            label="Fornecedor"
            value={formatValueFromAPI(nomeFornecedor, idFornecedor)}
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            divClassName="pr-0"
            label="Código no Fornecedor"
            value={selectedCodProdNf}
          />
          <FixedField
            divClassName="pr-0"
            label="UN no Fornecedor"
            value={selectedUnidProdNf}
          />
          <FixedField
            divClassName="pr-0"
            label="Cód. Barras no Fornec."
            value={selectedEanProdNf}
          />
        </Row>
        <Divider>Informações do Cadastro de Produto</Divider>
        <Row>
          <PesqProduto
            md={12}
            mdIdent={3}
            mdDesc={7}
            mdIdentAux={2}
            onConfirm={handleSelectProduto}
            ref={pesqProdRef}
            selectNextField={() => {}}
            podeAlterarNomeProdCuringa={false}
          />
        </Row>
        <Row>
          <FixedField
            divClassName="pr-0"
            md={1}
            label="Unidade"
            value={unidadeProd}
          />
          <FixedField
            divClassName="pr-0"
            md={2}
            label="Código de Barras"
            value={codBarProd}
          />
          <FormCheckbox
            label="Identificar por código do produto no fornecedor + código de barras"
            checked={considerarEan}
            padded={false}
            onChange={() => setConsiderarEan(!considerarEan)}
            hint={`Opção utilizada para diferenciar produtos
              que estejam no XML com o mesmo código.
              Marcando esta opção, o sistema irá considerar
              cód. do produto + código de barras
              para identificar o produto.`}
          />
        </Row>
      </ModalBase>
    </>
  );
};
