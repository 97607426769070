import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrecoProdUtilizado,
  setIdentificaProdutoRevenda,
  setIdContaBancPix,
  setPercDescMaxSobreTotalDinheiro,
  setPercDescMaxSobreTotalPix,
  setIdPlanoContasPix,
  setIdPlanoContasBoleto,
  setIdPlanoContasDinheiro,
  setBloqPedidoCliOutroVend,
  setUtilizaFlex,
  setVerificaEstoqueAntesPedido,
  setBloquearBoletoSeInadimplente,
  setDiasBloquearBoleto,
  setLayoutImprPedApp,
  setOrganizaClientesPor,
  setIdPlanoContasBonificacao,
  setIdPlanoContasTroca,
  setPermiteRecebimentoCheque,
} from "../store/app_pedido_venda_slice";
import { Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";

const layoutImprPedAppOptions = [{ label: "1 - Impressão Genérica", value: 1 }];

export const PersonAppPedidoVenda = () => {
  const store = useSelector((state) => state.app_pedido_venda);
  const dispatch = useDispatch();

  const onChangeBloquearBoletoSeInadimplente = () => {
    if (!store.bloquear_boleto_se_inadimplente) {
      dispatch(setDiasBloquearBoleto(0));
    }
  };
  useEffect(onChangeBloquearBoletoSeInadimplente, [
    store.bloquear_boleto_se_inadimplente,
  ]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Preço Utilizado"
          value={store.preco_prod_utilizado}
          onChange={(v) => dispatch(setPrecoProdUtilizado(v))}
        >
          <RadioItem label="Atacado" value="ATACADO" />
          <RadioItem label="Varejo" value="VAREJO" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identifica Produto para Revenda"
          checked={store.identifica_produto_revenda}
          onChange={() =>
            dispatch(
              setIdentificaProdutoRevenda(!store.identifica_produto_revenda)
            )
          }
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Conta Bancária para Recebimentos em PIX"
          isSearchable
          isConcatField
          concatModelName="conta_banc"
          defaultValue={store.id_conta_banc_pix}
          onChange={(s) => dispatch(setIdContaBancPix(s?.value ?? null))}
        />
      </Row>
      <Row>
        <NumberInput
          md={7}
          label="Perc. Máx. Desconto Sobre Total para Recebimentos em Dinheiro"
          value={store.perc_desc_max_sobre_total_dinheiro}
          onChange={(v) => dispatch(setPercDescMaxSobreTotalDinheiro(v))}
          mdInput={4}
          isPercentage
        />
      </Row>
      <Row>
        <NumberInput
          md={7}
          label="Perc. Máx. Desconto Sobre Total para Recebimentos em PIX"
          value={store.perc_desc_max_sobre_total_pix}
          onChange={(v) => dispatch(setPercDescMaxSobreTotalPix(v))}
          mdInput={4}
          isPercentage
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para Recebimentos em PIX"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasPix(v))}
          value={store.id_plano_contas_pix}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para Recebimentos em Boleto"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasBoleto(v))}
          value={store.id_plano_contas_boleto}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para Recebimentos em Dinheiro"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasDinheiro(v))}
          value={store.id_plano_contas_dinheiro}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Pedidos para Clientes de Outro Representante Externo"
          checked={store.bloq_pedido_cli_outro_vend}
          onChange={() =>
            dispatch(
              setBloqPedidoCliOutroVend(!store.bloq_pedido_cli_outro_vend)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Flex"
          checked={store.utiliza_flex}
          onChange={() => dispatch(setUtilizaFlex(!store.utiliza_flex))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Verificar Estoque do Produto Antes de Finalizar o Pedido"
          checked={store.verifica_estoque_antes_pedido}
          onChange={() =>
            dispatch(
              setVerificaEstoqueAntesPedido(
                !store.verifica_estoque_antes_pedido
              )
            )
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={5}
          label="Layout de Impressão pelo APP"
          options={layoutImprPedAppOptions}
          defaultValue={store.layout_impr_ped_app}
          onChange={(s) => dispatch(setLayoutImprPedApp(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Venda em Boleto Para Cliente Inadimplente"
          checked={store.bloquear_boleto_se_inadimplente}
          onChange={() =>
            dispatch(
              setBloquearBoletoSeInadimplente(
                !store.bloquear_boleto_se_inadimplente
              )
            )
          }
        />
        <Label md="auto" className="pt-0 pr-0" style={{ lineHeight: 1.7 }}>
          Dias para Bloqueio
        </Label>
        <IntegerFormInput
          md={2}
          defaultValue={store.dias_bloquear_boleto}
          onChange={(v) => dispatch(setDiasBloquearBoleto(v))}
          disabled={!store.bloquear_boleto_se_inadimplente}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Organiza Clientes Por"
          value={store.organiza_clientes_por}
          onChange={(v) => dispatch(setOrganizaClientesPor(v))}
        >
          <RadioItem label="Representante" value="REPRESENTANTE" />
          <RadioItem label="Empresa" value="EMPRESA" />
        </RadioGroup>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para registro de despesa de Trocas"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasTroca(v))}
          value={store.id_plano_contas_troca}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para registro de despesa de Bonificação"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasBonificacao(v))}
          value={store.id_plano_contas_bonificacao}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite recebimento em Cheque"
          checked={store.permite_recebimento_cheque}
          onChange={() =>
            dispatch(
              setPermiteRecebimentoCheque(!store.permite_recebimento_cheque)
            )
          }
        />
      </Row>
    </>
  );
};
